import React, { FC, useState, useEffect } from 'react'
import { Modal, Button, List } from 'antd'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import Loading from '../Elements/Loading'
import Empty from '../Elements/Empty'
import AvatarBankComponent from '../Elements/AvatarBank'
import AvatarMemberComponent from '../Elements/AvatarMember'
import PrefixService from '../../services/prefix'

interface ModalProps {
    close: () => void
    isOpen: boolean

    // dataDupicate: any
    dataDupicateID: number
    dataDupicateNote: string
    onloadData: () => void
}

const ModalBankDuplicate: FC<ModalProps> = ({ isOpen, close, dataDupicateID, dataDupicateNote, onloadData }) => {
    const [dataDupicate, setDataDupicate] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const handleDupicateBankTx = async (txID: number) => {
        if (txID) {
            try {
                const res = await PrefixService.duplicateBankTxDeposit(txID).then((data: any) => data)
                setIsLoading(false)
                setDataDupicate(res)
            } catch ({ message }) {
                toast.error(message as string)
            }
        }
    }

    const handleConfirmDuplicateTx = (txID: number, memberID: number, name: string) => {
        if (txID && memberID) {
            Swal.fire({
                title: `เติมเงินรายการ #${txID}`,
                text: `ตรวจสอบให้แน่ใจว่าคุณต้องการเติมเงินรายการ #${txID} ให้กับ ${name}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ตกลง'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const res = await PrefixService.confirmDuplicateBankTxDeposit(txID, memberID)
                        toast.success(res.message)
                        close()
                        onloadData()
                    } catch ({ message }) {
                        toast.error(message as string)
                    }
                }
            })
        }
    }
    useEffect(() => {
        if (dataDupicateID && isOpen) {
            setIsLoading(true)
            handleDupicateBankTx(dataDupicateID)
        }

        return () => {
            setIsLoading(false)
            setDataDupicate([])
        }
    }, [isOpen, dataDupicateID])


    return (
        <>
            <Modal
                title={`รายการฝากผิดพลาด #${dataDupicateNote}`}
                visible={isOpen}
                onCancel={close}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                footer={[
                    <Button key="submit" type="primary" onClick={close}>ปิด</Button>,
                ]}>
                {dataDupicate.length > 0 ? (
                    <List
                        className="demo-loadmore-list"
                        loading={false}
                        itemLayout="horizontal"
                        dataSource={dataDupicate}
                        renderItem={(item: any) => (
                            <List.Item
                                actions={[<button className="btn btn-success btn-sm text-white me-2" onClick={() => handleConfirmDuplicateTx(dataDupicateID, item.ID, `${item.FirstName} ${item.LastName}`)}>เติมเงิน</button>]}
                            >
                                <List.Item.Meta
                                    title={
                                        <div className="d-md-flex">
                                            <AvatarBankComponent
                                                bankCode={item.BankCode}
                                                bankNumber={
                                                    item.BankCode === 'TRUEWALLET'
                                                        ? item.Phone
                                                        : item.BankNumber
                                                }
                                                text={item.BankCode}
                                            />
                                            <div className="d-block mt-2 mt-md-0 ms-md-5">
                                                <AvatarMemberComponent
                                                    fullname={`${item.FirstName} ${item.LastName}`}
                                                    phone={item.Phone}
                                                    search={true}
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                ) : (isLoading ? <Loading /> : <Empty />)}
            </Modal>
        </>
    );
}

export default ModalBankDuplicate

