import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Space,
    Row,
    Col,
    Input,
    Checkbox,
    Divider,
    TimePicker
} from 'antd'
import { toast } from 'react-toastify'
import PrefixService from '../../services/prefix'
import { usersAddConfig } from '../../@types/prefix'
import moment from 'moment'

interface AddProps {
    close: () => void
    onloadData: () => void
    isOpen: boolean,
    workingTime: boolean,
    permissionlist: any
}

const AddData: FC<AddProps> = ({ close, isOpen, workingTime, onloadData, permissionlist }) => {
    const [form] = Form.useForm();
    const title = "เพิ่มผู้ใช้งาน"
    const [btnLoading, setBtnLoading] = useState(false)

    const listPermissions = Object.keys(permissionlist).map((key: string, index: any) => {
        const labelCheckbox = permissionlist[key as keyof typeof permissionlist];

        return (
            <Form.Item
                name={['permissions', `${key}`]}
                key={index}
                valuePropName="checked"
                className="mb-0">
                <Checkbox>
                    {labelCheckbox}
                </Checkbox>
            </Form.Item>
        )
    })

    const updateData = async (values: usersAddConfig) => {
        try {
            const res = await PrefixService.addUsers(values)
            setBtnLoading(false)
            onloadData()
            toast.success(res.message)
            close()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: usersAddConfig) => {
                setBtnLoading(true)
                if (workingTime) {
                    values.work_start = moment(values.work_start).format("HH:mm:ss")
                    values.work_end = moment(values.work_end).format("HH:mm:ss")
                } else {
                    values.work_start = "00:00:00"
                    values.work_end = "00:00:00"
                }
                updateData(values)
            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
        }
    }, [form, isOpen]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="addUserPermissions"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="username"
                                label="ชื่อผู้ใช้งาน"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อผู้ใช้งาน' }]}>
                                <Input placeholder="ชื่อผู้ใช้งาน" inputMode="text" defaultValue="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="password"
                                label="รหัสผ่าน"
                                rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
                                hasFeedback>
                                <Input.Password placeholder="รหัสผ่าน" inputMode="text" defaultValue="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {workingTime &&
                        <Row gutter={16}>
                            <Col span={24}>
                                <Space>
                                    <Form.Item
                                        name="work_start"
                                        label="เวลาเริ่มงาน"
                                        rules={[{ type: 'object' as const, required: true, message: 'กรุณากรอกเวลาเริ่มงาน' }]}
                                        hasFeedback>
                                        <TimePicker format={"HH:mm"} showNow={false} placeholder={"เวลาเริ่มงาน"} />
                                    </Form.Item>
                                    -
                                    <Form.Item
                                        name="work_end"
                                        label="เวลาสิ้นสุดงาน"
                                        rules={[{ type: 'object' as const, required: true, message: 'กรุณากรอกเวลาสิ้นสุดงาน' }]}
                                        hasFeedback>
                                        <TimePicker format={"HH:mm"} showNow={false} placeholder={"เวลาสิ้นสุดงาน"} />
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                    }
                    <Row gutter={16}>
                        <Col span={24}>
                            {listPermissions}
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                            <Button onClick={close} size="large">ยกเลิก</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default AddData

