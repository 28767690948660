import React, { FC } from 'react'
import { NavLink } from "react-router-dom";

import { message } from 'antd'
interface AvatarMemberProps {
    fullname: string
    phone?: string
    search: boolean
}

const AvatarMemberComponent: FC<AvatarMemberProps> = ({ fullname, phone, search }) => {
    const copyData = (text: any) => {
        navigator.clipboard.writeText(`${text}`)
        message.success('คัดลอกข้อมูลสำเร็จ')
    }

    if (search) {
        return (
            <>
                <NavLink
                    className="text-link"
                    to={`/members?search=${phone}`}
                    key={phone}>
                        <div className="row-member">
                            <div className="row-name">{fullname}</div>
                        </div>
                </NavLink>
                {phone &&
                    <div className="text-link">
                        <div className="row-member">
                            <div className="row-member-phone" onClick={() => copyData(phone)}>
                                <i className="fa-solid fa-phone-square me-2"></i>
                                <span>{phone}</span>
                            </div>
                        </div>
                    </div>
                }

            </>
        )
    } else {
        return (
            <div className="row-member">
                <div className="row-name">{fullname}</div>
                {phone &&
                    <div className="row-member-phone" onClick={() => copyData(phone)}>
                        <i className="fa-solid fa-phone-square me-2"></i>
                        <span>{phone}</span>
                    </div>
                }
            </div>
        )
    }

}

export default AvatarMemberComponent
