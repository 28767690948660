import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Space,
    Divider,
    Switch
} from 'antd'
// import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import PrefixService from "../../../services/prefix"
import { requestAuthInit } from '../../../store/auth/auth.actions'
import { BankConfig } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import { bankSelect } from '../../../utils/bank'

interface EditTruewalletProps {
    close: () => void
    isOpen: boolean

    data: any
}

const EditTruewallet: FC<EditTruewalletProps> = ({ close, isOpen, data }) => {
    const [form] = Form.useForm();
    const title = "แก้ไขบัญชีทรูมันนี่วอลเล็ท"
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    const updateData = async (values: BankConfig) => {
        try {
            const res = await PrefixService.editBankAccount(values)
            setBtnLoading(false)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            close()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: BankConfig) => {
                setBtnLoading(true)
                values.oldAccountNumber = data.accountNumber
                updateData(values)
            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpen && data) {
            form.resetFields();
            form.setFieldsValue(data)
            form.setFieldsValue({ "bank": bankSelect[data.bank] })
        }
    }, [form, isOpen, data]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="EditBank"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="enable"
                                label="สถานะ"
                                valuePropName="checked">
                                <Switch
                                    checkedChildren="เปิด"
                                    unCheckedChildren="ปิด" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="hide"
                                label="ซ่อนจากเว็บไซต์"
                                valuePropName="checked">
                                <Switch
                                    checkedChildren="เปิด"
                                    unCheckedChildren="ปิด" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="accountName"
                                label="ชื่อบัญชี"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อบัญชี' }]}
                                hasFeedback>
                                <Input placeholder="ชื่อบัญชี" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="accountNumber"
                                label="เลขที่บัญชี"
                                rules={[{ required: true, message: 'กรุณากรอกเลขที่บัญชี' }]}
                                hasFeedback>
                                <Input placeholder="เลขที่บัญชี" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                            <Button onClick={close} size="large">ยกเลิก</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditTruewallet

