import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { Select, Collapse, Row, Col, Space } from 'antd'
import moment from 'moment'
import TitleBar from '../../../components/Elements/TitleBar'
import DatePickerRange from '../../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../../components/Elements/DateFilterTabs'
import TableComponent from '../../../components/Elements/TablePagination'
import StatusBadge from '../../../components/Elements/StatusBadge'
import AvatarBankComponent from '../../../components/Elements/AvatarBank'
import SearchData from '../../../components/Elements/Search'
import AmountCardBank from '../../../components/Elements/AmountCardBank'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import { statusTransaction } from '../../../utils/StatusTransaction'
import { getBankIcon, getBankName } from '../../../utils/bank'
import useDateRange from "../../../hooks/useDateRange"
import useFetchTransactionList from '../../../hooks/useFetchTransactionList'
import { BankTransaction } from '../../../@types/transactions'
import useDatePagination from '../../../hooks/useDatePagination'
import useFetchTransactionSummary from '../../../hooks/useFetchTransactionSummary'

const TransactionBank = () => {
    const { Option } = Select;
    const { Panel } = Collapse;
    const { dateRange, setDateRange } = useDateRange()
    const [dataSource, setDataSource] = useState<any>([])
    const [dataSummary, setDataSummary] = useState<any>([])
    const [filterText, setFilterText] = useState('')
    const [searchAlldata, setSearchAllData] = useState<string>("")
    const [status, setStatus] = useState('all')

    const { pagination, setPagination } = useDatePagination()

    const { data: dataSummaryList, isLoading: isLoadingSummary, mutate: mutateSummary } = useFetchTransactionSummary(dateRange, 10000)

    const { data, isLoading, mutate } = useFetchTransactionList<BankTransaction>(`/bank-transactions`, {
        dateRange: dateRange,
        type: 'bank-transactions',
        status: status !== 'all' ? status : '',
        search: searchAlldata
    }, 10000, { page: pagination.page, limit: pagination.pageSize })

    const columns: any = [
        {
            title: 'เวลา',
            dataIndex: 'createdAt',
            width: '120px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'ธนาคาร',
            dataIndex: 'bank',
            render: (text: any, record: any) => (
                <>
                    <AvatarBankComponent
                        bankCode={record.bankType}
                        bankNumber={record.bankAccount}
                        text={(record.provider).toUpperCase()}
                    />
                </>
            )
        },
        {
            title: 'จำนวนเงิน',
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.amount, 2)}
                </div>
            )
        },
        {
            title: 'รายละเอียด',
            dataIndex: 'remark',
            width: '180px',
        },
        {
            title: 'สถานะ',
            dataIndex: 'status',
            width: '90px',
            render: (text: any, record: any) => (
                <StatusBadge status={record.status} />
            )
        }
    ]


    const listStatusTransaction = Object.keys(statusTransaction).map((key: any) => {
        return <Option key={key} value={key}>{statusTransaction[key]}</Option>
    })

    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.transactions.length > 0) {
            // const filteredItems = (data.transactions as any).filter(
            //     (item: any) => (
            //         (item.bankAccount && item.bankAccount.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.bankType && item.bankType.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.provider && item.provider.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.remark && item.remark.toLowerCase().includes(filterText.toLowerCase()))
            //     ),
            // );
            setDataSource(data.transactions)
        }
    }, [data, isLoading, filterText, setDataSource]) // eslint-disable-line

    useEffect(() => {
        setDataSummary([])
        let summaryDefault: any = {
            'SCB': {
                'api': 0,
                'sms': 0
            },
            'KBANK': {
                'internetBanking': 0,
                'sms': 0,
            },
            'TRUEWALLET': {
                'api': 0,
                'voucher': 0,
            }
        }
        dataSummaryList !== undefined && (dataSummaryList.summary).forEach((item: any) => {
            summaryDefault[`${item.bank_type}`][`${item.provider}`] = item.cnt
        })
        setDataSummary(summaryDefault)
    }, [dataSummaryList, isLoadingSummary, setDataSummary]) // eslint-disable-line

    useMemo(() => {
        mutate()
        mutateSummary()
    }, [setDateRange, setStatus, setPagination])// eslint-disable-line

    const handleSearch = () => {
        setSearchAllData(filterText)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        mutate()
        mutateSummary()
    }

    return (
        <Fragment>
            <TitleBar title="รายการเงินเข้า" subTitle={`${NumberWithCommas(data?.pagination.Count || 0)} รายการ`} />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <Space>
                    <SearchData onChangeSearch={setFilterText} filterText={filterText} />
                    <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">ค้นหา</button>
                </Space>
                <div className="ms-auto mt-3 mt-md-0 mt-xl-0">
                    <label className="me-2">สถานะ : </label>
                    <Select
                        size="large"
                        dropdownMatchSelectWidth={false}
                        placement="bottomRight"
                        value={status}
                        onSelect={(value: any) => {
                            setStatus(value)
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {listStatusTransaction}
                    </Select>
                </div>
            </div>
            <Collapse ghost>
                <Panel header="สรุปรายการเงินเข้า" key="1" className="antd-custom-panel">
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <AmountCardBank
                                icon={getBankIcon('SCB')}
                                theme='info'
                                // number={countSCBApi(dataSource)}
                                number={NumberWithCommas(dataSummary?.SCB?.api)}
                                amountType={`${getBankName('SCB')} (API)`}
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCardBank
                                icon={getBankIcon('SCB')}
                                theme='info'
                                // number={countSCBSms(dataSource)}
                                number={NumberWithCommas(dataSummary?.SCB?.sms)}
                                amountType={`${getBankName('SCB')} (SMS)`}
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCardBank
                                icon={getBankIcon('KBANK')}
                                theme='success'
                                // number={countKbankKbiz(dataSource)}
                                number={NumberWithCommas(dataSummary?.KBANK?.internetBanking)}
                                amountType={`${getBankName('KBANK')} (Kbiz)`}
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCardBank
                                icon={getBankIcon('KBANK')}
                                theme='success'
                                // number={countKbankSms(dataSource)}
                                number={NumberWithCommas(dataSummary?.KBANK?.sms)}
                                amountType={`${getBankName('KBANK')} (SMS)`}
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCardBank
                                icon={getBankIcon('TRUEWALLET')}
                                theme='warning'
                                // number={countTrueWalletAPI(dataSource)}
                                number={NumberWithCommas(dataSummary?.TRUEWALLET?.api)}
                                amountType={`${getBankName('TRUEWALLET')} (API)`}
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCardBank
                                icon={getBankIcon('TRUEWALLET')}
                                theme='warning'
                                // number={countTrueWalletVoucher(dataSource)}
                                number={NumberWithCommas(dataSummary?.TRUEWALLET?.voucher)}
                                amountType={`${getBankName('TRUEWALLET')} (Voucher)`}
                                loading={isLoading}
                            />
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.pagination.Count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>
        </Fragment>
    )
}

export default TransactionBank