import React, { FC } from 'react'
import moment from 'moment'
import { Button, Space } from 'antd'

interface DateFilterShowtimeProps {
    onChangeDate: (e: any) => void
    onDateActive: any
}

const DateFilterShowtime: FC<DateFilterShowtimeProps> = ({ onChangeDate, onDateActive }) => {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss'
    const dateFormatS = 'YYYY-MM-DD 00:00:00'
    const dateFormatE = 'YYYY-MM-DD 23:59:59'
    const sDateActive = moment(onDateActive?.start).format(dateFormat);
    const eDateActive = moment(onDateActive?.end).format(dateFormat);

    let activeClass: string = "";
    switch (`${sDateActive}-${eDateActive}`) {
        case `${moment().format(dateFormatS)}-${moment().format(dateFormatE)}`:
            activeClass = "today"
            break;

        case `${moment().subtract(1, 'days').format(dateFormatS)}-${moment().subtract(1, 'days').format(dateFormatE)}`:
            activeClass = "yesterday"
            break;

        case `${moment().startOf('isoWeek').format(dateFormatS)}-${moment().endOf('isoWeek').format(dateFormatE)}`:
            activeClass = "this_week"
            break;

        case `${moment().startOf('isoWeek').subtract(7, 'days').format(dateFormat)}-${moment().endOf('isoWeek').subtract(7, 'days').format(dateFormatE)}`:
            activeClass = "last_week"
            break;

        case `${moment().startOf('month').format(dateFormatS)}-${moment().endOf('month').format(dateFormatE)}`:
            activeClass = "this_month"
            break;

        case `${moment().subtract(1, 'months').startOf('month').format(dateFormatS)}-${moment().subtract(1, 'months').endOf('month').format(dateFormatE)}`:
            activeClass = "last_month"
            break;

        default:
            break;
    }

    const onDateSearch = (date: any) => {
        if (date !== null) {
            onChangeDate && onChangeDate({
                start: moment(date[0]).format(dateFormatS),
                end: moment(date[1]).format(dateFormatE),
            })
        }
    }

    return (
        <Space size={4} className="date-filter">
            <Button type={activeClass === 'today' ? 'primary' : 'text'} onClick={() => onDateSearch([moment().startOf('day'), moment().endOf('day')])}>Today</Button>
            <Button type={activeClass === 'yesterday' ? 'primary' : 'text'} onClick={() => onDateSearch([moment().startOf('day').subtract(1, 'days'), moment().endOf('day').subtract(1, 'days')])}>Yesterday</Button>
            <Button type={activeClass === 'this_week' ? 'primary' : 'text'} onClick={() => onDateSearch([moment().startOf('isoWeek'), moment().endOf('isoWeek')])}>This week</Button>
            <Button type={activeClass === 'last_week' ? 'primary' : 'text'} onClick={() => onDateSearch([moment().startOf('isoWeek').subtract(7, 'days'), moment().endOf('isoWeek').subtract(7, 'days')])}>Last week</Button>
            <Button type={activeClass === 'this_month' ? 'primary' : 'text'} onClick={() => onDateSearch([moment().startOf('month'), moment().endOf('month')])}>This month</Button>
            <Button type={activeClass === 'last_month' ? 'primary' : 'text'} onClick={() => onDateSearch([moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')])}>Last month</Button>
        </Space>
    )
}

export default DateFilterShowtime