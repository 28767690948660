import React, { Fragment, useState, useEffect } from 'react'
import { Tabs } from 'antd'
import useDateRange from "../../../hooks/useDateRange"
import TitleBar from '../../../components/Elements/TitleBar'
import DatePickerRange from '../../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../../components/Elements/DateFilterTabs'
import DepositTab from '../../../components/Reports/ReportChart/DepositTab'
import WithdrawTab from '../../../components/Reports/ReportChart/WithdrawTab'
import MemberTab from '../../../components/Reports/ReportChart/MemberTab'

const ReportChart = () => {
    const { dateRange, setDateRange } = useDateRange()
    const { TabPane } = Tabs;
    const [defaultActiveKey, setDefaultActiveKey] = useState<string>("1")

    useEffect(() => {
        setDefaultActiveKey("1")
    }, []);


    return (
        <Fragment>
            <TitleBar title="กราฟข้อมูล" subTitle={``} />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-5">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>

            <Tabs
                activeKey={defaultActiveKey}
                centered
                onChange={(key) => setDefaultActiveKey(key)}
                style={{ overflow: 'visable' }}
            >
                <TabPane tab="รายงานการฝาก" key="1">
                    <DepositTab dateRange={dateRange} />
                </TabPane>
                <TabPane tab="รายงานการถอน" key="2">
                    <WithdrawTab dateRange={dateRange} />
                </TabPane>
                <TabPane tab="รายงานการสมัครสมาชิก" key="3">
                    <MemberTab dateRange={dateRange} />
                </TabPane>
            </Tabs>
        </Fragment>
    )
}

export default ReportChart
