import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Space,
    Divider
} from 'antd'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import PrefixService from "../../../services/prefix"
import { requestAuthInit } from '../../../store/auth/auth.actions'
import { truewalletConfirmOTPParams, truewalletRequestOTPParams } from '../../../@types/truewallet'
import { ThunkDispatch } from '../../../@types/store'
import PatternNumber from '../../../utils/PatternNumber'

interface AddTruewalletProps {
    close: () => void
    isOpen: boolean
}

const AddTruewallet: FC<AddTruewalletProps> = ({ close, isOpen }) => {
    const [form] = Form.useForm();
    const title = "เพิ่มบัญชีทรูมันนี่วอลเล็ท"
    const [btnLoading, setBtnLoading] = useState(false)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    const insertData = async (values: truewalletRequestOTPParams) => {
        try {
            const res = await PrefixService.requestTruewalletOTP({
                phone: values.phone,
                password: values.password,
                pin: values.pin
            })
            const otpRef = res.otpRef
            if (otpRef) {
                toast.success("กรุณาตรวจสอบรหัส OTP ที่ได้รับจากระบบทรูมันนี่")
                Swal.fire({
                    title: 'OTP',
                    text: `กรุณากรอก OTP (Ref:${otpRef})`,
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'ยกเลิก',
                    confirmButtonText: 'ตกลง'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const res: any = await PrefixService.confirmTruewalletOTP({
                                phone: values.phone,
                                password: values.password,
                                pin: values.pin,
                                otpRef: otpRef,
                                otpCode: result.value
                            } as truewalletConfirmOTPParams)
                            setBtnLoading(false)
                            dispatch(requestAuthInit(false))
                            toast.success(res.message)
                            close()
                        } catch ({ message }) {
                            toast.error(message as string)
                            setBtnLoading(false)
                            form.setFieldsValue({ 'otpRef': "" })
                        } finally {
                            setBtnLoading(false)
                            form.setFieldsValue({ 'otpRef': "" })
                        }
                    } else {
                        setBtnLoading(false)
                        form.setFieldsValue({ 'otpRef': "" })
                    }
                })
            } else {
                setBtnLoading(false)
                form.setFieldsValue({ 'otpRef': "" })
            }
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: truewalletRequestOTPParams) => {
                setBtnLoading(true)
                insertData(values)
            })
            .catch((errorInfo) => { });
    };


    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
        }
    }, [form, isOpen]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }>
                <Form
                    form={form}
                    name="AddTruewallet"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="phone"
                                label="เบอร์โทรศัพท์"
                                rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <Input placeholder="เบอร์โทรศัพท์" maxLength={10} showCount inputMode="tel" autoComplete="new-phone" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="password"
                                label="รหัสผ่าน"
                                rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
                                hasFeedback>
                                <Input.Password placeholder="รหัสผ่าน" inputMode="text" autoComplete="new-password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="pin"
                                label="PIN"
                                rules={[{ required: true, message: 'กรุณากรอก PIN' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <Input.Password placeholder="PIN" maxLength={6} showCount inputMode="numeric" autoComplete="new-pin" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                            <Button onClick={close} size="large">ยกเลิก</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default AddTruewallet

