import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Space,
    Radio,
    InputNumber,
    // DatePicker,
    Divider
} from 'antd'
import { toast } from 'react-toastify'
import MaskedInput from "antd-mask-input"
import PatternNumber from '../../utils/PatternNumber'
import PrefixService from "../../services/prefix"
import { FixTransactionParams } from '../../@types/transactions'
// import { useSelector } from 'react-redux'
// import { ApplicationState } from '../../store'
// import moment from 'moment'

interface AddProps {
    close: () => void
    onloadData: () => void
    isOpen: boolean

    phone?: string
}

const AddData: FC<AddProps> = ({ close, isOpen, onloadData, phone }) => {
    const [form] = Form.useForm();
    const title = "แก้ไขรายการผิดพลาด"
    const [btnLoading, setBtnLoading] = useState(false)
    const [fieldValue, setFieldListValue] = useState('depositMemberBank')

    // const prefixState = useSelector((state: ApplicationState) => state.prefix)
    // const lineTokenFixTx = prefixState.prefix.setting.lineTokenFixTx
    const lineTokenFixTx = ''

    const updateData = async (values: FixTransactionParams) => {
        try {
            const res = await PrefixService.fixTx(values)
            // const resLineNoti = await PrefixService.fixTxLineNoti(values)

            setBtnLoading(false)
            onloadData()
            toast.success(res.message)
            // form.resetFields();
            // form.setFieldsValue({
            //     fixType: 'depositMemberBank',
            //     phone: phone,
            //     amount: 0,
            //     turn: 0,
            //     maxWithdraw: 0,
            // });
            close()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: FixTransactionParams) => {
                setBtnLoading(true)
                // values.transferTime = moment(values.transferTime).format("DD/MM/YYYY 00:00")
                updateData(values)
            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
            form.setFieldsValue({
                fixType: fieldValue,
                phone: phone,
                amount: 0,
                turn: 0,
                maxWithdraw: 0,
            });
        }
    }, [form, isOpen, phone, fieldValue]);

    return (
        <>

            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="addFixTx"
                    layout="vertical"
                    hideRequiredMark
                    initialValues={{
                        'linetoken': lineTokenFixTx,
                    }}
                >
                    <Form.Item
                        name="linetoken"
                        style={{ display: 'none' }}>
                        <Input type="text" />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="fixType"
                                label="ประเภทรายการ"
                                hasFeedback>
                                <Radio.Group buttonStyle="solid" className="ant-radio-button-custom">
                                    <Radio.Button value="depositMemberBank" key="depositMemberBank" onClick={() => setFieldListValue('depositMemberBank')}>ธนาคารไม่รัน</Radio.Button>
                                    <Radio.Button value="depositMemberTruewallet" key="depositMemberTruewallet" onClick={() => setFieldListValue('depositMemberTruewallet')}>ทรูมันนี่วอลเล็ทไม่รัน</Radio.Button>
                                    <Radio.Button value="addCredit" key="addCredit" onClick={() => setFieldListValue('addCredit')}>เติมเครดิต</Radio.Button>
                                    <Radio.Button value="subCredit" key="subCredit" onClick={() => setFieldListValue('subCredit')}>ตัดเครดิต</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="phone"
                                label="เบอร์โทรศัพท์"
                                rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <Input placeholder="เบอร์โทรศัพท์" inputMode="tel" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label="จำนวนเงิน"
                                rules={[{ required: true, message: 'กรุณากรอกจำนวนเงิน' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <InputNumber placeholder="จำนวนเงิน" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {fieldValue === 'addCredit' && (
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="turn"
                                    label="เทิร์น (เท่า)"
                                    rules={[{ required: true, message: 'กรุณากรอกจเทิร์น (เท่า)' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                    hasFeedback>
                                    <InputNumber placeholder="เทิร์น (เท่า)" inputMode="numeric" controls={false} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="maxWithdraw"
                                    label="ถอนสูงสุด (เท่า)"
                                    rules={[{ required: true, message: 'กรุณากรอกถอนสูงสุด (เท่า)' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                    hasFeedback>
                                    <InputNumber placeholder="ถอนสูงสุด (เท่า)" inputMode="numeric" controls={false} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {(fieldValue === 'depositMemberBank' || fieldValue === 'depositMemberTruewallet') && (
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="transferTime"
                                    label="วัน/เดือน/ปี(ค.ศ.) เวลาที่โอนเงิน"
                                    rules={[{ required: true, message: 'กรุณากรอกวัน/เดือน/ปี(ค.ศ.) เวลาที่โอนเงิน' }]}
                                    hasFeedback>
                                    <MaskedInput mask={'00/00/0000 00:00'} placeholder="วัน/เดือน/ปี(ค.ศ.) เวลาที่โอนเงิน" />
                                    {/* <DatePicker placeholder="วัน/เดือน/ปี เวลาที่โอนเงิน" showTime format="YYYY-MM-DD HH:mm" /> */}
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="remark"
                                label="หมายเหตุ"
                                hasFeedback>
                                <Input placeholder="หมายเหตุ" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                            <Button onClick={close} size="large">ยกเลิก</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default AddData

