import React, { useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import useFetchTransactionList from '../../hooks/useFetchTransactionList'
import NumberWithCommas from '../../utils/NumberWithCommas'
import { getBankName } from '../../utils/bank'
import Loading from '../Elements/Loading'
import Empty from '../Elements/Empty'
import FormattedMessage from '../Elements/FormattedMessage'
import ModalBankDuplicate from '../ModalBankDuplicate'
import { ApplicationState } from '../../store'
import { useSelector } from 'react-redux'
import PrefixService from '../../services/prefix'

const DepositTransactionLastest = () => {

    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const dateFormat = "DD/MM/YYYY HH:mm:ss"
    const [isDuplicateVisible, setIsDuplicateVisible] = useState<boolean>(false);
    const toggleModal = () => setIsDuplicateVisible(!isDuplicateVisible)
    // const [dataDupicate, setDataDupicate] = useState<any>([])
    const [dataDupicateID, setDataDupicateID] = useState<number>(0)
    const [dataDupicateNote, setDataDupicateNote] = useState<string>("")
    const { data, mutate, isLoading } = useFetchTransactionList(`/deposit`, {
        dateRange: {
            start: moment().add(-1, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD')
        },
        type: 'deposit',
        status: 'bank_duplicate',
        limit: 20
    }, 30000)

    const getPromotion = (name: any) => {
        const promotion = prefix.promotions !== undefined && Object.fromEntries(
            Object.entries(prefix.promotions).filter(([key]) => (key === name))
        )
        if (Object.values(promotion)[0]?.enable === true) {
            return Object.values(promotion)[0]?.name || name
        } else {
            return name
        }
    }

    const handleCancelTx = (txID: number) => {
        if (txID) {
            Swal.fire({
                title: `คุณต้องการยกเลิกรายการ #${txID}?`,
                text: "ตรวจสอบให้แน่ใจว่าคุณต้องการยกเลิกรายการใช่หรือไม่",
                input: 'text',
                inputLabel: 'หมายเหตุ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ตกลง'
            }).then(async (result) => {
                if (result.isConfirmed && result.value) {
                    try {
                        const res = await PrefixService.cancelTxDeposit(txID, result.value)
                        toast.success(res.message)
                        mutate()
                    } catch ({ message }) {
                        toast.error(message as string)
                    }
                }
            })
        }
    }


    return (
        <>

            <div className="mb-4">
                <h5 className="mb-3"><FormattedMessage id={'main.deposit-list'} defaultMessage={'รายการฝาก'} /></h5>
                {!isLoading ?
                    data !== undefined && data?.count !== 0 ?
                        Object.values(data?.transactions as any).map((item: any, index: number) => (
                            <div className="box-white box-list" key={index}>
                                <div className="box-list-head">
                                    {item.status !== "bank_duplicate" ? (
                                        <div>
                                            <div>{item.member.firstName} {item.member.lastName}</div>
                                            <div><small>{item.member.game.username}</small></div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div>Unknown</div>
                                            <div><small></small></div>
                                        </div>
                                    )}
                                    <div className="ms-auto text-end">
                                        <div><span>฿</span> {NumberWithCommas(item.amount, 2)}</div>
                                        <div className="text-status-danger"><small>รายการฝากผิดพลาด</small></div>
                                    </div>
                                </div>
                                <div className="box-list-footer">
                                    {item.status === "bank_duplicate" && (
                                        <small className="text-status-danger">หมายเหตุ : {item.remark}</small>
                                    )}
                                    <small>ธนาคารที่โอนเข้ามา : {getBankName(item.transferFrom)} ({item.transferFrom})</small>
                                    <small>ทำรายการโอน : {moment(item?.transferTime).format(dateFormat)}</small>
                                    <small>ดึงเข้าระบบ : {moment(item?.createdAt).format(dateFormat)}</small>
                                    {item.member.autoBonus === "off"
                                        ? (<div className="msn-badge badge-danger mt-2">ไม่รับโบนัส</div>)
                                        : (<div className="msn-badge badge-info mt-2">{getPromotion(item.member.autoBonus)}</div>)
                                    }
                                </div>
                                <div className="box-list-footer">
                                    {item.status === "bank_duplicate" && (
                                        <>
                                            <button className="btn btn-info btn-sm text-white me-2" onClick={() => {
                                                toggleModal()
                                                setDataDupicateID(item.id)
                                                setDataDupicateNote(item.remark)
                                            }}>ทำรายการ</button>
                                            <button className="btn btn-danger btn-sm text-white me-2" onClick={() => handleCancelTx(item.id)}>ยกเลิก</button>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))
                        :
                        <Empty />
                    :
                    <Loading />
                }
            </div>
            <ModalBankDuplicate
                isOpen={isDuplicateVisible}
                close={() => toggleModal()}
                onloadData={mutate}
                // dataDupicate={dataDupicate}
                dataDupicateID={dataDupicateID}
                dataDupicateNote={dataDupicateNote}
            />
        </>
    );
}

export default DepositTransactionLastest

