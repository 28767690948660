import React, { FC, Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Row, Col, Divider, Space, Button, InputNumber, Typography } from 'antd'
import useFetchSetting from "../../../../hooks/useFetchSetting"
import PrefixService from "../../../../services/prefix"
import PatternNumber from '../../../../utils/PatternNumber'
import { PointCalurateSetting } from '../../../../@types/prefix'

interface PointProps {
    setActiveKey: (key: string) => void
    setIsUpdate: (isUpdate: boolean) => void
}


const Point: FC<PointProps> = ({ setActiveKey, setIsUpdate }) => {

    const [form] = Form.useForm();
    const { data: dataSetting } = useFetchSetting()

    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const updateData = async (values: PointCalurateSetting) => {
        try {
            const res = await PrefixService.updatePointCalurate(values)
            setBtnLoading(false)
            toast.success(res.message)
            setActiveKey("2")
            setIsUpdate(true)
            // mutate()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                updateData(values);
            })
            .catch((errorInfo) => {

            });
    }


    useEffect(() => {
        if (form && dataSetting) {
            form.resetFields();
            form.setFieldsValue({ pointCalcurate: dataSetting?.setting?.point.pointCalcurate })
        }
    }, [form, dataSetting])// eslint-disable-line


    return (
        <Fragment>
            <Form
                form={form}
                name="pointForm"
                layout="vertical"
                hideRequiredMark>
                <Typography.Title level={3}>ตั้งค่าสะสมแต้มแลกของรางวัล</Typography.Title>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="pointCalcurate"
                            label="จำนวนหารยอดฝาก"
                            rules={[
                                { required: true, message: 'กรุณากรอกจำนวนหารยอดฝาก' },
                                { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' },
                                { type: 'number', min: 1, max: 100, message: `กรุณากรอกตัวเลขตั้งแต่ 1 - 100 เท่านั้น` }
                            ]}
                            hasFeedback
                            extra={`โดยระบบจะเอา ยอดฝาก หาร ตัวเลขที่กรอกด้านบน = คะแนนสะสม`}
                        >
                            <InputNumber placeholder="จำนวนหารยอดฝาก" inputMode="numeric" controls={false} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <div className="drawer-actions">
                    <Space>
                        <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                    </Space>
                </div>
            </Form>
        </Fragment>
    )
}

export default Point