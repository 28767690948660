import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Space,
    Divider,
    Descriptions,
    Row,
    Col,
    Typography,
    Modal
} from 'antd';
import Button from 'antd-button-color'
import { InfoCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { toast } from 'react-toastify'
import { NavLink } from "react-router-dom";
import TableMiniComponent from '../Elements/TableMini'
import { getBankName } from "../../utils/bank";
import NumberWithCommas from "../../utils/NumberWithCommas";
import PrefixService from '../../services/prefix'
import ViewMember from './View'

interface ViewProps {
    member: any
    close: () => void
    isOpen: boolean
    title: string
}

const View: FC<ViewProps> = ({ close, isOpen, member, title }) => {
    const { Text } = Typography;

    const [memberAffiliate, setMemberAffiliate] = useState<any>({})
    const [memberInfo, setMemberInfo] = useState<any>({})
    const [dataBonusTransactions, setDataBonusTransactions] = useState<any>([])
    const [dataAffiliates, setDataAffiliates] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [knowFrom1, setKnowFrom1] = useState<string>("")
    const [knowFrom2, setKnowFrom2] = useState<string>("")

    const [viewDrawer, setViewDrawer] = useState<boolean>(false)
    const toggleView = () => setViewDrawer(!viewDrawer)
    const [isMemberInfoVisible, setIsMemberInfoVisible] = useState<boolean>(false);
    const [isReGameUserVisable, setIsReGameUserVisable] = useState<boolean>(false);
    const [memberInfoLoading, setMemberInfoLoading] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    const handleViewInfo = async (id: number) => {
        setMemberInfoLoading(true)
        try {
            const res = await PrefixService.getMemberInfo(id, true)
            if (res) {
                setMemberInfo(res)
                setIsMemberInfoVisible(true)
            }
        } catch ({ message }) {
            toast.error(message as string)
        }
        setMemberInfoLoading(false)
    }

    const handleMemberAffiliate = async (id: number) => {
        try {
            const res = await PrefixService.getMemberInfo(id)
            setMemberAffiliate(res.member);
            toggleView()
        } catch ({ message }) {
            toast.error(message as string)
        }
    }

    const submitReGameUser = async (id: number) => {
        setConfirmLoading(true)
        try {
            const res = await PrefixService.setReGameUser(id)
            toast.success(res.message)
            setIsReGameUserVisable(false)
            setConfirmLoading(false)
        } catch ({ message }) {
            toast.error(message as string)
        }
    }

    const columnsBonus: any = [
        {
            title: 'วันที่/เวลา',
            dataIndex: 'createdAt',
            width: '140px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'จำนวนเงิน',
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <>
                    <div className="row-amount">
                        <span>฿</span> {NumberWithCommas(record.amount, 2)}
                    </div>
                    <div>{record.promotion.name}</div>
                </>
            )
        },
        {
            title: 'ยอดเทิร์น',
            dataIndex: 'totalTurn',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.totalTurn, 2)}
                </div>
            )
        },
    ]
    const columnsAffiliates: any = [
        {
            title: 'สมาชิก',
            dataIndex: 'name',
            width: '140px',
            render: (text: any, record: any) => (
                <NavLink
                    className="text-link"
                    to={`/members?search=${record.phone}`}
                    key={record.phone}
                    onClick={close}>
                    <div className="row-member">
                        <div>{record.firstName} {record.lastName}</div>
                        <div className="row-member-phone">
                            <i className="fa-solid fa-phone-square me-2"></i>
                            <span>{record.phone}</span>
                        </div>
                    </div>
                </NavLink>
            )
        },
        {
            title: 'วันที่สมัคร',
            dataIndex: 'createdAt',
            width: '140px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
    ]

    const getMemberInfo = async () => {
        try {
            const res = await PrefixService.getMemberInfo(member.id, false)
            setDataBonusTransactions(res.bonusTransactions)
            setDataAffiliates(res.affiliates)
        } catch ({ message }) {
            toast.error(message as string)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (member && member.id) {
            getMemberInfo()
            setKnowFrom1((member.knowFrom).split(',')[0])
            setKnowFrom2((member.knowFrom).split(',')[1])
        }

        return () => {
            setMemberInfo({})
            setDataBonusTransactions([])
            setDataAffiliates([])
            setMemberInfoLoading(false)
        }
    }, [member]) //eslint-disable-line


    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .8}>
                {member &&
                    <>
                        <Row gutter={16} className="mb-3">
                            <Col sm={24} lg={8} >
                                <Divider orientation="left">ข้อมูลสมาชิก</Divider>
                                <Descriptions column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }} >
                                    <Descriptions.Item label="เบอร์โทรศัพท์">{member.phone}</Descriptions.Item>
                                    <Descriptions.Item label="ชื่อสมาชิก">{member.firstName} {member.lastName}</Descriptions.Item>
                                    <Descriptions.Item label="ไอดีไลน์">{member.lineID}</Descriptions.Item>
                                    <Descriptions.Item label="รู้จักเราจาก">
                                        {knowFrom1 === "friend" ?
                                            member?.affiliate?.id !== 0 ?
                                                (<Space direction="horizontal">
                                                    <button className="btn btn-warning btn-sm text-white ms-2" onClick={() => handleMemberAffiliate(member?.affiliate?.id)}>เพื่อนแนะนำ #{member?.affiliate?.id}</button>{knowFrom2 && `| ${knowFrom2}`}
                                                </Space>
                                                )
                                                : knowFrom2 && `เพื่อนแนะนำ / ${knowFrom2}`
                                            : member.knowFrom}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="วันที่สมัครสมาชิก">{member.createdAt ? moment(member.createdAt).format("DD/MM/YYYY HH:mm:ss") : '-'}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                            <Col sm={24} lg={8}>
                                <Divider orientation="left">ข้อมูลธนาคาร</Divider>
                                <Descriptions column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                    <Descriptions.Item label="ธนาคาร">{member.bankNumber}</Descriptions.Item>
                                    <Descriptions.Item label="เลขบัญชี">{getBankName(member.bankCode)}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                            <Col sm={24} lg={8}>
                                <Divider orientation="left">ข้อมูลเครดิต</Divider>
                                <Descriptions column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                    <Descriptions.Item label="ยอดเชิญเพื่อนคงเหลือ">{NumberWithCommas(member?.affiliate?.balance)}</Descriptions.Item>
                                    {/* <Descriptions.Item label="ยอดคงเหลือขั้นต่ำ">{NumberWithCommas(member?.minAvailable, 2)} บาท</Descriptions.Item> */}
                                    <Descriptions.Item label="ยอดถอนขั้นต่ำ">{NumberWithCommas(member.minWithdraw, 2)} บาท</Descriptions.Item>
                                    <Descriptions.Item label="ยอดถอนสูงสุด">{NumberWithCommas(member.maxWithdraw, 2)} บาท</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Space>

                            <Button type="primary" className="ms-2" loading={memberInfoLoading} onClick={() => handleViewInfo(member.id)}>
                                {/* {
                                    memberInfoLoading
                                        ? (<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>)
                                        : ''
                                } */}
                                ดูข้อมูลยูสเซอร์
                            </Button>
                            <Button type="danger" className="ms-2" onClick={() => setIsReGameUserVisable(true)}>เปิดยูสเซอร์ใหม่</Button>
                        </Space>
                        <Divider />
                        <Row gutter={16}>
                            <Col sm={24} lg={12} className="mb-3">
                                <h5>รายการรับโบนัส <small>(50 รายการล่าสุด)</small></h5>
                                <TableMiniComponent
                                    columns={columnsBonus}
                                    dataSource={dataBonusTransactions || []}
                                    loading={loading}
                                    customOffsetHeader={-24}
                                />
                            </Col>
                            <Col sm={24} lg={12} className="mb-3">
                                <h5>รายการแนะนำเพื่อน</h5>
                                <TableMiniComponent
                                    columns={columnsAffiliates}
                                    dataSource={dataAffiliates || []}
                                    loading={loading}
                                    customOffsetHeader={-24}
                                />
                            </Col>
                        </Row>
                    </>
                }

                {viewDrawer && (
                    <ViewMember
                        member={memberAffiliate as any}
                        isOpen={viewDrawer}
                        close={() => toggleView()}
                        title={`ข้อมูลสมาชิก #${member?.affiliate?.id}`}
                    />
                )}

                {isMemberInfoVisible && (
                    <Modal
                        title="ข้อมูลยูสเซอร์"
                        visible={isMemberInfoVisible}
                        onCancel={() => {
                            setMemberInfo({})
                            setIsMemberInfoVisible(false)
                        }}
                        footer={[
                            <Button key="submit" type="primary" onClick={() => setIsMemberInfoVisible(false)}>ปิด</Button>,
                        ]}>
                        {memberInfo && (
                            <>
                                <Space direction="vertical" size={0}>
                                    <Text type="secondary">ชื่อผู้ใช้งาน</Text>
                                    <Typography.Title level={5} style={{ margin: 0 }}>{memberInfo?.member?.game?.username}</Typography.Title>
                                </Space>
                                <Divider className="my-2" />
                                <Space direction="vertical" size={0}>
                                    <Text type="secondary">รหัสผ่าน</Text>
                                    <Typography.Title level={5} style={{ margin: 0 }}>{memberInfo?.member?.game?.password}</Typography.Title>
                                </Space>
                                <Divider className="my-2" />
                                <Space direction="vertical" size={0}>
                                    <Text type="secondary">เครดิตคงเหลือ</Text>
                                    <Typography.Title level={5} style={{ margin: 0 }}>฿ {NumberWithCommas(memberInfo?.credit, 2)}</Typography.Title>
                                </Space>
                                <Divider className="my-2" />
                                <Space direction="vertical" size={0}>
                                    <Text type="secondary">เทิร์นโอเวอร์</Text>
                                    <Typography.Title level={5} style={{ margin: 0 }}>฿ {NumberWithCommas(memberInfo?.currentTurnover, 2)}</Typography.Title>
                                </Space>
                            </>
                        )}
                    </Modal>
                )}

                {isReGameUserVisable && (
                    <Modal
                        visible={isReGameUserVisable}
                        onOk={() => submitReGameUser(member.id)}
                        onCancel={() => setIsReGameUserVisable(false)}
                        footer={[
                            <Button key="cancel" type="danger" onClick={() => setIsReGameUserVisable(false)}>ยกเลิก</Button>,
                            <Button key="submit" type="primary" loading={confirmLoading} onClick={() => submitReGameUser(member.id)}>ตกลง</Button>,
                        ]}
                    >
                        <div className="d-flex align-items-center">
                            <InfoCircleTwoTone twoToneColor="#ffc602" style={{ fontSize: '44px' }} />
                            <Space direction="vertical" size={0} className="ps-3">
                                <Typography.Title level={4} style={{ marginBottom: '4px' }}>ต้องการเปิดยูสเซอร์ใหม่?</Typography.Title>
                                <p style={{ fontSize: '16px', marginBottom: '0' }}>เมื่อเปิดเปิดยูสเซอร์ใหม่เครดิตเก่าจะหายทั้งหมด</p>
                            </Space>
                        </div>
                    </Modal>
                )}
            </Drawer>

        </>
    )
}
export default View
