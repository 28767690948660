import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { UncontrolledCollapse } from 'reactstrap'
import './../../assets/scss/sidebar.scss'
import listMenuSidebar from '../../utils/SidebarConfig'
import NamePrefix from './NamePrefix'
import FormattedMessage from '../../components/Elements/FormattedMessage'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

const Sidebar = () => {

  const currentLocation = useLocation().pathname
  const [menuSidebar, setMenuSidebar] = useState<any>(listMenuSidebar)
  const { permissions } = useSelector((state: ApplicationState) => state.auth)
  const { features, prefix } = useSelector((state: ApplicationState) => state.prefix)
  const [subMenuIsOpen, setSubMenuIsOpen] = useState<string>("")

  const toggleMenu = (menu: string) => {
    setSubMenuIsOpen(subMenuIsOpen === menu ? "" : menu)
  }

  const MenuSideBar = Object.values(menuSidebar).map((item: any) => {
    if (permissions[item.permission_key] || item.permission_key === 'manage.all' || permissions["@admin"]) {
      if (item.features_key === "" || features[item.features_key] === false || permissions["@admin"]) {
        if (typeof item.submenu === 'undefined') {
          return (
            <li className="nav-item" key={item.id}>
              <Link
                to={item.path}
                key={`link-${item.id}`}
                onClick={() => toggleMenu("")}
                className={`nav-link link-dark ${(currentLocation === item.path && 'active')}`}>
                <i className={item.icon}></i>
                <span>
                  <FormattedMessage id={item.title_id} defaultMessage={item.title} />
                </span>
              </Link>
            </li>
          )
        } else { // if (typeof item.submenu === 'object') 
          return (
            <li className="nav-item" key={item.id}>
              <Link
                to={item.path}
                id={`menuId-${item.id}`}
                key={`link-${item.id}`}
                onClick={() => toggleMenu(`menuId-${item.id}`)}
                onTouchStart={() => toggleMenu(`menuId-${item.id}`)}
                className={`nav-link link-dark ${Object.values(item.submenu).find((item_s: any) => item_s.path === currentLocation) ? 'active' : ''}`}>
                <i className={item.icon}></i>
                <span>
                  <FormattedMessage id={item.title_id} defaultMessage={item.title} />
                </span>
                <i className="fa-duotone fa-chevron-down"></i>
              </Link>
              <UncontrolledCollapse
                className="nav-sub-item"
                key={`nav-sub-link-${item.id}`}
                toggler={`#menuId-${item.id}`}
                isOpen={subMenuIsOpen === `menuId-${item.id}` ? true :
                  (Object.values(item.submenu).find((item_s: any) => item_s.path === currentLocation) ? true : false)}>
                {Object.values(item.submenu).map((subItem: any) => {
                  if (subItem.features_key === "" || features[subItem.features_key]) {
                    if (subItem.id === 33) {
                      if (prefix.setting.ambbetGames !== null) {
                        return (
                          <Link
                            to={subItem.path}
                            key={`link-${subItem.id}`}
                            className={`nav-sub-link ${(currentLocation === subItem.path ? 'active' : '')}`}>
                            <span>
                              <FormattedMessage id={subItem.title_id} defaultMessage={subItem.title} />
                            </span>
                          </Link>
                        )
                      } else {
                        return (<div key={item.id}></div>)
                      }
                    } else {
                      if (permissions[subItem.permission_key] || subItem.permission_key === 'manage.all' || permissions["@admin"]) {
                        return (
                          <Link
                            to={subItem.path}
                            key={`link-${subItem.id}`}
                            className={`nav-sub-link ${(currentLocation === subItem.path ? 'active' : '')}`}>
                            <span>
                              <FormattedMessage id={subItem.title_id} defaultMessage={subItem.title} />
                            </span>
                          </Link>
                        )
                      } else {
                        return (<div key={subItem.id}></div>)
                      }
                    }
                  } else {
                    return (<div key={subItem.id}></div>)
                  }
                })}
              </UncontrolledCollapse>
            </li>
          )
        }
        //  else {
        //   return (<></>)
        // }
      } else {
        return (<div key={item.id}></div>)
      }
    } else {
      return (<div key={item.id}></div>)
    }
  })

  useEffect(() => {
    setMenuSidebar(listMenuSidebar)
  }, [])


  return (
    <div className="sidebar">
      <NamePrefix className="mb-5" />
      <div className="sidebar-menu">
        <ul className="nav nav-pills flex-column mb-auto">
          {MenuSideBar}
        </ul>
      </div>
      <div className="nav-pills sidebar-footer">
        <Link
          to="/logout"
          key="logout"
          className="nav-link link-dark pb-0">
          <i className="fa-solid fa-sign-out"></i>
          <span>
            <FormattedMessage id="logout" defaultMessage="ออกจากระบบ" />
          </span>
        </Link>
      </div>
    </div>
  );
}

export default Sidebar;
