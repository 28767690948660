import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Space,
    Divider,
    Radio,
    Select
} from 'antd'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import MaskedInput from "antd-mask-input"
import PrefixService from "../../../services/prefix"
import { requestAuthInit } from '../../../store/auth/auth.actions'
import { AddSCBAccountData } from '../../../@types/scb' //, AddSCBAccountResponse, ConfirmSCBAccountOTPData
import { ThunkDispatch } from '../../../@types/store'
import PatternNumber from '../../../utils/PatternNumber'
import countryCode from '../../../mockup-json/countryCode.json'

interface ScbAPIProps {
    close: () => void
    isOpen: boolean

    data: any
}

const ScbAPI: FC<ScbAPIProps> = ({ close, isOpen, data }) => {
    const [form] = Form.useForm();
    const title = "ตั้งค่า SCB API"
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [type, setType] = useState<string>('idCard')
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const { Option } = Select

    const scbOTP = async (values: AddSCBAccountData) => {
        try {
            const res = await PrefixService.requestSCBAccountOTP(values)
            toast.success(res.message)
            form.setFieldsValue({ 'deviceID': res.data.deviceID })
            const DeviceID = res.data.deviceID;
            if (res.data.deviceID) {
                Swal.fire({
                    title: 'OTP',
                    text: "กรุณากรอก OTP",
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'ยกเลิก',
                    confirmButtonText: 'ตกลง'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const res: any = await PrefixService.confirmSCBAccountOTP(DeviceID, {
                                accountNumber: values.accountNumber,
                                pin: values.pin,
                                otpCode: result.value,
                            })
                            toast.success(res.message)
                            setBtnLoading(false)
                            dispatch(requestAuthInit(false))
                            close()
                        } catch ({ message }) {
                            toast.error(message as string)
                            setBtnLoading(false)
                            form.setFieldsValue({ 'deviceID': "" })
                        } finally {
                            setBtnLoading(false)
                            form.setFieldsValue({ 'deviceID': "" })
                        }
                    } else {
                        setBtnLoading(false)
                        form.setFieldsValue({ 'deviceID': "" })
                    }
                })
            }
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                if (values.typeCard === "passport") {
                    values.identification = values.countryCode + values.identification
                    delete values.typeCard
                    delete values.countryCode
                }
                scbOTP(values)
            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpen && data) {
            form.resetFields();
            form.setFieldsValue(data)
            form.setFieldsValue({ "typeCard": "idCard" })
        }
    }, [form, isOpen, data]);

    const optionCountryCode = countryCode.map((item: any) => {
        return <Option key={item.countryCode} value={item.countryCode}>{item.countryDescTh}</Option>
    })

    const keyUpPassport = (value: string) => {
        form.setFieldsValue({ "identification": value.toUpperCase() })
    }

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="ScbAPI"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="ประเภทบัตร"
                                name="typeCard"
                                rules={[{ required: true, message: 'กรุณาเลือกประเภทบัตร' }]}>
                                <Radio.Group buttonStyle="solid">
                                    <Radio.Button value="idCard" onClick={() => setType('idCard')}>บัตรประชาชน</Radio.Button>
                                    <Radio.Button value="passport" onClick={() => setType('passport')}>พาสปอร์ต</Radio.Button>
                                    <Radio.Button value="alienCard" onClick={() => setType('alienCard')}>บัตรต่างด้าว</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            {type === "idCard" &&
                                <Form.Item
                                    name="identification"
                                    label="เลขบัตรประชาชน"
                                    rules={[{ required: true, message: 'กรุณากรอกเลขบัตรประชาชน' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                    hasFeedback>
                                    <Input placeholder="เลขบัตรประชาชน" showCount maxLength={13} inputMode='numeric' />
                                </Form.Item>
                            }
                            {type === "passport" &&
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item
                                            name="countryCode"
                                            label="ประเทศ"
                                            rules={[{ required: true, message: 'กรุณาเลือกประเทศ' }]}
                                            hasFeedback>
                                            <Select placeholder="เลือกประเทศ"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input: any, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {optionCountryCode}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item
                                            name="identification"
                                            label="เลขที่หนังสือเดินทาง"
                                            rules={[{ required: true, message: 'กรุณากรอกเลขที่หนังสือเดินทาง' }]}
                                            hasFeedback>
                                            <Input placeholder="เลขที่หนังสือเดินทาง" onKeyUp={(e: any) => keyUpPassport(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            {type === "alienCard" &&
                                <Form.Item
                                    name="identification"
                                    label="เลขบัตรต่างด้าว"
                                    rules={[{ required: true, message: 'กรุณากรอกเลขบัตรต่างด้าว' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                    hasFeedback>
                                    <Input placeholder="เลขบัตรต่างด้าว" showCount maxLength={13} inputMode='numeric' />
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="accountNumber"
                                label="เลขที่บัญชี"
                                rules={[{ required: true, message: 'กรุณากรอกเลขที่บัญชี' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <Input placeholder="เลขที่บัญชี" inputMode='numeric' disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="birthDate"
                                label="วัน/เดือน/ปีเกิด(ค.ศ.)"
                                rules={[{ required: true, message: 'กรุณากรอกวัน/เดือน/ปีเกิด(ค.ศ.)' }]}
                                hasFeedback>
                                <MaskedInput mask={'00/00/0000'} placeholder="วัน/เดือน/ปีเกิด(ค.ศ.)" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="phone"
                                label="เบอร์โทรศัพท์"
                                rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <Input placeholder="เบอร์โทรศัพท์" showCount maxLength={10} inputMode='numeric' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="pin"
                                label="PIN"
                                rules={[{ required: true, message: 'กรุณากรอกPIN' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <Input placeholder="PIN" showCount maxLength={6} inputMode='numeric' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                            <Button onClick={close} size="large">ยกเลิก</Button>
                        </Space>
                    </div>
                </Form>

            </Drawer>
        </>
    )
}
export default ScbAPI

