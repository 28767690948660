import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import logo from '../../assets/img/logo-light.png'
import { useDispatch } from 'react-redux'
import { useNavigate, Navigate } from 'react-router-dom'
import { AnyAction } from 'redux'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { toast } from 'react-toastify'
import { requestAuthInit } from '../../store/auth/auth.actions'
import { LoginParam } from '../../@types/auth'
import { ThunkDispatch } from '../../@types/store'
import AuthService from '../../services/auth'
import PasswordInput from '../../components/Elements/PasswordInput'
import { menuItems } from '../../utils/RouteConfig'



const Login = () => {

    const navigate = useNavigate();
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [prefix, setPrefix] = useState<string>('');
    const [otpCode, setOtpCode] = useState<string>('');
    const [showOTP, setShowOTP] = useState<boolean>(false);

    const { executeRecaptcha } = useGoogleReCaptcha();
    const [tokenGoogle, setTokenGoogle] = useState<string>("")
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (tokenGoogle) {
            // toast.error("Execute recaptcha not yet available, Please reload page.")
            // return;
            localStorage.setItem("googleRecaptcha", tokenGoogle)
        }
        try {
            await AuthService.login({
                username: username,
                password: password,
                prefix: prefix,
                otpCode: otpCode
            } as LoginParam)
            dispatch(requestAuthInit(true))
            navigate('/main')
        } catch (error: any) {
            if (error.error === 'otp_required') {
                setShowOTP(true)
                toast.info("กรุณากรอก OTP Code เพื่อเข้าสู่ระบบ")
            } else {
                toast.error(error ? error.message as string : "ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ในขณะนี้")
            }
        }
    }

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('Login');
        setTokenGoogle(token)
    }, [executeRecaptcha]);

    useEffect(() => {
        const x_prefix = localStorage.getItem('x-prefix') || ""
        setPrefix(x_prefix)
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);


    const token = localStorage.getItem('x-auth-token')
    if (token) {
        return <Navigate to={menuItems[0]?.path} replace />
    }
    return (
        <Wrapper>
            <SignIn>
                <img className="mb-5 d-block mx-auto" src={logo} alt="logo msn-bet" width="180" />
                <form onSubmit={handleSubmit}>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="floatingUsername" placeholder="Username" onChange={e => setUsername(e.target.value)} value={username} required />
                        <label>ชื่อผู้ใช้งาน</label>
                    </div>
                    <PasswordInput
                        password={password}
                        handleChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="floatingPrefix" placeholder="Prefix" onChange={e => setPrefix((e.target.value).toLocaleUpperCase())} value={prefix} required />
                        <label>Prefix</label>
                    </div>
                    {showOTP &&
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingOtpCode" placeholder="OTP Code" onChange={e => setOtpCode(e.target.value)} value={otpCode} required maxLength={6} />
                            <label>OTP Code</label>
                        </div>}
                    <button className="w-100 btn btn-lg btn-primary" type="submit">เข้าสู่ระบบ</button>
                    <p className="mt-4 mb-0 text-center text-muted"><small>Copyright © 2022 <a href="/">MSN.BET</a>. All rights reserved.</small></p>
                </form>
            </SignIn>
        </Wrapper>
    )
}

const LoginWithReCaptcha = () => {
    const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY : "6LdbZgYgAAAAAClNd74xEIrTgF-m4fbzkksg-sxQ"
    return (
        <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY} language="th">
            <Login />
        </GoogleReCaptchaProvider>
    );
};

export default LoginWithReCaptcha

const Wrapper = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #F8F9FD;
`;

const SignIn = styled.main`
	width: 100%;
	max-width: 400px;

	form {
		padding: 24px;
		background: #fff;
		box-shadow: 0 12px 24px 0 rgba(128,144,208,0.08);
		border-radius: 16px;
	}
`;