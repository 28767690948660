import React, { FC } from 'react'
import PrefixService from '../../services/prefix'

interface loadingProps {
    className?: string
}

const NamePrefix: FC<loadingProps> = ({ className }) => {
    const namePrefix = PrefixService.getPrefix()

    return (
        <a href="/" className={'name-prefix ' + className}>
            <div className="firework">
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
            </div>
            <div className="name-prefix-shape"></div>
            <div className="name-prefix-shape"></div>
            <div className="name-prefix-text">
                {namePrefix.toLocaleUpperCase()}
            </div>
        </a>
    );
}

export default NamePrefix