import React, { FC } from 'react'
import BadgeComponent from '../../components/Elements/Badge'

interface StatusBadgeProps {
    status: string
}

const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
    switch (status) {
        case 'pending': {
            return <BadgeComponent className="badge-warning" text='รอดำเนินการ' />
        }
        case 'queueing': {
            return <BadgeComponent className="badge-warning" text='กำลังดำเนินการ' />
        }
        case 'checking': {
            return <BadgeComponent className="badge-warning" text='รอตรวจสอบ' />
        }
        case 'success': {
            return <BadgeComponent className="badge-success" text='รายการสำเร็จ' />
        }
        case 'reject': {
            return <BadgeComponent className="badge-danger" text='รายการไม่สำเร็จ' />
        }
        case 'member_notfound': {
            return <BadgeComponent className="badge-danger" text='ไม่พบสมาชิก' />
        }
        case 'duplicate': {
            return <BadgeComponent className="badge-danger" text='รายการซ้ำ' />
        }
        case 'bank_duplicate': {
            return <BadgeComponent className="badge-danger" text='รายการฝากผิดพลาด' />
        }
        default: {
            return <BadgeComponent className="badge-warning" text={status && status.toUpperCase()} />
        }
    }
}

export default StatusBadge