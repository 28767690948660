import React, { FC, useCallback, useEffect, useState } from 'react'
import {
    Drawer,
} from 'antd';
import DatePickerRangeShowtime from '../../Elements/DatePickerRangeShowtime';
import DateFilterShowtime from '../../Elements/DateFilterShowtime'
import TableComponent from '../../Elements/Table'
import useDateRangeShowtime from "../../../hooks/useDateRangeShowtime"
import { useAPIRequest } from '../../../hooks/useAPIRequest';
// import NumberWithCommas from '../../../utils/NumberWithCommas';
import PrefixService from '../../../services/prefix'
import Amount from '../../Elements/Amount';

interface BetflixProps {
    member: any
    close: () => void
    isOpen: boolean
}

const Betflix: FC<BetflixProps> = ({ close, isOpen, member }) => {
    const title = 'ประวัติการการเล่น'
    const { dateRange, setDateRange } = useDateRangeShowtime()
    const [dataSource, setDataSource] = useState<any>([])

    const { data, loading, update } = useAPIRequest(
        useCallback(
            async () =>
                PrefixService.getMemberReportBetflix({
                    id: member.id,
                    dateRange: dateRange,
                }),
            [member.id, dateRange]
        )
    )

    const columns = [
        {
            title: 'Game',
            dataIndex: 'game',
            render: (text: any, record: any) => (
                <div>{record.game || "-"}</div>
            )
        },
        {
            title: 'Turnover',
            dataIndex: 'turnover',
            render: (text: any, record: any) => (
                <Amount amount={Number(record.turnover)} decimal={true} />
            )
        },
        {
            title: 'Valid amount',
            dataIndex: 'valid_amount',
            render: (text: any, record: any) => (
                <Amount amount={Number(record.valid_amount)} decimal={true} />
            )
        },
        {
            title: 'Win/Loss',
            dataIndex: 'winloss',
            render: (text: any, record: any) => (
                <Amount amount={Number(record.winloss)} decimal={true} />
            )
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            render: (text: any, record: any) => (
                <Amount amount={Number(record.commission)} decimal={true} />
            )
        },
        {
            title: 'Total',
            dataIndex: 'total',
            render: (text: any, record: any) => (
                <Amount amount={Number(record.total)} decimal={true} />
            )
        }
    ]

    useEffect(() => {
        if (isOpen && data) {
            setDataSource(data.data)
        }
    }, [isOpen, data, loading, setDataSource])

    useEffect(() => {
        update()
    }, [dateRange])// eslint-disable-line


    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .8}>
                <div className="col-12 col-md-auto mb-3 mb-md-0 mb-md-0">
                    <h2 className="my-auto me-3 mb-2">{(member && Object.keys(member).length > 0) ? `${member.firstName} ${member.lastName}` : ''} </h2>
                    <p className="my-auto me-3">{(member && Object.keys(member).length > 0) ? `เบอร์โทรศัพท์ : ${member.phone}` : ''} </p>
                </div>
                <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4 mt-4">
                    <DatePickerRangeShowtime onChangeDate={setDateRange} onDateActive={dateRange} />
                    <DateFilterShowtime onChangeDate={setDateRange} onDateActive={dateRange} />
                </div>
                <div className="box-white ant-box-table">
                    <TableComponent
                        columns={columns}
                        dataSource={dataSource || []}
                        loading={loading}
                        customOffsetHeader={-24}
                    />
                </div>
            </Drawer>
        </>
    )
}
export default Betflix