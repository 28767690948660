import React, { Fragment, useEffect, useState, useMemo } from 'react' //useState,
import { Select, Space } from 'antd'
import moment from 'moment'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import TitleBar from '../../../components/Elements/TitleBar'
import DatePickerRange from '../../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../../components/Elements/DateFilterTabs'
import StatusBadge from '../../../components/Elements/StatusBadge'
import TableComponent from '../../../components/Elements/TablePagination'
import AvatarBankComponent from '../../../components/Elements/AvatarBank'
import AvatarMemberComponent from '../../../components/Elements/AvatarMember'
import SearchData from '../../../components/Elements/Search'
import ModalBankDuplicate from '../../../components/ModalBankDuplicate'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import { statusTransactionDeposit } from '../../../utils/StatusTransaction'
import useDateRange from "../../../hooks/useDateRange"
import useFetchTransactionList from '../../../hooks/useFetchTransactionList'
import { DepositTransaction } from '../../../@types/transactions'
import PrefixService from '../../../services/prefix'
import useDatePagination from '../../../hooks/useDatePagination'

const TransactionDeposit = () => {
    const { Option } = Select;

    const { dateRange, setDateRange } = useDateRange()
    const [dataSource, setDataSource] = useState<any>([])
    const [filterText, setFilterText] = useState('')
    const [searchAlldata, setSearchAllData] = useState<string>("")
    const [status, setStatus] = useState('all')
    const [isDuplicateVisible, setIsDuplicateVisible] = useState<boolean>(false);
    const toggleModal = () => setIsDuplicateVisible(!isDuplicateVisible)
    // const [dataDupicate, setDataDupicate] = useState<any>([])
    const [dataDupicateID, setDataDupicateID] = useState<number>(0)
    const [dataDupicateNote, setDataDupicateNote] = useState<string>("")
    const { pagination, setPagination } = useDatePagination()

    const { data, mutate, isLoading } = useFetchTransactionList<DepositTransaction>(`/deposit`, {
        dateRange: dateRange,
        type: 'deposit',
        status: status !== 'all' ? status : '',
        search: searchAlldata
    }, 10000, { page: pagination.page, limit: pagination.pageSize })


    const handleCheckTx = (txID: number) => {
        if (txID) {
            Swal.fire({
                title: `ยืนยันรายการ #${txID}`,
                text: `ตรวจสอบให้แน่ใจว่าคุณต้องการยืนยันรายการ #${txID}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ตกลง'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const res = await PrefixService.checkTxDeposit(txID)
                        toast.success(res.message)
                        mutate()
                    } catch ({ message }) {
                        toast.error(message as string)
                    }
                }
            })
        }
    }

    const handleConfirmTx = (txID: number) => {
        if (txID) {
            Swal.fire({
                title: `ยืนยันรายการ #${txID}`,
                text: `ตรวจสอบให้แน่ใจว่าคุณต้องการยืนยันรายการ #${txID}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ตกลง'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const res = await PrefixService.confirmTxDeposit(txID)
                        toast.success(res.message)
                        mutate()
                    } catch ({ message }) {
                        toast.error(message as string)
                    }
                }
            })
        }
    }

    // const handleDupicateBankTx = async (txID: number) => {
    //     if (txID) {
    //         try {
    //             const res = await PrefixService.duplicateBankTxDeposit(txID)
    //             setDataDupicateID(txID)
    //             setDataDupicate(res)
    //             setIsDuplicateVisible(true)
    //         } catch ({ message }) {
    //             toast.error(message as string)
    //         }
    //     }
    // }

    const handleCancelTx = (txID: number) => {
        if (txID) {
            Swal.fire({
                title: `คุณต้องการยกเลิกรายการ #${txID}?`,
                text: "ตรวจสอบให้แน่ใจว่าคุณต้องการยกเลิกรายการใช่หรือไม่",
                input: 'text',
                inputLabel: 'หมายเหตุ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ตกลง'
            }).then(async (result) => {
                if (result.isConfirmed && result.value) {
                    try {
                        const res = await PrefixService.cancelTxDeposit(txID, result.value)
                        toast.success(res.message)
                        mutate()
                    } catch ({ message }) {
                        toast.error(message as string)
                    }
                }
            })
        }
    }

    const columns: any = [
        {
            title: 'เวลา',
            dataIndex: 'transferTime',
            width: '120px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.transferTime).format('DD/MM/YYYY HH:mm:ss')}</span></div>
                    <div>ดึงรายการ : {moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}</div>
                    {record.status === "success" && <div>อัพเดทยอด : {moment(record.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</div>}
                </div>
            )
        },
        {
            title: 'สมาชิก',
            dataIndex: 'name',
            render: (text: any, record: any) => (
                <>
                    {record.status !== "bank_duplicate" ? (
                        <AvatarMemberComponent
                            fullname={`${record.member.firstName} ${record.member.lastName}`}
                            phone={record.member.phone}
                            search={true}
                        />)
                        : (<AvatarMemberComponent
                            fullname={`Unknown`}
                            phone={''}
                            search={false}
                        />)}
                </>
            )
        },
        {
            title: 'จาก',
            dataIndex: 'bank',
            render: (text: any, record: any) => (
                <>
                    <AvatarBankComponent
                        bankCode={record.transferFrom}
                        bankNumber={
                            record.transferFrom === 'TRUEWALLET'
                                ? record.member.phone
                                : record.member.bankNumber
                        }
                        text={record.transferFrom}
                    />
                    {record.remark && <div className="row-remark mt-2">{record.remark}</div>}
                    {record.operator && <div className="msn-badge badge-light mt-2 text-danger">ทำรายการโดย : <b>{record.operator}</b></div>}
                </>
            )
        },
        {
            title: 'จำนวนเงิน',
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.amount, 2)}
                </div>
            )
        },
        {
            title: 'สถานะ',
            dataIndex: 'status',
            width: '90px',
            render: (text: any, record: any) => (
                <StatusBadge status={record.status} />
            )
        },
        {
            title: 'จัดการ',
            dataIndex: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space size={0}>
                        {record.status === "checking" && <button className="btn btn-warning btn-sm text-white me-2" onClick={() => handleCheckTx(record.id)}>ตรวจสอบ</button>}
                        {record.status === "pending" && <button className="btn btn-success btn-sm text-white me-2" onClick={() => handleConfirmTx(record.id)}>ยืนยัน</button>}
                        {(record.status === "pending" || record.status === "checking") && <button className="btn btn-danger btn-sm text-white me-2" onClick={() => handleCancelTx(record.id)}>ยกเลิก</button>}
                        {record.status === "bank_duplicate" && (
                            <>
                                <button className="btn btn-info btn-sm text-white me-2" onClick={() => {
                                    toggleModal()
                                    setDataDupicateID(record.id)
                                    setDataDupicateNote(record.remark)
                                }}>ทำรายการ</button>
                                <button className="btn btn-danger btn-sm text-white me-2" onClick={() => handleCancelTx(record.id)}>ยกเลิก</button>
                            </>
                        )}
                    </Space>
                </>
            )
        }
    ]

    const listStatusTransaction = Object.keys(statusTransactionDeposit).map((key: any) => {
        return <Option key={key} value={key}>{statusTransactionDeposit[key]}</Option>
    })

    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data?.transactions.length > 0) {
            // const filteredItems = (data?.transactions).filter(
            //     (item: any) => (
            //         (item.member.firstName && item.member.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.member.lastName && item.member.lastName.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.member.phone && item.member.phone.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.member.bankNumber && item.member.bankNumber.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.transferFrom && item.transferFrom.toLowerCase().includes(filterText.toLowerCase()))
            //     ),
            // );
            setDataSource(data?.transactions)
        }
    }, [data, isLoading, setDataSource])

    useMemo(() => {
        setFilterText("")
        setSearchAllData("")
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    const handleSearch = () => {
        setSearchAllData(filterText)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        mutate()
    }

    // useMemo(() => {

    // }, [setDataDupicate])// eslint-disable-line

    return (
        <Fragment>
            <TitleBar title="รายการฝาก" subTitle={`${NumberWithCommas(data?.pagination.Count || 0)} รายการ`} />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <Space>
                    <SearchData onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                    <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">ค้นหา</button>
                </Space>
                <div className="ms-auto mt-3 mt-md-0 mt-xl-0">
                    <label className="me-2">สถานะ : </label>
                    <Select
                        size="large"
                        dropdownMatchSelectWidth={false}
                        placement="bottomRight"
                        value={status}
                        onSelect={(value: any) => {
                            setStatus(value)
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {listStatusTransaction}
                    </Select>
                </div>
            </div>

            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.pagination.Count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>

            <ModalBankDuplicate
                isOpen={isDuplicateVisible}
                close={() => toggleModal()}
                onloadData={mutate}
                // dataDupicate={dataDupicate}
                dataDupicateID={dataDupicateID}
                dataDupicateNote={dataDupicateNote}
            />
        </Fragment>
    )
}

export default TransactionDeposit