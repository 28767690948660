import React, { FC, useEffect, useRef } from 'react'
import imageBG from './../../assets/img/scb-slip/bg.jpg'
import imageFillWhite from './../../assets/img/scb-slip/fill.png'
import imageCheck from './../../assets/img/scb-slip/check.png'
import imageBankFrom from './../../assets/img/scb-slip/bank/scb.png'
import { getBankIconSlip } from '../../utils/bank'
import NumberWithCommas from '../../utils/NumberWithCommas'
import moment from 'moment'
import 'moment/locale/th'  // without this line it didn't work
import { Button } from 'antd'
import html2canvas from "html2canvas"
import { DownloadOutlined } from '@ant-design/icons'
moment.locale('th')

interface SCBSlipProps {
    data?: any
}

const SCBSlip: FC<SCBSlipProps> = ({ data }) => {

    const replaceBankAccount = (num: any) => {
        num = num.replace(/[^\d]/g, '')
        const pattern = /^([0-9]{3})([0-9]{6})([0-9])$/;
        while (pattern.test(num))
            num = num.replace(pattern, '$1-$2-$3');
        return num;
    }

    var canvas, ctx: any
    // const canvasRef = useRef(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const canvasDownload: any = React.createRef();

    const init = () => {
        if (canvasRef.current) {
            // will be type HTMLDivElement NOT HTMLDivElement | null
            canvas = canvasRef.current
            ctx = canvas.getContext('2d')
            draw()
        }
    }

    function draw() {
        const loadImages = async (source: any, callback: (e: any) => void) => {
            var images: any = {};
            var loadedImages = 0;
            var numImages = Object.values(source).length;

            Object.keys(source).forEach((src: any) => {
                images[src] = new Image();
                images[src].crossOrigin = 'anonymous'
                images[src].onload = function () {
                    loadedImages++;
                    if (loadedImages >= numImages) {
                        callback(images);
                    }
                }
                images[src].src = sources[src];
            })
        }

        var sources: any = {
            bg: imageBG,
            fillWhite: imageFillWhite,
            check: imageCheck,
            bankFrom: imageBankFrom,
            bankTo: getBankIconSlip(data?.accountToBankCode),
            qrCode: `https://chart.apis.google.com/chart?cht=qr&chs=256x256&chl=${data?.qrString}`,
        }

        loadImages(sources, function callback(images: any) {

            const FONT_NAME = 'Slip Li';
            const FONT_NAME_TWO = 'Slip Reg';
            const FONT_NAME_THREE = 'Slip Med';
            // const FONT_NAME_FOUR = 'SlipSerif';

            // Value
            var amount = NumberWithCommas(data?.amount, 2);

            var refCode = data?.transactionID;

            var accountFrom = {
                name: data?.accountFromName,
                number: replaceBankAccount(data?.accountFrom)
            }

            var accountTo = {
                name: data?.accountToName,
                number: replaceBankAccount(data?.accountTo)
            }

            var dateTrans = {
                day: moment(data?.transactionDateTime).format("DD"),
                month: moment(data?.transactionDateTime).format("MMM"),
                year: moment(data?.transactionDateTime).add(543, "year").format("YYYY"),
                time: moment(data?.transactionDateTime).format("HH:mm"),
            }

            // Generate Bg
            ctx.drawImage(images.bg, 0, 0);
            ctx.drawImage(images.check, 313, 306);
            ctx.drawImage(images.qrCode, 760, 1150, 200, 200);

            // Generate Text
            function renderText() {

                // date
                ctx.font = `51px "${FONT_NAME}"`;
                ctx.fillStyle = '#77767b';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText(dateTrans.day, 336, 412);

                // month
                ctx.font = `51px "${FONT_NAME}"`;
                ctx.fillStyle = '#77767b';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText(dateTrans.month, 386, 412);

                // year
                ctx.font = `51px "${FONT_NAME}"`;
                ctx.fillStyle = '#77767b';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText(dateTrans.year, 463, 412);

                // dash
                ctx.font = `45px "${FONT_NAME}"`;
                ctx.fillStyle = '#77767b';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText('-', 556, 411);

                // time
                ctx.font = `51px "${FONT_NAME}"`;
                ctx.fillStyle = '#77767b';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText(dateTrans.time, 583, 412);

                // ref code
                ctx.font = `53.3px "${FONT_NAME}"`;
                ctx.fillStyle = '#77767b';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText('รหัสอ้างอิง', 166, 481);

                // ref code
                ctx.font = `52.5px "${FONT_NAME}"`;
                ctx.fillStyle = '#77767b';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText(refCode, 344, 481);

                // name from
                ctx.font = `63.25px "${FONT_NAME}"`;
                ctx.fillStyle = '#000';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "end";
                ctx.fillText(accountFrom.name, 948, 637);

                // icon bank from
                ctx.drawImage(images.bankFrom, 868 - ctx.measureText(accountFrom.name).width, 607);

                // account number from
                ctx.font = `52.4px "${FONT_NAME}"`;
                ctx.fillStyle = '#77767b';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "end";
                ctx.fillText(accountFrom.number, 948, 708);

                // name to
                ctx.font = `65px "${FONT_NAME}"`;
                ctx.fillStyle = '#000';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "end";
                ctx.fillText(accountTo.name, 948, 822);

                // icon bank to
                ctx.drawImage(images.bankTo, 871 - ctx.measureText(accountTo.name).width, 788);

                // account number to
                ctx.font = `52.4px "${FONT_NAME}"`;
                ctx.fillStyle = '#77767b';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "end";
                ctx.fillText(accountTo.number, 948, 893);

                // amount
                ctx.font = `62.8px "${FONT_NAME}"`;
                ctx.fillStyle = '#000';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "end";
                ctx.fillText(amount, 948, 1046);

                // text description 1
                ctx.font = `59.05px "${FONT_NAME}"`;
                ctx.fillStyle = '#000';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText('ผู้รับเงินสามารถสแกนคิวอาร์โค้ดนี้เพื่อ', 59, 1214);

                ctx.font = `59.05px "${FONT_NAME}"`;
                ctx.fillStyle = '#000';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText('ตรวจสอบสถานะการโอนเงิน', 59, 1284);
            }

            function renderTextTwo() {
                ctx.font = `55px "${FONT_NAME_TWO}"`;
                ctx.fillStyle = '#000000';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText("จาก", 59, 630);

                ctx.font = `55px "${FONT_NAME_TWO}"`;
                ctx.fillStyle = '#000';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText("ไปยัง", 59, 815);

                ctx.font = `56.25px "${FONT_NAME_TWO}"`;
                ctx.fillStyle = '#000';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText("จำนวนเงิน", 59, 1045);

            }

            function renderTextThree() {
                ctx.font = `64px "${FONT_NAME_THREE}"`;
                ctx.fillStyle = '#16b968';
                ctx.textBaseline = 'middle';
                ctx.textAlign = "start";
                ctx.fillText('โอนเงินสำเร็จ', 385, 334);
            }

            document.fonts.load('0pt "Slip Li"').then(renderText);
            document.fonts.load('0pt "Slip Reg"').then(renderTextTwo);
            document.fonts.load('0pt "Slip Med"').then(renderTextThree);

            // Generate Line
            ctx.beginPath();
            ctx.moveTo(20, 555);
            ctx.lineTo(987, 555);
            ctx.strokeStyle = "#dfdfe1";
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(20, 966);
            ctx.lineTo(987, 966);
            ctx.strokeStyle = "#dfdfe1";
            ctx.stroke();
        })

    }

    const exportAsImage = async (element: any, imageFileName: any) => {
        const html = document.getElementsByTagName("html")[0];
        const body = document.getElementsByTagName("body")[0];
        let htmlWidth = html.clientWidth;
        let bodyWidth = body.clientWidth;
        const newWidth = element.scrollWidth - element.clientWidth;
        if (newWidth > element.clientWidth) {
            htmlWidth += newWidth;
            bodyWidth += newWidth;
        }
        html.style.width = htmlWidth + "px";
        body.style.width = bodyWidth + "px";
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, imageFileName);
        html.removeAttribute('style');
        body.removeAttribute('style');
    };

    const downloadImage = (blob: any, fileName: any) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style.display = "none";
        fakeLink.download = fileName;
        fakeLink.href = blob;
        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
        fakeLink.remove();
    };

    useEffect(() => {
        init()
    }, [])// eslint-disable-line

    return (
        <div ref={canvasDownload}>
            <canvas ref={canvasRef} id="canvasImage" style={{ display: "block", width: "100%" }} width="1007" height="1634" />
            <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => exportAsImage(canvasRef.current, `slip-${data?.transactionID}`)}
                size={'large'}
                style={{ margin: "16px auto 0", display: "block" }}>
                Download
            </Button>
        </div>
    )
}
export default SCBSlip
