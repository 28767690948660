import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { toast } from 'react-toastify'
import { ApplicationState } from '../../../store'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import TitleBar from '../../../components/Elements/TitleBar'
import { Form, Input, InputNumber, Row, Col, Checkbox, Divider, Space, Button, Alert } from 'antd'
import PatternNumber from '../../../utils/PatternNumber'
import { AffiliateConfig } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import PrefixService from "../../../services/prefix"

const PromotionAffiliate = () => {
    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [showTurn, setShowTurn] = useState<boolean>(false)
    const [form] = Form.useForm();

    const updateData = async (values: AffiliateConfig) => {
        try {
            values.withdrawToBank = prefix?.setting?.affiliate?.withdrawToBank
            const res = await PrefixService.saveAffiliateConfig(values)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: AffiliateConfig) => {
                setBtnLoading(true)
                updateData(values);
            })
            .catch((errorInfo) => {

            });
    };


    useEffect(() => {
        if (form) {
            form.resetFields();
            form.setFieldsValue(prefix?.setting?.affiliate)
            setShowTurn(prefix?.setting?.affiliate?.withdrawToGame || false)
        }
    }, [form])// eslint-disable-line

    return (
        <Fragment>
            <TitleBar title="จัดการแนะนำเพื่อน" />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="affiliateForm"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label="ยอดแนะนำเพื่อน"
                                rules={[
                                    { required: false, message: 'กรุณากรอกยอดแนะนำเพื่อน' },
                                ]}>
                                <Input placeholder="ยอดแนะนำเพื่อน (%)" inputMode="numeric" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="max"
                                label="ยอดแนะสูงสุด"
                                rules={[
                                    { required: false, message: 'กรุณากรอกยอดแนะสูงสุด' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}>
                                <InputNumber placeholder="ยอดแนะสูงสุด" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="min"
                                label="ยอดฝากขั้นต่ำ"
                                rules={[
                                    { required: false, message: 'กรุณากรอกยอดฝากขั้นต่ำ' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}>
                                <InputNumber placeholder="ยอดฝากขั้นต่ำ" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="checkbox-collapse">
                        <Form.Item
                            name="withdrawToGame"
                            key="withdrawToGame"
                            valuePropName="checked"
                            className="mb-0">
                            <Checkbox onClick={() => setShowTurn(!showTurn)}>
                                โยกยอดแนะนำเพื่อนเข้าเกม
                            </Checkbox>
                        </Form.Item>
                        {showTurn &&
                            <Form.Item
                                name="toGameTurn"
                                label="เทิร์น (เท่า)"
                                className="mt-2 mb-0"
                                rules={[
                                    { required: false, message: 'กรุณากรอกเทิร์น (เท่า)' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}>
                                <InputNumber placeholder="เทิร์น (เท่า)" inputMode="numeric" controls={false} />
                            </Form.Item>
                        }
                    </div>
                    <Alert message={'* ระบบจะบังคับผู้ถูกแนะนำรับ "โบนัสสมัครสมาชิกใหม่" โดยอัตโนมัติเมื่อเติมเงิน'} type="error" className="mt-3" />
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default PromotionAffiliate