import React, { Fragment, useEffect, useState, useMemo } from 'react'
import { Row, Col, message } from 'antd'
import TitleBar from '../../../components/Elements/TitleBar'
import DatePickerRange from '../../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../../components/Elements/DateFilterTabs'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import useDateRange from "../../../hooks/useDateRange"
import Chart from 'react-apexcharts'
import AmountCard from '../../../components/Elements/AmountCard'
// import { toast } from 'react-toastify';
import { OverviewSummary } from '../../../@types/report'
import useFetchReport from '../../../hooks/useFetchReport'
import { calculateNewProfit, depositAndbonus } from '../../../utils/summary'
import Loading from '../../../components/Elements/Loading'
import moment from 'moment'

const ReportSummary = () => {
    const { dateRange, setDateRange } = useDateRange()
    const [dataChart, setDataChart] = useState<any>([])
    const [dataSource, setDataSource] = useState<any>([])
    const [dataProfit, setDataProfit] = useState<number>(0)
    const [dataWithdrawTotal, setDataWithdrawTotal] = useState<any>([])

    const { data, mutate, isLoading } = useFetchReport<OverviewSummary>('overview', dateRange, 10000)

    const totalProfitChart = {
        options: {
            series: dataChart,
            labels: ['ยอดฝาก', 'ยอดฝาก + โบนัส', 'ยอดถอน (สำเร็จ)', 'ยอดถอน (ไม่สำเร็จ)'],
            colors: ['#17c5a2', '#5952c0', '#fd7e14', '#dc3545'],
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false
            },
            fill: {
                colors: ['#17c5a2', '#5952c0', '#fd7e14', '#dc3545']
            },
            tooltip: {
                y: {
                    formatter: function (val: any) {
                        return NumberWithCommas(val, 2)
                    },
                }
            }
        },
    };

    useEffect(() => {
        setDataChart([])
        setDataSource([])
        if (data !== undefined) {
            if (Object.keys(data).length > 0) {
                const dataSetChart: any = [
                    data.depositTotal,
                    depositAndbonus(data),
                    data.withdrawTotal.success,
                    data.withdrawTotal.reject
                ];
                setDataChart(dataSetChart)
                setDataProfit(calculateNewProfit(data))
                setDataWithdrawTotal(data.withdrawTotal)
                setDataSource(data)
            }
        }
    }, [data, setDataChart, setDataSource, setDataProfit, setDataWithdrawTotal]) // eslint-disable-line

    useMemo(() => {
        mutate()
    }, [setDateRange])// eslint-disable-line

    const profitTotal = NumberWithCommas(dataProfit, 2)
    const depositTotal = NumberWithCommas(dataSource.depositTotal, 2)
    const depositbonus = NumberWithCommas(depositAndbonus(dataSource), 2)
    const dataWithdrawTotalSuccess = NumberWithCommas(dataWithdrawTotal.success, 2)
    const dataWithdrawTotalReject = NumberWithCommas(dataWithdrawTotal.reject, 2)

    const memberCount = `${NumberWithCommas(dataSource.memberCount)} บัญชี`
    const memberFirstDepositCount = `${NumberWithCommas(dataSource.memberFirstDepositCount)} บัญชี`
    const promotionTotal = NumberWithCommas(dataSource.promotionTotal, 2)
    const affiliateTotal = NumberWithCommas(dataSource.affiliateTotal, 2)
    const cashbackTotal = NumberWithCommas(dataSource.cashbackTotal, 2)
    const wheelTotal = NumberWithCommas(dataSource.wheelTotal, 2)

    const copyData = () => {
        navigator.clipboard.writeText(`สรุปข้อมูล (${moment(dateRange.start).format('DD/MM/YYYY')} - ${moment(dateRange.end).format('DD/MM/YYYY')})
สรุปยอดฝาก : ${depositTotal} บาท
สรุปยอดถอน (สำเร็จ) : ${dataWithdrawTotalSuccess} บาท
สรุปยอดถอน (ยกเลิกรายการ) : ${dataWithdrawTotalReject} บาท
สรุปกำไร (ยอดฝาก - ยอดถอน) : ${profitTotal} บาท
สรุปยอดโบนัส : ${promotionTotal} บาท
สรุปยอดแนะนำเพื่อน : ${affiliateTotal} บาท
สรุปยอดแคชแบ็ก : ${cashbackTotal} บาท
สรุปยอดกงล้อเสี่ยงโชค : ${wheelTotal} บาท
เปิดยูสเซอร์ใหม่ : ${memberCount}
เปิดยูสเซอร์ใหม่ (ฝากครั้งแรก) : ${memberFirstDepositCount}`)
        // toast.success('คัดลอกช้อมูลสำเร็จ')
        message.success('คัดลอกข้อมูลสำเร็จ');
    }

    return (
        <Fragment>
            <TitleBar title="สรุปข้อมูล" />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            <Row gutter={16}>
                <Col sm={24} lg={12}>
                    <div className="box-white ant-box-table p-3 p-lg-4 mb-2 mb-lg-0">
                        <Row>
                            <Col xs={24} sm={12} lg={12}>
                                {isLoading ? <Loading /> :
                                    <>
                                        <div className="pie-chart-wrapper">
                                            <Chart
                                                type="donut"
                                                options={totalProfitChart.options as object}
                                                series={totalProfitChart.options.series}
                                            />
                                            <i className="fa-duotone fa-chart-line"></i>
                                        </div>
                                        <div className="drawer-actions">
                                            <p className="m-0">กำไรทั้งหมด</p>
                                            <h5 className="row-amount"><span>฿</span> {profitTotal}</h5>
                                            <button className='btn btn-primary btn-sm' onClick={copyData}>คัดลอกข้อมูล</button>
                                        </div>
                                    </>
                                }
                            </Col>
                            <Col xs={24} sm={12} lg={12} className="mt-3 mt-xl-0">
                                <h5>สรุปยอดทั้งหมด</h5>
                                <AmountCard
                                    icon='fa-solid fa-plus-circle'
                                    className='no-box'
                                    theme='success'
                                    amount={depositTotal}
                                    amountType='ยอดฝาก'
                                    loading={isLoading}
                                />
                                <AmountCard
                                    icon='fa-solid fa-plus-circle'
                                    className='no-box'
                                    theme='info'
                                    amount={depositbonus}
                                    amountType='ยอดฝาก + โบนัส'
                                    loading={isLoading}
                                />
                                <AmountCard
                                    icon='fa-solid fa-minus-circle'
                                    className='no-box'
                                    theme='warning'
                                    amount={dataWithdrawTotalSuccess}
                                    amountType='ยอดถอน (สำเร็จ)'
                                    loading={isLoading}
                                />
                                <AmountCard
                                    icon='fa-solid fa-minus-circle'
                                    className='no-box mb-0'
                                    theme='danger'
                                    amount={dataWithdrawTotalReject}
                                    amountType='ยอดถอน (ไมสำเร็จ)'
                                    loading={isLoading}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm={24} lg={12} className="mt-3 mt-xl-0">
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-user-plus'
                                theme='success'
                                amount=''
                                number={memberCount}
                                amountType='เปิดยูสเซอร์ใหม่'
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-user-check'
                                theme='primary'
                                amount=''
                                number={memberFirstDepositCount}
                                amountType='ยอดฝากครั้งแรก'
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-gift'
                                theme='third'
                                amount={promotionTotal}
                                amountType='ยอดรับโบนัส'
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-users'
                                theme='info'
                                amount={affiliateTotal}
                                amountType='ยอดแนะนำเพื่อน'
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-redo'
                                theme='warning'
                                amount={cashbackTotal}
                                amountType='ยอดรับแคชแบ็ก'
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-dharmachakra'
                                theme='danger'
                                amount={wheelTotal}
                                amountType='ยอดหมุนกงล้อ'
                                loading={isLoading}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    )
}

export default ReportSummary