import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { Form, InputNumber, Row, Col, Divider, Space, Button } from 'antd'
import { toast } from 'react-toastify'
import TitleBar from '../../../components/Elements/TitleBar'
import PatternNumber from '../../../utils/PatternNumber'
import PrefixService from "../../../services/prefix"
import { requestAuthInit } from '../../../store/auth/auth.actions'
import { NewRegBonusConfig } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'

const PromotionCredit = () => {

    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [form] = Form.useForm();
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    const updateData = async (values: NewRegBonusConfig) => {
        try {
            const res = await PrefixService.saveNewRegBonus(values)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = async () => {
        form.validateFields()
            .then((values: NewRegBonusConfig) => {
                setBtnLoading(true)
                values.amount = parseFloat(values.amount.toString())
                values.turn = parseFloat(values.turn.toString())
                values.maxWithdraw = parseFloat(values.maxWithdraw.toString())
                updateData(values)
            })
            .catch((errorInfo) => {

            });
    };

    const [dataNewMemberCredit, setDataNewMemberCredit] = useState<any>()
    const fetchNewRegBonus = async () => {
        try {
            const res = await PrefixService.getNewRegBonus()
            setDataNewMemberCredit(res)
        } catch ({ message }) {
            toast.error(message as string)
        }
    }

    useEffect(() => {
        fetchNewRegBonus()
    }, [])

    useEffect(() => {
        if (form && dataNewMemberCredit) {
            form.resetFields();
            form.setFieldsValue(dataNewMemberCredit)
        }
    }, [form, dataNewMemberCredit]);

    return (
        <Fragment>
            <TitleBar title="ตั้งค่าเครดิตฟรีสำหรับสมาชิกใหม่" />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="creditForm"
                    layout="vertical"
                    hideRequiredMark
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="amount"
                                label="จำนวน"
                                rules={[
                                    { required: true, message: 'กรุณากรอกจำนวน' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder="จำนวน" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="turn"
                                label="เทิร์น (เท่า)"
                                rules={[
                                    { required: true, message: 'กรุณากรอกเทิร์น (เท่า)' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder="เทิร์น (เท่า)" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="maxWithdraw"
                                label="ยอดถอนสูงสุด (เท่า)"
                                rules={[
                                    { required: true, message: 'กรุณากรอกยอดถอนสูงสุด (เท่า)' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder="ยอดถอนสูงสุด (เท่า)" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default PromotionCredit