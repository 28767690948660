import React, { Fragment, useEffect, useState } from 'react'
import TitleBar from '../../../components/Elements/TitleBar'
import { Form, Input, Row, Col, Select, Divider, Space, Button, InputNumber } from 'antd'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PatternNumber from '../../../utils/PatternNumber'
import { bankNames } from '../../../utils/bank'
import { CentralAccountConfig } from '../../../@types/prefix'
import { ApplicationState } from '../../../store'
import PrefixService from '../../../services/prefix'
import History from '../../../components/Banks/WithdrawCentral/History'

const WithdrawCentral = () => {

    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)

    const [historyDrawer, setHistoryDrawer] = useState<boolean>(false)
    const toggleHistory = () => setHistoryDrawer(!historyDrawer)
    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const { Option } = Select;
    const updateData = async (values: CentralAccountConfig) => {
        try {
            const res = await PrefixService.saveCentralAccount(values)
            setBtnLoading(false)
            toast.success(res.message)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: CentralAccountConfig) => {
                setBtnLoading(true)
                updateData(values)
            })
            .catch((errorInfo) => {

            });
    };

    const optionBankNames = Object.keys(bankNames).map((key: any) => {
        return <Option key={key} value={key}>{bankNames[key]}</Option>
    })

    useEffect(() => {
        if (form) {
            form.resetFields();
            form.setFieldsValue(prefix?.setting?.centralAccount)
        }
    }, [form])// eslint-disable-line 

    return (
        <Fragment>
            <TitleBar title="ตั้งค่าถอนเข้าบัญชีกลาง" />
            <div className="row mb-md-3 align-items-md-center">
                <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                    <button className="btn btn-primary ms-auto ms-md-0" onClick={toggleHistory}>
                        <i className="fa-solid fa-clock-rotate-left me-1"></i>
                        <span>ประวัติการโอนเงิน</span>
                    </button>
                </div>
            </div>
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="withdrawCentralForm"
                    layout="vertical"
                    hideRequiredMark
                    size={'large'}
                >
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="minAmount"
                                label="ยอดคงเหลือขั้นต่ำ"
                                rules={[
                                    { required: true, message: 'กรุณากรอกยอดคงเหลือขั้นต่ำ' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder="ยอดคงเหลือขั้นต่ำ" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="bankCode"
                                label="ธนาคาร"
                                rules={[{ required: true, message: 'กรุณาเลือกธนาคาร' }]}>
                                <Select
                                    placeholder="เลือกธนาคาร"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {optionBankNames}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="accountNumber"
                                label="เลขที่บัญชีกลาง"
                                rules={[
                                    { required: true, message: 'กรุณากรอกเลขที่บัญชีกลาง' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback>
                                <Input placeholder="เลขที่บัญชีกลาง" inputMode="numeric" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                        </Space>
                    </div>
                </Form>
            </div>

            <History
                isOpen={historyDrawer}
                close={() => toggleHistory()}
            />
        </Fragment>
    )
}

export default WithdrawCentral