import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../store'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import TitleBar from '../../../components/Elements/TitleBar'
import ListData from '../../../components/Promotions/Bonus/List'
import AddData from '../../../components/Promotions/Bonus/Add'
import EditData from '../../../components/Promotions/Bonus/Edit'

const PromotionBonus = () => {
    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const [loading, setLoading] = useState<boolean>(true)
    const [dataSource, setDataSource] = useState<any>([])

    const [addDrawer, setAddDrawer] = useState(false)
    const toggleAdd = () => setAddDrawer(!addDrawer)

    const [editDrawer, setEditDrawer] = useState(false)
    const toggleEdit = () => setEditDrawer(!editDrawer)
    const [bonusEdit, setBonusEdit] = useState<object>({})
    const [bonusKey, setBonusKey] = useState<string>("")
    const handleEdit = (key: string) => {
        setBonusEdit(prefix?.promotions[key])
        setBonusKey(key)
        toggleEdit()
    }

    useEffect(() => {
        if (Object.keys(prefix.promotions).length !== 0 || !prefix.promotions) {
            setLoading(false)
            setBonusEdit({})
            let sortable = Object.values(prefix.promotions).map((element: any, idx: any) => {
                const item: any = element
                const keyBonus = Object.keys(prefix.promotions)[idx];
                item.key = keyBonus
                return item
            });

            sortable.sort((a, b) => {
                if (!a.enable) {
                    return 1
                } else if (!b.enable) {
                    return -1
                } else {
                    if (a.order !== 0) {
                        if (b.order === 0) {
                            return -1
                        }
                        if (a.order < b.order) {
                            return -1
                        } else {
                            return 1
                        }
                    }
                }
                return 0
            })
            setDataSource(sortable)
        }
    }, [prefix.promotions]) // eslint-disable-line

    return (
        <Fragment>
            <TitleBar title="โบนัส" subTitle={`${NumberWithCommas(dataSource.length || 0)} รายการ`} />

            <div className="row mb-md-3 align-items-md-center">
                <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                    <button className="btn btn-primary ms-auto ms-md-0" onClick={() => toggleAdd()}>
                        <i className="fa-solid fa-plus me-3"></i>
                        <span>เพิ่มโบนัส</span>
                    </button>
                </div>
            </div>

            <ListData dataSource={dataSource} loading={loading} handleEdit={handleEdit} />

            <AddData
                isOpen={addDrawer}
                close={() => toggleAdd()}
            />
            <EditData
                isOpen={editDrawer}
                close={() => toggleEdit()}
                data={bonusEdit}
                bonusKey={bonusKey}
            />
        </Fragment>
    )
}

export default PromotionBonus