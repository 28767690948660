import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Modal,
    Input,
    InputNumber,
    Space,
    Divider,
    message
} from 'antd'
import { toast } from 'react-toastify'
import FileSaver from "file-saver"
import * as XLSX from 'xlsx'
import moment from 'moment'
import PrefixService from "../../../services/prefix"
import { RedeemAddConfig } from '../../../@types/prefix'
import PatternNumber from '../../../utils/PatternNumber'

interface AddCodeProps {
    close: () => void
    isOpen: boolean
    onloadData: () => void
}

const AddCode: FC<AddCodeProps> = ({ close, isOpen, onloadData }) => {
    const [form] = Form.useForm();
    const title = "เพิ่มโค้ดโปรโมชั่น"
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [isModalVisable, setIsModalVisable] = useState<boolean>(false);
    const [dataCodes, setDataCodes] = useState<any>([]);
    const [dataCodesExcel, setDataCodesExcel] = useState<any>([]);
    const insertData = async (values: RedeemAddConfig) => {
        try {
            const res = await PrefixService.addRedeem(values)
            setBtnLoading(false)
            onloadData()
            toast.success(res.message)
            close()
            let dataRes = ""
            if (res.codes.length > 0) {
                Object.values(res.codes).forEach((item: any) => {
                    dataRes += `${item.Code}\n`
                })
                const reData = Object.values(res.codes).map((item: any) => {
                    return {
                        Code: item.Code,
                        Amount: item.Amount,
                        Used: item.Used ? "ใช้งานแล้ว" : "ยังไม่ได้ใช้งาน",
                        MaxWithdraw: item.MaxWithdraw,
                        Turn: item.Turn,
                        CreatedAt: moment(item.CreatedAt).format("DD/MM/YYYY HH:mm:ss")
                    }
                })
                setDataCodesExcel(reData)
                copyData(dataRes)
                setDataCodes(dataRes)
                setIsModalVisable(true)
            }

        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const copyData = (text: any) => {
        navigator.clipboard.writeText(`${text}`)
        message.success('คัดลอกข้อมูลสำเร็จ')
    }

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const Heading = [
        {
            Code: "โค้ดโปรโมชั่น",
            Amount: "เครดิต",
            Used: "สถานะ",
            MaxWithdraw: "ยอดถอนสูงสุด (เท่า)",
            Turn: "เทิร์น (เท่า)",
            CreatedAt: "วันที่สร้าง"
        }
    ];

    const exportData = (dataExport: any) => {
        const ws = XLSX.utils.json_to_sheet(Heading, {
            header: ["Code", "Amount", "Used", "MaxWithdraw", "Turn", "CreatedAt"],
            skipHeader: true
        });
        // ws["!cols"] = [
        //     { wch: Math.max(...dataExport.map((item: any) => item.Code.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Amount.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Used.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.MaxWithdraw.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Turn.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.CreatedAt.length)) + 5 }
        // ];
        XLSX.utils.sheet_add_json(ws, dataExport, {
            header: ["Code", "Amount", "Used", "MaxWithdraw", "Turn", "CreatedAt"],
            skipHeader: true,
            origin: -1
        });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Export_Redeem" + fileExtension);
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: RedeemAddConfig) => {
                setBtnLoading(true)
                insertData(values)
            })
            .catch((errorInfo) => { });
    };


    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
            form.setFieldsValue({ "code": localStorage.getItem('x-prefix') + '' + Math.floor(Math.random() * 1000000000) })
        }
    }, [form, isOpen]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="addCode"
                    layout="vertical"
                    hideRequiredMark>
                    {/* <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="code"
                                label="โค้ดโปรโมชั่น"
                                rules={[{ required: true, message: 'กรุณากรอกโค้ดโปรโมชั่น' }]}
                                hasFeedback>
                                <Input placeholder="โค้ดโปรโมชั่น" />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="codeAmount"
                                label="จำนวนโค้ด"
                                rules={[
                                    { required: true, message: 'กรุณากรอกจำนวนโค้ด' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder="จำนวนโค้ด" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="amount"
                                label="จำนวนเครดิต"
                                rules={[
                                    { required: true, message: 'กรุณากรอกจำนวนเครดิต' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder="จำนวนเครดิต" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="maxWithdraw"
                                label="ยอดถอนสูงสุด (เท่า)"
                                rules={[
                                    { required: true, message: 'กรุณากรอกยอดถอนสูงสุด (เท่า)' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder="ยอดถอนสูงสุด (เท่า)" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="turn"
                                label="เทิร์น (เท่า)"
                                rules={[
                                    { required: true, message: 'กรุณากรอกเทิร์น (เท่า)' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder="เทิร์น (เท่า)" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                            <Button onClick={close} size="large">ยกเลิก</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
            <Modal
                title="โค้ดโปรโมชั่น"
                visible={isModalVisable}
                onCancel={() => setIsModalVisable(false)}
                footer={[
                    <Button key="excel" danger type="primary" onClick={() => exportData(dataCodesExcel)}  ><i className="fa-solid fa-arrow-down-to-line me-2"></i>Export Excel</Button>,
                    <Button key="submit" type="primary" onClick={() => copyData(dataCodes)} ><i className="fa-solid fa-copy me-2"></i>คัดลอก</Button>,
                ]}
            >
                <div className="d-flex align-items-center">
                    <Input.TextArea autoSize={{ minRows: 6 }} disabled={true} value={dataCodes} />
                </div>
            </Modal>
        </>
    )
}
export default AddCode

