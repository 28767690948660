import React, { FC } from 'react'
import BadgeComponent from '../../components/Elements/Badge'
import { FixTransaction } from '../../@types/transactions'

interface FixTxBadgeProps {
	tx: FixTransaction
}

const FixTxBadge: FC<FixTxBadgeProps> = ({ tx }) => {
    if (tx.type === 'deduct') return <BadgeComponent className="badge-danger" text='ตัดเครดิต' />
    if (tx.type === 'add') return <BadgeComponent className="badge-success" text='เติมเครดิต' />
    if (tx.type === 'deposit' && tx.transferFrom === 'TRUEWALLET') return <BadgeComponent className="badge-warning" text='ทรูมันนี่วอลเล็ทไม่รัน' />
    return <BadgeComponent className="badge-info" text='ธนาคารไม่รัน' />
}

export default FixTxBadge