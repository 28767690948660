import React, { FC, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from "../../contexts/ThemeContext";
import { ScreenContext } from "../../contexts/ScreenContext";
import { Menu, Dropdown, Switch } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import FormattedMessage from './FormattedMessage'
import { useThemeSwitcher } from "react-css-theme-switcher"
import { useTranslation } from 'react-i18next'
import { availableLanguages } from '../../utils/i18n'

interface ProfileButtonProps {
    className?: string
    username?: string
    prefix?: string
}

const ProfileButton: FC<ProfileButtonProps> = ({ className, username, prefix }) => {
    const { theme, toggleTheme } = useContext(ThemeContext)
    const { screen, toggleScreen } = useContext(ScreenContext)
    const [visible, setVisible] = useState(false)

    const onVisibleChange = (visible: boolean) => setVisible(visible)
    const { i18n } = useTranslation()
    const [current, setCurrent] = React.useState(i18n.language);
    const { switcher, themes } = useThemeSwitcher();

    const toggleTheme2 = (isChecked: boolean) => {
        switcher({ theme: isChecked ? themes.dark : themes.light });
    }


    const menu = (
        <Menu className="profile-link-hover" selectedKeys={[current]}>
            {/* <Menu.Item key="0">
                <Link to="/profile"><FormattedMessage id={'menu-profile'} defaultMessage={'ข้อมูลส่วนตัว'} /></Link>
            </Menu.Item> */}
            {/* <Menu.Item key="1">
                <Link to="/security"><FormattedMessage id={'menu-2fa'} defaultMessage={'ความปลอดภัย 2FA'} /></Link>
            </Menu.Item>
            <Menu.Item key="2">
                <Link to="#"><FormattedMessage id={'menu-reset-password'} defaultMessage={'เปลี่ยนรหัสผ่าน'} /></Link>
            </Menu.Item> */}
            <Menu.Item key="3" disabled>
                <Link to="#"><FormattedMessage id={'menu-user-manual'} defaultMessage={'คู่มือการใช้งาน'} /></Link>
                {/* <a href="https://kb.msn.bet" target="_blank" rel="noreferrer"><FormattedMessage id={'instruction-manual'} defaultMessage={'คู่มือการใช้งาน'} /></a> */}
            </Menu.Item>
            <Menu.SubMenu key="4" title={<FormattedMessage id={'menu-translation'} defaultMessage="ภาษา" />}>
                {Object.entries(availableLanguages).map((language: any) => (
                    <Menu.Item key={language[0]} onClick={() => { i18n.changeLanguage(language[0]); setCurrent(language[0]) }}>
                        {language[1]}
                    </Menu.Item>
                ))}
            </Menu.SubMenu>
            <Menu.Item key="5">
                <div className="d-flex">
                    <span><FormattedMessage id={'mode'} defaultMessage={'โหมด'} /></span>
                    <Switch
                        className='ms-auto'
                        checkedChildren={<i className="fa-solid fa-moon"></i>}
                        unCheckedChildren={<i className="fa fa-sun"></i>}
                        checked={theme === "dark" ? true : false}
                        onClick={(e) => {
                            toggleTheme(e)
                            toggleTheme2(e)
                        }}
                    />
                </div>
            </Menu.Item>
            <Menu.Item key="6">
                <div className="d-flex">
                    <span><FormattedMessage id={'full-screen'} defaultMessage={'เต็มจอ'} /></span>
                    <Switch
                        className='ms-auto'
                        checkedChildren={"เปิด"}
                        unCheckedChildren={"ปิด"}
                        defaultChecked={screen === "full" ? true : false}
                        onClick={(e) => {
                            toggleScreen(e)
                        }}
                    />
                </div>
            </Menu.Item>
            <Menu.Item key="7" className="text-danger">
                <Link to="/logout"><i className="fa-solid fa-sign-out"></i> <FormattedMessage id="logout" defaultMessage="ออกจากระบบ" /></Link>
            </Menu.Item>
        </Menu>
    );
    return (
        <div className={"profile-button " + className}>
            <Dropdown overlay={menu} trigger={["click"]} visible={visible} onVisibleChange={onVisibleChange}>
                <Profile className="ant-dropdown-link">
                    <div className="d-lg-none">
                        <UserOutlined className="profile-icon" style={{ fontSize: '20px', padding: '4px 8px' }} />
                    </div>
                    <div className="profile-wrapper d-none d-lg-flex">
                        <div className="profile-char">
                            <span>{username?.charAt(0)}</span>
                        </div>
                        <div className="profile-info">
                            <div className="profile-username">{username}</div>
                            <small className="profile-permission">{prefix?.toLocaleUpperCase()}</small>
                        </div>
                    </div>
                    <i className="fa-duotone fa-chevron-down profile-chevron-down"></i>
                </Profile>
            </Dropdown>
        </div>
    )
}

export default ProfileButton

const Profile = styled.div`
    display: flex;
    align-items: center;
    line-height: 1.25;
`