import React, { Fragment, useEffect, useState } from 'react'
import { Form, Input, Row, Col, Select, Divider, Space, Button, Alert, Switch, Tooltip, message } from 'antd'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import TitleBar from '../../../components/Elements/TitleBar'
import PatternNumber from '../../../utils/PatternNumber'
import { createSCBAccountSelector, } from '../../../utils/bank'
import { ApplicationState } from '../../../store'
import PrefixService from '../../../services/prefix'
import useFetchSetting from "../../../hooks/useFetchSetting"
import { TrueWalletCallbackConfig, TrueWalletQrCodeConfig, TrueWalletVoucherConfig } from '../../../@types/prefix'
import ListTruewallet from '../../../components/Banks/Truewallet/List'
import { CopyOutlined } from '@ant-design/icons'


const TrueWallet = () => {

    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();

    const [optionAccountNumber, setOptionAccountNumber] = useState<any>()
    const [btnLoadingQrCode, setBtnLoadingQrCode] = useState<boolean>(false)
    const [btnLoadingCallback, setBtnLoadingCallback] = useState<boolean>(false)
    const [btnLoadingVoucher, setBtnLoadingVoucher] = useState<boolean>(false)

    const [dataSecret, setDataSecret] = useState<string>()

    const [colSpan, setColSpan] = useState<number>(12)
    const { Option } = Select

    const { data: dataSetting, mutate } = useFetchSetting()

    // const { permissions } = useSelector((state: ApplicationState) => state.auth)
    const { features, prefix } = useSelector((state: ApplicationState) => state.prefix)
    const prefixWeb = PrefixService.getPrefix()

    const updateDataQrCode = async (values: TrueWalletQrCodeConfig) => {
        try {
            const res = await PrefixService.saveTrueWalletQrCode(values)
            setBtnLoadingQrCode(false)
            mutate()
            toast.success(res.message)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoadingQrCode(false)
        }

    }

    const updateDataVoucher = async (values: TrueWalletVoucherConfig) => {
        try {
            const res = await PrefixService.saveTrueWalletVoucher(values)
            setBtnLoadingVoucher(false)
            mutate()
            toast.success(res.message)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoadingVoucher(false)
        }
    }

    const updateDataCallback = async (values: TrueWalletCallbackConfig) => {
        try {
            const res = await PrefixService.saveTrueWalletCallback(values)
            setBtnLoadingCallback(false)
            mutate()
            toast.success(res.message)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoadingCallback(false)
        }
    }

    const handleFormSubmitQrCode = () => {
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: 'คุณต้องการบันทึกข้อมูลเติมเงินทรูมันนี่วอลเล็ทด้วย QR Code ใช่หรือไม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                form.validateFields()
                    .then((values: TrueWalletQrCodeConfig) => {
                        setBtnLoadingQrCode(true)
                        updateDataQrCode(values)
                    })
                    .catch((errorInfo) => {

                    });
            }
        })
    };

    const handleFormSubmitVoucher = () => {

        Swal.fire({
            title: 'แจ้งเตือน!',
            text: 'คุณต้องการบันทึกข้อมูลเติมเงินทรูมันนี่วอลเล็ทด้วย อังเปา ใช่หรือไม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                form2.validateFields()
                    .then((values: TrueWalletVoucherConfig) => {
                        setBtnLoadingVoucher(true)
                        updateDataVoucher(values)
                    })
                    .catch((errorInfo) => {

                    });
            }
        })
    }

    const handleFormSubmitCallback = () => {
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: 'คุณต้องการบันทึกข้อมูลเติมเงินทรูมันนี่วอลเล็ทด้วย Notification Callback ใช่หรือไม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                form3.validateFields()
                    .then((values: TrueWalletCallbackConfig) => {
                        setBtnLoadingCallback(true)
                        updateDataCallback(values)
                    })
                    .catch((errorInfo) => {

                    });
            }
        })
    }

    const resetForm = (type: Number) => {

        let textDes = 'คุณต้องการล้างข้อมูลเติมเงินทรูมันนี่วอลเล็ทด้วย QR Code ใช่หรือไม่'
        if (type === 2) {
            textDes = 'คุณต้องการล้างข้อมูลเติมเงินทรูมันนี่วอลเล็ทด้วย อังเปา ใช่หรือไม่'
        } else if (type === 3) {
            textDes = 'คุณต้องการล้างข้อมูลเติมเงินทรูมันนี่วอลเล็ทด้วย Notification Callback ใช่หรือไม่'
        }

        Swal.fire({
            title: 'แจ้งเตือน!',
            text: textDes,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (type === 1) {
                    form.resetFields();
                } else if (type === 2) {
                    form2.resetFields();
                } else if (type === 3) {
                    form3.setFieldsValue({
                        'status': '',
                        'accountName': '',
                        'phoneNumber': '',
                        'secret': '',
                    });
                }
            }
        })
    }

    const onValuesChange = (changedValues: any, allValues: any) => {
        if (Object.keys(changedValues)[0] === 'phoneNumber') {
            const secret = `https://api.msn.bet/v2/external/truewallet/webhook/${changedValues.phoneNumber}?prefix=${prefixWeb}`
            setDataSecret(secret)
        }
    }

    const handleCopy = (values: any) => {
        navigator.clipboard.writeText(values)
        message.success('คัดลอกข้อมูลสำเร็จ');
    }

    useEffect(() => {
        if (form) {
            const AccountNumber = createSCBAccountSelector(Object.values(prefix.banks))
            const optAccountNumber = AccountNumber && Object.keys(AccountNumber).map((key: any) => {
                return <Option key={AccountNumber[key].value} value={AccountNumber[key].value}>{AccountNumber[key].label}</Option>
            })
            setOptionAccountNumber(optAccountNumber)

            form.resetFields();
            if (dataSetting?.setting?.truewalletQRDeposit) {
                form.setFieldsValue(dataSetting?.setting?.truewalletQRDeposit)
            }
        }

        if (form2) {
            form2.resetFields();
            if (dataSetting?.setting?.truewalletVoucher) {
                form2.setFieldsValue(dataSetting?.setting?.truewalletVoucher)
            }
        }

        if (form3) {
            if (dataSetting?.setting?.truewalletCallback) {
                form3.setFieldsValue(dataSetting?.setting?.truewalletCallback)
            }
        }

        if (dataSecret === undefined && dataSetting?.setting?.truewalletCallback.phoneNumber !== undefined) {
            const secret = `https://api.msn.bet/v2/external/truewallet/webhook/${dataSetting?.setting?.truewalletCallback.phoneNumber}?prefix=${prefixWeb}`
            setDataSecret(secret)
        }

        if (!features.truewallet) {
            setColSpan(24)
        }
    }, [form, form2, dataSetting])// eslint-disable-line

    return (
        <Fragment>
            <TitleBar title="ทรูมันนี่วอลเล็ท" />
            <Row gutter={16}>
                {features.truewallet &&
                    <Col sm={24} lg={12} className="mb-3">
                        <div className="box-white ant-box-card">
                            <Form
                                form={form}
                                name="truewalletQrCodeForm"
                                layout="vertical"
                                hideRequiredMark
                                size={'large'}>
                                <h5>ตั้งค่าเติมเงินทรูมันนี่วอลเล็ทด้วย QR Code</h5>
                                <Alert message={'** บัญชีธนาคารที่ใช้ต้องลงทะเบียนพร้อมเพย์ด้วยเบอร์โทรศัพท์ **'} type="error" className="mt-3" />
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="accountNumber"
                                            label="บัญชี"
                                            className="mt-3"
                                            rules={[
                                                // { required: true, message: 'กรุณาเลือกบัญชี' }
                                            ]}>
                                            <Select
                                                placeholder="เลือกบัญชี"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input: any, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {optionAccountNumber}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="phoneNumber"
                                            label="เบอร์โทรศัพท์"
                                            rules={[
                                                // { required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' },
                                                { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                            ]}>
                                            <Input placeholder="เบอร์โทรศัพท์" inputMode="numeric" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider />
                                <div className="drawer-actions">
                                    <Space>
                                        <Button type="primary" htmlType="submit" disabled={btnLoadingQrCode} onClick={handleFormSubmitQrCode} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                                        <Button onClick={() => resetForm(1)} type="primary" danger size="large">รีเซ็ต</Button>
                                    </Space>
                                </div>
                            </Form>
                        </div>
                    </Col>
                }
                <Col sm={24} lg={colSpan}>
                    <div className="box-white ant-box-card">
                        <Form
                            form={form2}
                            name="truewalletForm"
                            layout="vertical"
                            hideRequiredMark
                            size={'large'}>
                            <h5>ตั้งค่าเติมเงินทรูมันนี่วอลเล็ทด้วย อังเปา</h5>
                            <Alert message={'** เบอร์โทรศัพท์ต้องเป็นเบอร์ที่ลงทะเบียนกับทรูมันนี่วอลเล็ทเท่านั้น **'} type="error" className="mt-3" />
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="accountName"
                                        label="ชื่อบัญชี"
                                        className="mt-3"
                                        rules={[
                                            // { required: true, message: 'กรุณากรอกชื่อบัญชี' }
                                        ]}>
                                        <Input placeholder="ชื่อบัญชี" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="phoneNumber"
                                        label="เบอร์โทรศัพท์"
                                        rules={[
                                            // { required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' },
                                            { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                        ]}>
                                        <Input placeholder="เบอร์โทรศัพท์" inputMode="numeric" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <div className="drawer-actions">
                                <Space>
                                    <Button type="primary" htmlType="submit" disabled={btnLoadingVoucher} onClick={handleFormSubmitVoucher} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                                    <Button onClick={() => resetForm(2)} type="primary" danger size="large">รีเซ็ต</Button>
                                </Space>
                            </div>
                        </Form>
                    </div>
                </Col>
                <Col sm={24} lg={24} className="mb-3" style={{ display: 'none' }}>
                    <div className="box-white ant-box-card">
                        <Form
                            form={form3}
                            name="truewalletNotificationForm"
                            layout="vertical"
                            hideRequiredMark
                            size={'large'}
                            onValuesChange={onValuesChange}
                        >
                            <h5>ตั้งค่าเติมเงินทรูมันนี่วอลเล็ทด้วย Notification P2P</h5>
                            <Alert message={'** ไม่ควรใช้เบอร์โทรศัพท์เดียวกับ "ตั้งค่าเติมเงินทรูมันนี่วอลเล็ทด้วย อังเปา" **'} type="error" className="mt-3" />
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="status"
                                        label="สถานะ"
                                        valuePropName="checked"
                                        className="mt-3">
                                        <Switch
                                            checkedChildren="เปิด"
                                            unCheckedChildren="ปิด"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="statusFee"
                                        label="คิดค่าธรรมเนียม 1.9% แต่ไม่เกิน 10 บาท ต่อรายการ"
                                        valuePropName="checked">
                                        <Switch
                                            checkedChildren="เปิด"
                                            unCheckedChildren="ปิด"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="accountName"
                                        label="บัญชี"
                                        rules={[
                                            // { required: true, message: 'กรุณาเลือกบัญชี' }
                                        ]}>
                                        <Input placeholder="ชื่อบัญชี" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="phoneNumber"
                                        label="เบอร์โทรศัพท์"
                                        rules={[
                                            // { required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' },
                                            { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                        ]}>
                                        <Input placeholder="เบอร์โทรศัพท์" inputMode="numeric" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="urlTruewalletApiEndpoint"
                                        label="Truewallet Webhook API">
                                        <Input.Group className="d-flex" compact>
                                            <Input placeholder="" disabled inputMode="text" value={dataSecret} />
                                            <Tooltip title="คัดลอก Truewallet Webhook API">
                                                <Button icon={<CopyOutlined />} onClick={() => handleCopy(dataSecret)} />
                                            </Tooltip>
                                        </Input.Group>
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="secret"
                                        label="Secret"
                                        rules={[
                                            { message: 'กรุณากรอก secret' }
                                        ]}
                                        extra={`* ห้ามเผยแพร่ข้อมูลนี้กับบุคคลอื่น !`}>
                                        <Input placeholder="secret" inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            <div className="drawer-actions">
                                <Space>
                                    <Button type="primary" htmlType="submit" disabled={btnLoadingCallback} onClick={handleFormSubmitCallback} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                                    <Button onClick={() => resetForm(3)} type="primary" danger size="large">รีเซ็ต</Button>
                                </Space>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
            {features.truewallet &&
                <>
                    <Divider />
                    <ListTruewallet />
                </>
            }
        </Fragment>
    )
}

export default TrueWallet