import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { Space } from 'antd'
import moment from 'moment'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import FileSaver from "file-saver"
import * as XLSX from 'xlsx'
import TitleBar from '../../../components/Elements/TitleBar'
import TableComponent from '../../../components/Elements/TablePromotionCode'
import SearchData from '../../../components/Elements/Search'
import BadgeComponent from '../../../components/Elements/Badge'
import AddData from '../../../components/Promotions/Code/Add'
// import EditData from '../../../components/Promotions/Code/Edit'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import PrefixService from "../../../services/prefix"
import useFetchPromotionCode from "../../../hooks/useFetchPromotionCode"
import useDatePagination from '../../../hooks/useDatePagination'

const PromotionCode = () => {
    const [filterText, setFilterText] = useState('')
    // const [loading, setLoading] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<any>([])
    const [dataCodesExcel, setDataCodesExcel] = useState<any>([]);
    const { pagination, setPagination } = useDatePagination()
    const [searchAlldata, setSearchAllData] = useState<string>("")

    const { data, mutate, isLoading } = useFetchPromotionCode(searchAlldata, { page: pagination.page, limit: pagination.pageSize })
    const [addDrawer, setAddDrawer] = useState(false)
    const toggleAdd = () => setAddDrawer(!addDrawer)

    // const [editDrawer, setEditDrawer] = useState(false)
    // const toggleEdit = () => setEditDrawer(!editDrawer)

    // const [dataEdit, setDataEdit] = useState<object>()
    // const handleEdit = (object: any) => setDataEdit(object)
    const handleDelete = (id: any) => {
        if (id.length === 0) {
            toast.error("กรุณาเลือกรายการที่ต้องการลบ")
            return false
        }
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: "คุณต้องการลบรายการนี้ใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await PrefixService.deleteRedeem({
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        data: {
                            id: !Array.isArray(id) ? [id] : id
                        }
                    })
                    toast.success(res.message)
                    mutate()
                } catch ({ message }) {
                    toast.error(message as string)
                }
            }
        })
    }

    const columns: any = [
        {
            title: 'โค้ดโปรโมชั่น',
            dataIndex: 'Code',
            // width: '280px',
        },
        {
            title: 'เครดิต',
            dataIndex: 'Amount',
            width: '120px',
            align: 'center',
        },
        {
            title: 'ยอดถอนสูงสุด (เท่า)',
            dataIndex: 'MaxWithdraw',
            width: '180px',
            align: 'center',
        },
        {
            title: 'เทิร์น (เท่า)',
            dataIndex: 'Turn',
            width: '120px',
            align: 'center',
        },
        {
            title: 'สถานะ',
            dataIndex: 'Used',
            width: '180px',
            align: 'center',
            render: (text: any, record: any) => {
                return (
                    <BadgeComponent text={record.Used} className={record.UsedClass} />
                )
            }
        },
        {
            title: 'วันที่สร้าง',
            dataIndex: 'createdAt',
            width: '180px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'จัดการ',
            render: (record: any) => (
                <>
                    <Space size={0}>
                        {/* <button className="btn btn-warning btn-sm text-white me-2"
                            onClick={() => {
                                handleEdit(record)
                                toggleEdit()
                            }}>แก้ไข</button> */}
                        <button className="btn btn-danger btn-sm text-white me-2" onClick={() => handleDelete(record.key)}><i className="fa-solid fa-trash me-1"></i>ลบ</button>
                    </Space>
                </>
            )
        }
    ]

    useEffect(() => {
        if (data !== undefined) {
            const promotionCodeData = Object.values(data.data).map((item: any) => {
                return {
                    key: item.ID,
                    Code: item.Code,
                    Amount: item.Amount,
                    Used: item.Used ? "ใช้งานแล้ว" : "ยังไม่ได้ใช้งาน",
                    UsedClass: item.Used ? "badge-danger" : "badge-success",
                    MaxWithdraw: item.MaxWithdraw,
                    Turn: item.Turn,
                    CreatedAt: moment(item.CreatedAt).format("DD/MM/YYYY HH:mm:ss")
                }
            })
            // const filteredItems = (promotionCodeData as any).filter(
            //     (item: any) => (
            //         (item.Code && item.Code.toLowerCase().includes(filterText.toLowerCase()))
            //     ),
            // );
            setDataSource(promotionCodeData)
            const reData = Object.values(data.data).map((item: any) => {
                return {
                    Code: item.Code,
                    Amount: item.Amount,
                    Used: item.Used ? "ใช้งานแล้ว" : "ยังไม่ได้ใช้งาน",
                    UsedClass: item.Used ? "badge-danger" : "badge-success",
                    MaxWithdraw: item.MaxWithdraw,
                    Turn: item.Turn,
                    CreatedAt: moment(item.CreatedAt).format("DD/MM/YYYY HH:mm:ss")
                }
            })
            
            setDataCodesExcel(reData)
        }
        // setLoading(isLoading)
    }, [data, isLoading, filterText, setDataSource])

    useMemo(() => {
        mutate()
    }, [setPagination])// eslint-disable-line

    const handleSearch = () => {
        setSearchAllData(filterText)
        mutate()
    }

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const Heading = [
        {
            Code: "โค้ดโปรโมชั่น",
            Amount: "เครดิต",
            Used: "สถานะ",
            MaxWithdraw: "ยอดถอนสูงสุด (เท่า)",
            Turn: "เทิร์น (เท่า)",
            CreatedAt: "วันที่สร้าง"
        }
    ];

    const exportData = (dataExport: any) => {
        const ws = XLSX.utils.json_to_sheet(Heading, {
            header: ["Code", "Amount", "Used", "MaxWithdraw", "Turn", "CreatedAt"],
            skipHeader: true
        });
        // ws["!cols"] = [
        //     { wch: Math.max(...dataExport.map((item: any) => item.Code.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Amount.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Used.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.MaxWithdraw.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Turn.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.CreatedAt.length)) + 5 }
        // ];
        XLSX.utils.sheet_add_json(ws, dataExport, {
            header: ["Code", "Amount", "Used", "MaxWithdraw", "Turn", "CreatedAt"],
            skipHeader: true,
            origin: -1
        });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Export_Redeem" + fileExtension);
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Fragment>
            <TitleBar title="โค้ดโปรโมชั่น" subTitle={`${NumberWithCommas(data?.pagination.Count || 0)} รายการ`} />
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <SearchData onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">ค้นหา</button>
                <div className="ms-auto mt-3 mt-md-0 mt-xl-0">
                    <button className="btn btn-primary ms-auto ms-md-0  me-1" onClick={() => toggleAdd()}>
                        <i className="fa-solid fa-plus me-2"></i>
                        <span>เพิ่มโค้ดโปรโมชั่น</span>
                    </button>
                    <button className="btn btn-danger ms-auto ms-md-0"
                        onClick={() => handleDelete(selectedRowKeys)}
                        disabled={selectedRowKeys.length > 0 ? false : true}>
                        <i className="fa-solid fa-trash me-2"></i>
                        <span>ลบ</span>
                    </button>
                </div>
            </div>
            <div className="row mb-md-3 align-items-md-center">
                <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                    <button className="btn btn-info ms-auto ms-md-0" onClick={() => exportData(dataCodesExcel)}>
                        <i className="fa-solid fa-arrow-down-to-line me-2"></i>
                        <span>Export Excel</span>
                    </button>
                </div>
            </div>

            <div className="box-white ant-box-table">
                <TableComponent
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    totalCount={data?.pagination.Count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>
            <AddData
                isOpen={addDrawer}
                close={() => toggleAdd()}
                onloadData={mutate}
            />
            {/* <EditData
                isOpen={editDrawer}
                close={() => toggleEdit()}
                onloadData={mutate}
                data={dataEdit}
            /> */}
        </Fragment>
    )
}

export default PromotionCode