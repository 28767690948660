import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Space,
    Row,
    Col,
    Input,
    Divider,
    Tooltip,
    message
} from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import PrefixService from '../../services/prefix'
import { usersAddConfig } from '../../@types/prefix'
import { useNavigate } from 'react-router-dom'

interface open2faProps {
    close: () => void
    onloadData: () => void
    isOpen: boolean,
    data: any,
}

const Open2fa: FC<open2faProps> = ({ close, isOpen, onloadData, data }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const title = "เปิดใช้งาน 2FA (Two Factor Authentication)"
    const [btnLoading, setBtnLoading] = useState(false)

    const [dataImageQrCode, setDataImageQrCode] = useState<string>('')
    const [dataSecret, setDataSecret] = useState<string>('')

    const updateData = async (values: usersAddConfig) => {
        try {
            const res = await PrefixService.enable2FA(data.id, values)
            setBtnLoading(false)
            onloadData()
            toast.success(res.message)
            close()

            navigate('/logout')
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const generate2FA = async () => {
        try {
            const res: any = await PrefixService.generate2FA(data.id)
            form.setFieldsValue({
                'secret': res.secret
            });
            setDataImageQrCode(res.qrCode)
            setDataSecret(res.secret)
        } catch ({ message }) {
            toast.error(message as string)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: usersAddConfig) => {
                setBtnLoading(true)
                updateData(values)
            })
            .catch((errorInfo) => { });
    };

    const handleCopy = (values: string) => {
        navigator.clipboard.writeText(values)
        message.success('คัดลอกข้อมูลสำเร็จ');
    }

    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
            generate2FA()
        }
    }, [form, isOpen, data]) // eslint-disable-line

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="2faForm"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                                {dataImageQrCode &&
                                    <img alt="qrcode" src={dataImageQrCode} className="mb-4" />
                                }
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                                <Row gutter={16}>
                                    <Col span={20}>
                                        <Form.Item
                                            name="secret"
                                            label="Secret">
                                            <Input placeholder="Secret" disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} className="mt-4" style={{ paddingTop: '6px' }}>
                                        <Tooltip title="คัดลอก Secret">
                                            <Button icon={<CopyOutlined />} onClick={() => handleCopy(dataSecret)} />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="otpCode"
                                label="OTP Code"
                                rules={[{ required: true }, { message: 'กรุณากรอก OTP Code' }]}
                                hasFeedback>
                                <Input.Password placeholder="OTP Code" inputMode="numeric" showCount maxLength={6} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                            <Button onClick={close} size="large">ยกเลิก</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default Open2fa

