import React, { FC, ReactNode, useEffect, useState } from 'react'
import ProfileButton from '../../components/Elements/ProfileButton'
// import SwapPrefix from '../../components/Elements/SwapPrefix'
// import TranslationButton from '../../components/Elements/TranslationButton'
import PrefixService from '../../services/prefix'
import AuthService from '../../services/auth'

interface TitleBarProps {
    title: string | ReactNode
    subTitle?: string | ReactNode
}

const TitleBar: FC<TitleBarProps> = ({ title, subTitle }) => {
    const [scrollPosition, setScrollPosition] = useState(0)
    const namePrefix = PrefixService.getPrefix().toUpperCase()
    const username = AuthService.getUsername()
    const onScroll = (offset: number) => setScrollPosition(offset)
    const funcSetScroll = () => window.addEventListener('scroll', () => onScroll(window.pageYOffset))
    useEffect(() => {
        funcSetScroll()
        return () => {
            onScroll(0);
            setScrollPosition(0)
        };
    }, []) //eslint-disable-line

    useEffect(() => {
        funcSetScroll()
    }, []) //eslint-disable-line

    return (
        <div className={'title-bar mt-3 mb-4 mb-lg-5' + (scrollPosition > 50 ? ' scrolling' : '')}>
            <h2 className="mb-0 position-relative">
                {title}
                {subTitle && (<small className="sub-title">{subTitle}</small>)}
            </h2>
            {/* <SwapPrefix className="d-none d-lg-block" prefix={namePrefix} /> */}
            <ProfileButton className="d-none d-lg-block" username={username} prefix={namePrefix} />
        </div>
    )
}

export default TitleBar