import React, { FC, useState, useEffect, useMemo } from 'react'
import { Row, Col } from 'antd'
import Chart from 'react-apexcharts'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import useFetchReport from '../../../hooks/useFetchReport'
import { depositSummaryItem } from '../../../@types/report'
import Loading from '../../Elements/Loading'

interface DepositTabProps {
    // dataSource: any
    dateRange: any
}

const DepositTab: FC<DepositTabProps> = ({ dateRange }) => {
    const [amountDeposit, setAmountDeposit] = useState([])
    const [countDeposit, setCountDeposit] = useState([])
    const [amountDepositBar, setAmountDepositBar] = useState([])
    const [countDepositBar, setCountDepositBar] = useState([])

    // Deposit
    const AmountOfDepositPieChart = {
        options: {
            series: amountDeposit,
            labels: [
                'ธนาคาร',
                'ทรูมันนี่วอลเล็ท',
                'ธนาคาร (เติมมือ)',
                'ทรูมันนี่วอลเล็ท (เติมมือ)'
            ],
            colors: ['#17c5a2', '#5952c0', '#fd7e14', '#dc3545'],
            chart: {
                dropShadow: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    top: 5,
                    left: 0,
                    blur: 10,
                    color: '#8090d0',
                    opacity: 0.2
                }
            },
            legend: {
                position: 'bottom',
                fontFamily: 'Roboto, Noto Sans Thai, sans-serif',
            },
            stroke: {
                show: false
            },
            fill: {
                colors: ['#17c5a2', '#5952c0', '#fd7e14', '#dc3545']
            },
            tooltip: {
                style: {
                    fontSize: '14px',
                    fontFamily: 'Roboto, Noto Sans Thai, sans-serif',
                },
                y: {
                    formatter: function (val: any) {
                        return NumberWithCommas(val, 2) + ' บาท'
                    },
                }
            }
        },
    };

    const CountOfDepositPieChart = {
        options: {
            series: countDeposit,
            labels: [
                `ธนาคาร (เปอร์เซ็นต์)`,
                `ทรูมันนี่วอลเล็ท (เปอร์เซ็นต์)`,
                `ธนาคาร เติมมือ (เปอร์เซ็นต์)`,
                `ทรูมันนี่วอลเล็ท เติมมือ (เปอร์เซ็นต์)`,
            ],
            colors: ['#17c5a2', '#5952c0', '#fd7e14', '#dc3545'],
            chart: {
                dropShadow: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    top: 5,
                    left: 0,
                    blur: 10,
                    color: '#8090d0',
                    opacity: 0.2
                }
            },
            legend: {
                position: 'bottom',
                fontFamily: 'Roboto, Noto Sans Thai, sans-serif',
            },
            stroke: {
                show: false
            },
            fill: {
                colors: ['#17c5a2', '#5952c0', '#fd7e14', '#dc3545']
            },
            tooltip: {
                style: {
                    fontSize: '14px',
                    fontFamily: 'Roboto, Noto Sans Thai, sans-serif',
                },
                y: {
                    formatter: function (val: any) {
                        return NumberWithCommas(val) + ' ครั้ง'
                    },
                }
            }
        },
    };

    const AmountOfDepositBarChart = {
        options: {
            series: [{
                name: 'จำนวนเงิน',
                data: amountDepositBar,
                color: '#17c5a2',
            }],
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            colors: ['#52D4BA', '#FF4267'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false,
            },
            tooltip: {
                theme: 'apexcharts-tooltip',
                x: {
                    formatter: function (val: any) {
                        return 'เวลา ' + val + ' น.'
                    },
                },
                y: {
                    formatter: function (val: any) {
                        return NumberWithCommas(val, 2) + ' บาท'
                    },
                }
            },
            xaxis: {
                categories: [
                    '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
                    '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00',
                    '22:00', '23:00',
                ],
                labels: {
                    rotate: -60,
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                }
            },
            yaxis: {
                labels: {
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                    formatter: function (val: any) {
                        return NumberWithCommas(val, 0)
                    },
                }
            },
            grid: {
                borderColor: 'var(--line)',
            },
            legend: {
                labels: {
                    colors: ['var(--text-secondary)']
                }
            }
        }
    };

    const CountOfDepositBarChart = {
        options: {
            series: [{
                name: 'จำนวนครั้ง',
                data: countDepositBar,
                color: '#17c5a2',
            }],
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            colors: ['#52D4BA', '#FF4267'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false,
            },
            tooltip: {
                theme: 'apexcharts-tooltip',
                x: {
                    formatter: function (val: any) {
                        return 'เวลา ' + val + ' น.'
                    },
                },
                y: {
                    formatter: function (val: any) {
                        return NumberWithCommas(val) + ' ครั้ง'
                    },
                }
            },
            xaxis: {

                categories: [
                    '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
                    '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00',
                    '22:00', '23:00',
                ],
                labels: {
                    rotate: -60,
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                }
            },
            yaxis: {
                labels: {
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                }
            },
            grid: {
                borderColor: 'var(--line)',
            },
            legend: {
                labels: {
                    colors: ['var(--text-secondary)']
                }
            }
        }
    };

    const { data: dataDeposit, mutate: mutateDeposit, isLoading } = useFetchReport<depositSummaryItem>('deposit', dateRange)


    useEffect(() => {
        if (dataDeposit && Object.keys(dataDeposit).length > 0) {
            const amountDeposit: any = [
                Math.round(dataDeposit?.bankTotal * 100) / 100,
                Math.round(dataDeposit?.truewalletTotal * 100) / 100,
                Math.round(dataDeposit?.bankManualTotal * 100) / 100,
                Math.round(dataDeposit?.truewalletManualTotal * 100) / 100,
            ];
            setAmountDeposit(amountDeposit)

            const countDeposit: any = [
                dataDeposit?.bank,
                dataDeposit?.truewallet,
                dataDeposit?.bankManual,
                dataDeposit?.truewalletManual,
            ];
            setCountDeposit(countDeposit)

            setAmountDepositBar(Object.values(dataDeposit.amountHours))

            setCountDepositBar(Object.values(dataDeposit.countHours))
        }
    }, [dataDeposit, isLoading])

    useMemo(() => {
        mutateDeposit()
    }, [dateRange])// eslint-disable-line

    return (
        <>
            {/* Deposit */}
            <Row gutter={[16, 16]} className="mb-5">
                <Col span={24}>
                    <h4>รายงานการฝาก</h4>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="box-white ant-box-table p-3 p-lg-4 mb-2 mb-lg-0">
                        <p className="text-center mb-0">ยอดรวมรายการฝากเงิน (จำนวนเงิน)</p>
                        {isLoading ? <Loading /> :
                            <Chart
                                type="pie"
                                options={AmountOfDepositPieChart.options as object}
                                series={AmountOfDepositPieChart.options.series}
                            />
                        }
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="box-white ant-box-table p-3 p-lg-4 mb-2 mb-lg-0">
                        <p className="text-center mb-0">ยอดรวมรายการฝากเงิน (ครั้ง)</p>
                        {isLoading ? <Loading /> :
                            <Chart
                                type="pie"
                                options={CountOfDepositPieChart.options as object}
                                series={CountOfDepositPieChart.options.series}
                            />
                        }
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="box-white ant-box-table p-md-2 pt-3 pt-md-4 pb-md-0 mb-2 mb-lg-0">
                        <p className="text-center mb-0">จำนวนการฝากเงินรายชั่วโมง (จำนวนเงิน)</p>
                        {isLoading ? <Loading /> :
                            <Chart
                                type="bar"
                                options={AmountOfDepositBarChart.options as object}
                                series={AmountOfDepositBarChart.options.series}
                            />
                        }
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="box-white ant-box-table p-md-2 pt-3 pt-md-4 pb-md-0 mb-2 mb-lg-0">
                        <p className="text-center mb-0">จำนวนการฝากเงินรายชั่วโมง (ครั้ง)</p>
                        {isLoading ? <Loading /> :
                            <Chart
                                type="bar"
                                options={CountOfDepositBarChart.options as object}
                                series={CountOfDepositBarChart.options.series}
                            />
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default DepositTab