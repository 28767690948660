import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Space,
    Divider,
    Select,
    Radio,
    Upload,
    Switch,
    InputNumber
} from 'antd'
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import PrefixService from "../../../services/prefix"
import { requestAuthInit } from '../../../store/auth/auth.actions'
import {
    BonusAmountTypes,
    BonusGameTypes,
    PromotionConfig,
    PromotionLimits,
    PromotionLimitTypes,
    PromotionUfaCashbacks,
    WithdrawLimitTypes
} from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import PatternNumber from '../../../utils/PatternNumber'
import StepSetting from './StepSetting'
import PeriodSetting from './PeriodSetting'
import ConsecutiveDepositBonus from './ConsecutiveDepositBonus'
import NoDepositBetween from './NoDepositBetween'
import moment from "moment";

interface EditBonusProps {
    close: () => void
    isOpen: boolean

    data: any
    bonusKey: string
}

const EditBonus: FC<EditBonusProps> = ({ close, isOpen, data, bonusKey }) => {
    const [form] = Form.useForm();
    const title = "แก้ไขโบนัส"
    const { Option } = Select;
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [imageUrl, setImageUrl] = useState<string>("")
    const [loadingImage, setLoadingImage] = useState<boolean>(false)
    const [category, setCategory] = useState<string>("")
    const [withdrawLimitType, setWithdrawLimitType] = useState<string>("")
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    const updateData = async (values: PromotionConfig) => {
        try {
            const res = await PrefixService.savePromotionConfig(bonusKey, values)
            setBtnLoading(false)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            close()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: PromotionConfig) => {
                setBtnLoading(true)
                // values.steps = steps
                values.steps = values.steps && values.steps.filter((x) => !x.deleted)
                // values.periods = periods
                values.periods = values.periods && values.periods.filter((x) => !x.deleted)
                values.noDepositBetween.startDate = values.noDepositBetween.startDate ? moment(values.noDepositBetween.startDate).format('YYYY-MM-DD') : ""
                values.noDepositBetween.endDate = values.noDepositBetween.endDate ? moment(values.noDepositBetween.endDate).format('YYYY-MM-DD') : ""
                updateData(values)
            })
            .catch((errorInfo) => { });
    };


    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const uploadButton = (
        <div>
            {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>อัพโหลด</div>
        </div>
    );

    const uploadPromotionImage = async (response: any) => {
        setLoadingImage(true)
        let formData: FormData = new FormData();
        formData.append('file', response.file);
        formData.append('setting', 'promotion');

        try {
            const res = await PrefixService.uploadPromotionImage(bonusKey ? bonusKey : '', formData)
            toast.success(res.message)
            setLoadingImage(false)
            getBase64(response.file, (imageUrl: any) => setImageUrl(imageUrl));
        } catch ({ message }) {
            toast.error(message as string)
            setLoadingImage(false)
        }
    }

    const deleteImage = async () => {
        try {
            await PrefixService.deletePromotionImage(bonusKey ? bonusKey : '')
            toast.success('ลบรูปภาพสำเร็จ')
            setImageUrl('')
        } catch ({ message }) {
            toast.error(message as string)
        }
    }


    useEffect(() => {
        if (form && isOpen && data) {
            form.resetFields();
            data.gameType = data.gameType ? data.gameType : 'none'
            data.steps = data.steps || [{ min: 0, max: 0, bonus: 0, deleted: false }]
            data.periods = data.periods || [{ from: "00:00", to: "23:59", deleted: false }]
            data.consecutiveDepositBonus.rules = data.consecutiveDepositBonus?.rules || {
                noBonus: false,
                noCashback: false,
                noLuckyWheel: false,
                noWithdraw: false
            }
            data.noDepositBetween.rules = data.noDepositBetween?.rules || {
                noBonus: false,
                noCashback: false,
                noLuckyWheel: false,
                noWithdraw: false
            }
            data.noDepositBetween.startDate = data.noDepositBetween.startDate ? moment(data.noDepositBetween.startDate) : ""
            data.noDepositBetween.endDate = data.noDepositBetween.endDate ? moment(data.noDepositBetween.endDate) : ""
            form.setFieldsValue(data)

            setCategory(data?.category)
            setWithdrawLimitType(data?.withdrawLimitType)
            setImageUrl(data?.image)
        }
    }, [form, isOpen, data]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .7}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }>
                <Form
                    form={form}
                    name="EditBonus"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="enable"
                                label="สถานะ"
                                valuePropName="checked"
                                className="mb-0"
                            >
                                <Switch
                                    checkedChildren="เปิด"
                                    unCheckedChildren="ปิด"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="ชื่อโบนัส"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อโบนัส' }]}
                                hasFeedback>
                                <Input placeholder="ชื่อโบนัส" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="รายละเอียด"
                                rules={[{ required: true, message: 'กรุณากรอกรายละเอียด' }]}
                                hasFeedback>
                                {/* <Input.TextArea rows={3} placeholder="รายละเอียด" /> */}
                                <Input placeholder="รายละเอียด" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" orientationMargin="0">
                        ประเภทโบนัส
                    </Divider>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="category"
                                label="ประเภทโบนัส"
                                rules={[{ required: true, message: 'กรุณาประเภทโบนัส' }]}
                                hasFeedback>
                                <Select
                                    placeholder="เลือกประเภทโบนัส"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onSelect={(value: any) => {
                                        setCategory(value);
                                    }}>
                                    {PromotionLimitTypes.map(({ value, label }) => (
                                        <Option value={value} key={value} >{label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {category === 'deposit' && (
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="limit"
                                    label=""
                                    hasFeedback>
                                    <Radio.Group buttonStyle="solid" className="ant-radio-button-custom">
                                        {PromotionLimits.map(({ limit, label }) => (
                                            <Radio.Button value={limit} key={limit}>{label}</Radio.Button>
                                        ))}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    {category === 'ufa-sport-wl-cashback' && (
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="limit"
                                    label=""
                                    hasFeedback>
                                    <Radio.Group buttonStyle="solid" className="ant-radio-button-custom">
                                        {PromotionUfaCashbacks.map(({ limit, label }) => (
                                            <Radio.Button value={limit} key={limit}>{label}</Radio.Button>
                                        ))}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Divider />
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="type"
                                label="ประเภทจำนวน"
                                hasFeedback>
                                <Radio.Group buttonStyle="solid">
                                    {BonusAmountTypes.map(({ type, label }) => (
                                        <Radio.Button value={type} key={type}>{label}</Radio.Button>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="gameType"
                                label="ประเภทเกม"
                                hasFeedback>
                                <Radio.Group buttonStyle="solid">
                                    {BonusGameTypes.map(({ game, label }) => (
                                        <Radio.Button value={game} key={game}>{label}</Radio.Button>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="order"
                                label="ลำดับ"
                                rules={[{ required: true, message: 'กรุณากรอกลำดับ' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <InputNumber placeholder="ลำดับ" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label="โบนัส"
                                rules={[{ required: true, message: 'กรุณากรอกโบนัส' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <InputNumber placeholder="โบนัส" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="minBonus"
                                label="โบนัสขั้นต่ำ"
                                rules={[{ required: true, message: 'กรุณากรอกโบนัสขั้นต่ำ' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <InputNumber placeholder="โบนัสขั้นต่ำ" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="maxBonus"
                                label="โบนัสสูงสุด"
                                rules={[{ required: true, message: 'กรุณากรอกโบนัสสูงสุด' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <InputNumber placeholder="โบนัสสูงสุด" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="minimum"
                                label="ยอดฝากขั้นต่ำ"
                                rules={[{ required: true, message: 'กรุณากรอกยอดฝากขั้นต่ำ' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <InputNumber placeholder="ยอดฝากขั้นต่ำ" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="turn"
                                label="เทิร์น (เท่า)"
                                rules={[{ required: true, message: 'กรุณากรอกเทิร์น (เท่า)' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <InputNumber placeholder="เทิร์น (เท่า)" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="withdrawLimitType"
                                label="ประเภทถอนสูงสุด"
                                rules={[{ required: true, message: 'กรุณาประเภทถอนสูงสุด' }]}
                                hasFeedback>
                                <Select
                                    placeholder="เลือกประเภทถอนสูงสุด"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onSelect={(value: any) => {
                                        setWithdrawLimitType(value)
                                    }}>
                                    {WithdrawLimitTypes.map(({ value, label }) => (
                                        <Option value={value} key={value} >{label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="withdrawLimit"
                                label={withdrawLimitType === 'fixed' ? 'จำนวนถอนสูงสุด' : 'ถอนสูงสุด (เท่า)'}
                                rules={[{ required: true, message: 'กรุณากรอก' + (withdrawLimitType === 'fixed' ? 'จำนวนถอนสูงสุด' : 'ถอนสูงสุด (เท่า)') }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <InputNumber placeholder={withdrawLimitType === 'fixed' ? 'จำนวนถอนสูงสุด' : 'ถอนสูงสุด (เท่า)'} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <StepSetting data={data} />
                    <PeriodSetting data={data} />
                    <ConsecutiveDepositBonus data={data} />
                    <NoDepositBetween data={data} />

                    <Row gutter={16}>
                        <Col span={24}>
                            <label style={{ marginBottom: '16px' }}>รูปภาพโปรโมชั่น</label>
                            <div className="upload-image">
                                <Upload
                                    name="promotion-image"
                                    listType="picture-card"
                                    showUploadList={false}
                                    customRequest={uploadPromotionImage}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                                {imageUrl &&
                                    <Button type="primary" onClick={deleteImage} icon={<DeleteOutlined />} shape="circle" danger></Button>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                            <Button onClick={close} size="large">ยกเลิก</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditBonus

