import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'
import {
    Drawer,
    Button,
    Space
} from 'antd';
import moment from 'moment'
// import QRCode from 'qrcode.react'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import PrefixService from '../../../services/prefix'
import useDateRange from "../../../hooks/useDateRange"
import { useAPIRequest } from '../../../hooks/useAPIRequest'
import DatePickerRange from '../../Elements/DatePickerRange'
import DateFilterTabs from '../../Elements/DateFilterTabs'
import TableComponent from '../../Elements/Table'
import SlipModal from '../../Slip/SlipModal'


interface HistoryProps {
    close: () => void
    isOpen: boolean
}

const History: FC<HistoryProps> = ({ close, isOpen }) => {
    const title = 'ประวัติการโอนเงิน'

    const [viewSlipModal, setViewSlipModal] = useState(false)
    const [transaction, setTransaction] = useState({})
    const toggleViewSlip = () => setViewSlipModal(!viewSlipModal)

    const [dataSource, setDataSource] = useState<any>([])
    const { dateRange, setDateRange } = useDateRange()
    const { data, loading, update } = useAPIRequest(
        useCallback(
            async () =>
                PrefixService.getSCBTransactionList(dateRange),
            [dateRange]
        )
    )
    // const [qrcodeDrawer, setQrcodeDrawer] = useState<boolean>(false)
    // const toggleQRCode = () => setQrcodeDrawer(!qrcodeDrawer)
    // const [dataQRCode, setDataQRCode] = useState<string>("")
    // const handleCheckSlip = (values: any) => {
    //     if (values) {
    //         Swal.fire({
    //             title: 'QR Code',
    //             html: `
    //             <img src="https://chart.apis.google.com/chart?cht=qr&chs=256x256&chl=${values}">
    //         `,
    //             confirmButtonColor: '#3085d6',
    //             confirmButtonText: 'ปิด'
    //         }).then(async (result) => {
    //         })
    //     } else {
    //         toast.error("ไม่พบ QR Code")
    //     }
    // }
    const handleCheckSlip = (values: any) => {
        if (values.transactionLog) {
            setTransaction(values)
            toggleViewSlip()
        } else {
            if (values.qrString) {
                Swal.fire({
                    title: 'สลิปโอนเงิน',
                    html: `
                    <img src="https://chart.apis.google.com/chart?cht=qr&chs=256x256&chl=${values.qrString}">
                `,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ปิด'
                }).then(async (result) => {
                })
            } else {
                toast.error("ไม่พบ QR Code")
            }
        }
    }

    const columns = [
        {
            title: 'จาก',
            dataIndex: 'fromAccount'
        },
        {
            title: 'ไปยัง',
            dataIndex: 'toAccount'
        },
        {
            title: 'จำนวนเงิน',
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <>
                    <div className="row-amount">
                        <span>฿</span> {NumberWithCommas(record.amount, 2)}
                    </div>
                    <div className="msn-badge badge-light mt-2">คงเหลือ : ฿{NumberWithCommas(record.afterBalance, 2)}</div>
                </>
            )
        },
        {
            title: 'วันที่/เวลา',
            dataIndex: 'createdAt',
            width: '120px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'จัดการ',
            dataIndex: 'action',
            render: (text: any, record: any) => (
                <>
                    {(record.qrString || record?.transactionLog) &&
                        <Space size={0}>
                            <button className="btn btn-info btn-sm text-white me-2" onClick={() => { handleCheckSlip(record); }}>QR Code</button>
                        </Space>
                    }
                </>
            )
        }
    ]

    useEffect(() => {
        if (isOpen && data) {
            setDataSource(data)
        }
    }, [data, setDataSource, isOpen])

    useMemo(() => {
        if (isOpen) {
            update()
        }
    }, [setDateRange, isOpen])// eslint-disable-line

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .8}
                extra={
                    <Space>
                        <Button onClick={close} size="large">ปิด</Button>
                    </Space>
                }>
                <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                    <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                    <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
                </div>
                <h5 className="mt-5 mb-3">ประวัติการโอนเงิน <small className="sub-title">{`${NumberWithCommas(dataSource ? dataSource.length : 0)} รายการ`}</small></h5>
                <div className="box-white ant-box-table">
                    <TableComponent
                        columns={columns}
                        dataSource={dataSource || []}
                        loading={loading}
                        customOffsetHeader={-24}
                    />
                </div>
                {/* <Drawer
                    title="QRCode"
                    onClose={toggleQRCode}
                    visible={qrcodeDrawer}
                    extra={
                        <Space>
                            <Button onClick={toggleQRCode} size="large">ปิด</Button>
                        </Space>
                    }>
                    <Space size={0}>
                        <QRCode value={dataQRCode} />
                    </Space>
                </Drawer> */}
                <SlipModal
                    data={transaction as any}
                    isOpen={viewSlipModal}
                    close={() => toggleViewSlip()}
                />
            </Drawer>
        </>
    )
}
export default History