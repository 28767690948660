import React, { Fragment, useEffect, useState, useMemo } from 'react'
import TitleBar from '../../../components/Elements/TitleBar'
import DatePickerRange from '../../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../../components/Elements/DateFilterTabs'
import TableComponent from '../../../components/Elements/Table'
import TablePagination from '../../../components/Elements/TablePagination'
import AvatarMemberComponent from '../../../components/Elements/AvatarMember'
import { Divider } from 'antd'
import moment from 'moment'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import useDateRange from "../../../hooks/useDateRange"
import useFetchReport from '../../../hooks/useFetchReport'
import { RedeemSummary } from '../../../@types/report'
import useDatePagination from '../../../hooks/useDatePagination'

const ReportPromotionCode = () => {
    const { dateRange, setDateRange } = useDateRange()
    const [dataSource, setDataSource] = useState<any>([])
    const [dataTransactionsSource, setDataTransactionsSource] = useState<any>([])

    const { pagination, setPagination } = useDatePagination()
    const { data, mutate, isLoading } = useFetchReport<RedeemSummary>('redeem', dateRange, 10000, { page: pagination.page, limit: pagination.pageSize })

    const columnsSummary: any = [
        {
            title: 'โค้ดโปรโมชั่น',
            dataIndex: 'name',
        },
        {
            title: 'จำนวนครั้ง',
            dataIndex: 'count',
        },
        {
            title: 'จำนวนเงิน',
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.amount, 2)}
                </div>
            )
        }
    ]

    const columnsTransactions: any = [
        {
            title: 'เวลา',
            dataIndex: 'createdAt',
            width: '140px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'สมาชิก',
            dataIndex: 'name',
            render: (text: any, record: any) => (
                <>
                    <AvatarMemberComponent
                        fullname={`${record.member.firstName} ${record.member.lastName}`}
                        phone={record.member.phone}
                        search={true}
                    />
                </>
            )
        },
        {
            title: 'โค้ดโปรโมชั่น',
            dataIndex: 'code',
            render: (text: any, record: any) => (
                <>
                    {record.redeem}
                </>
            )
        },
        {
            title: 'จำนวนเงิน',
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.amount, 2)}
                </div>
            )
        },
        {
            title: 'ยอดเทิร์น',
            dataIndex: 'totalTurn',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.totalTurn, 2)}
                </div>
            )
        },
        {
            title: 'เทิร์น',
            dataIndex: 'turn',
            render: (text: any, record: any) => (
                <>
                    {record.turn} เท่า
                </>
            )
        }
    ]

    useEffect(() => {
        setDataSource([])
        setDataTransactionsSource([])
        if (data !== undefined) {
            if (data.data.summary.length > 0) {
                setDataSource(data.data.summary)
            }
            if (data.data.transactions.length > 0) {
                setDataTransactionsSource(data.data.transactions)
            }
        }
        // setLoading(isLoading)
    }, [data, isLoading, setDataSource, setDataTransactionsSource])

    useMemo(() => {
        // setLoading(true)
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    return (
        <Fragment>
            <TitleBar title="รายงานโค้ดโปรโมชั่น" />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>

            <h5 className="mt-5 mb-3">รายงานการรับโค้ดโปรโมชั่นแต่ละประเภท</h5>
            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columnsSummary}
                    dataSource={dataSource}
                    loading={isLoading}
                />
            </div>
            <Divider />

            <h5 className="mt-5 mb-3">รายงานการรับโค้ดโปรโมชั่น <small className="sub-title">{`${NumberWithCommas(dataTransactionsSource.length)} รายการ`}</small></h5>
            <div className="box-white ant-box-table">
                <TablePagination
                    columns={columnsTransactions}
                    dataSource={dataTransactionsSource}
                    loading={isLoading}
                    totalCount={data?.pagination.Count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>
        </Fragment>
    )
}

export default ReportPromotionCode