import React, { Fragment, useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import { Select, Collapse, Row, Col } from "antd";
import TitleBar from '../../components/Elements/TitleBar'
import TableComponent from '../../components/Elements/Table'
import DatePickerRange from '../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../components/Elements/DateFilterTabs'
import AmountCard from '../../components/Elements/AmountCard'
import SearchData from '../../components/Elements/Search'
import AvatarMemberComponent from '../../components/Elements/AvatarMember'
import FixTxBadge from '../../components/Elements/FixTxBadge'
import NumberWithCommas from '../../utils/NumberWithCommas'
import useDateRange from "../../hooks/useDateRange"
import useFetchFixTixList from '../../hooks/useFetchFixTixList'
import { sumFixAddCredit, sumFixBank, sumFixDeductCredit, sumFixTW } from '../../utils/fitTx'
import AddData from '../../components/FixTx/Add'

const FixTx = () => {
    const [filterText, setFilterText] = useState('')
    const [dataSource, setDataSource] = useState<any>([])
    const { dateRange, setDateRange } = useDateRange()
    const { Option } = Select;
    const { Panel } = Collapse;
    const [category, setCategory] = useState<string>("all")
    const [addDrawer, setAddDrawer] = useState(false)
    const toggleAdd = () => setAddDrawer(!addDrawer)

    const { data, mutate, isLoading } = useFetchFixTixList(dateRange)

    const columns: any = [
        {
            title: 'วันที่/เวลา',
            dataIndex: 'createdAt',
            width: '180px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'สมาชิก',
            dataIndex: 'member',
            render: (text: any, record: any) => (
                <>
                    <AvatarMemberComponent
                        fullname={`${record.member.firstName} ${record.member.lastName}`}
                        phone={record.member.phone}
                        search={true}
                    />
                </>
            )
        },
        {
            title: 'รายละเอียด',
            dataIndex: 'remark',
            render: (text: any, record: any) => (
                <>
                    <div className="row-datetime">
                        <div><span>{moment(record.transferTime).format('DD/MM/YYYY')}</span> {moment(record.transferTime).format('HH:mm')}</div>
                    </div>
                    <div className="msn-badge badge-light mt-2 text-danger">ทำรายการโดย : {record.operator}</div>
                </>
            )
        },
        {
            title: 'หมายเหตุ',
            dataIndex: 'remark',
            width: '180px',
            render: (text: any, record: any) => (
                <div>{record.remark}</div>
            )
        },
        {
            title: 'จำนวนเงิน',
            dataIndex: 'amount',
            width: '120px',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.amount, 2)}
                </div>
            )
        },
        {
            title: 'ประเภท',
            dataIndex: 'transferFrom',
            render: (text: any, record: any) => (
                <>
                    <FixTxBadge tx={record} />
                </>
            )
        },
    ]

    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.length > 0) {
            let filteredItems = (data as any).filter(
                (item: any) => (
                    (item.member.firstName && item.member.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.member.lastName && item.member.lastName.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.member.phone && item.member.phone.toLowerCase().includes(filterText.toLowerCase()))
                ),
            );
            if (category !== 'all') {
                filteredItems = (filteredItems as any).filter(
                    (item: any) => (
                        (category === 'bank' && item.type === 'deposit' && item.transferFrom !== 'TRUEWALLET') ||
                        (category === 'tw' && item.type === 'deposit' && item.transferFrom === 'TRUEWALLET') ||
                        (category === 'add' && item.type === 'add') ||
                        (category === 'sub' && item.type === 'deduct')
                    ),
                );
            }
            setDataSource(filteredItems)
        }
    }, [data, isLoading, filterText, setDataSource, category])

    useMemo(() => {
        mutate()
    }, [setDateRange])// eslint-disable-line

    return (
        <Fragment>
            <TitleBar title="แก้ไขรายการผิดพลาด" subTitle={`${NumberWithCommas(dataSource.length || 0)} รายการ`} />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <SearchData onChangeSearch={setFilterText} filterText={filterText} />
                <div className="ms-auto mt-3 mt-md-0 mt-xl-0">
                    <label className="me-2">ประเภท : </label>
                    <Select
                        size="large"
                        dropdownMatchSelectWidth={false}
                        placement="bottomRight"
                        value={category}
                        placeholder="เลือกประเภท"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onSelect={(value: any) => {
                            setCategory(value);
                        }}>
                        <Option value={"all"} key={"all"} >ทั้งหมด</Option>
                        <Option value={"bank"} key={"bank"} >ธนาคารไม่รัน</Option>
                        <Option value={"tw"} key={"tw"} >ทรูมันนี่วอลเล็ทไม่รัน</Option>
                        <Option value={"add"} key={"add"} >เติมเครดิต</Option>
                        <Option value={"sub"} key={"sub"} >ตัดเครดิต</Option>
                    </Select>
                </div>
            </div>

            <Collapse ghost defaultActiveKey={['1']} className="mb-3 antd-custom-panel">
                <Panel header="สรุปแก้ไขรายการผิดพลาด" key="1">
                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={6}>
                            <AmountCard
                                icon='fa-solid fa-university'
                                theme='info'
                                amount={sumFixBank(dataSource)}
                                amountType='ธนาคารไม่รัน'
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <AmountCard
                                icon='fa-solid fa-wallet'
                                theme='warning'
                                amount={sumFixTW(dataSource)}
                                amountType='ทรูมันนี่วอลเล็ทไม่รัน'
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <AmountCard
                                icon='fa-solid fa-plus-circle'
                                theme='success'
                                amount={sumFixAddCredit(dataSource)}
                                amountType='เติมเครดิต'
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <AmountCard
                                icon='fa-solid fa-minus-circle'
                                theme='danger'
                                amount={sumFixDeductCredit(dataSource)}
                                amountType='ตัดเครดิต'
                                loading={isLoading}
                            />
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            <div className="row mb-md-3 align-items-md-center">
                <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                    <button className="btn btn-primary ms-auto ms-md-0" style={{ whiteSpace: 'nowrap' }} onClick={() => toggleAdd()}>
                        <i className="fa-solid fa-edit me-3"></i>
                        <span >แก้ไขรายการผิดพลาด</span>
                    </button>
                </div>
            </div>

            <AddData
                isOpen={addDrawer}
                close={() => toggleAdd()}
                onloadData={mutate}
            />

            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                />
            </div>
        </Fragment >
    )
}

export default FixTx