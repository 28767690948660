import { ISidebar } from '../@types/ISidebar'
// import i18n from "../utils/i18n"

const onLoadMenuSidebar = () => {
    const menuSidebar: Array<ISidebar> = [
        {
            id: 1,
            permission_key: 'manage.all',
            features_key: '',
            title_id: '',
            title: "หน้าแรก",
            path: "/main",
            icon: "fa-duotone fa-house-chimney",
            isShow: true,
            parent_id: 0
        },
        {
            id: 2,
            permission_key: 'manage.members',
            features_key: '',
            title_id: '',
            title: "สมาชิก",
            path: "/members",
            icon: "fa-duotone fa-users",
            isShow: true,
            parent_id: 0
        },
        {
            id: 3,
            permission_key: 'manage.deposit',
            features_key: '',
            title_id: '',
            title: "รายการฝาก / ถอน",
            path: "#",
            icon: "fa-duotone fa-money-bill",
            isShow: true,
            parent_id: 0
        },
        {
            id: 4,
            permission_key: 'manage.deposit',
            features_key: '',
            title_id: '',
            title: "รายการฝาก",
            path: "/transaction-deposit",
            icon: "",
            isShow: true,
            parent_id: 3
        },
        {
            id: 5,
            permission_key: 'manage.withdraw',
            features_key: '',
            title_id: '',
            title: "รายการถอน",
            path: "/transaction-withdraw",
            icon: "",
            isShow: true,
            parent_id: 3
        },
        {
            id: 6,
            permission_key: 'manage.deposit',
            features_key: '',
            title_id: '',
            title: "รายการเงินเข้า",
            path: "/transaction-bank",
            icon: "",
            isShow: true,
            parent_id: 3
        },
        {
            id: 7,
            permission_key: 'manage.bonus',
            features_key: '',
            title_id: '',
            title: "โปรโมชั่น",
            path: "#",
            icon: "fa-duotone fa-tags",
            isShow: true,
            parent_id: 0
        },
        {
            id: 8,
            permission_key: 'manage.bonus',
            features_key: '',
            title_id: '',
            title: "โบนัส",
            path: "/promotion-bonus",
            icon: "",
            isShow: true,
            parent_id: 7
        },
        {
            id: 9,
            permission_key: 'manage.bonus',
            features_key: '',
            title_id: '',
            title: "แจกเครดิต",
            path: "/promotion-credit",
            icon: "",
            isShow: true,
            parent_id: 7
        },
        {
            id: 10,
            permission_key: 'manage.wheel',
            features_key: 'wheel',
            title_id: '',
            title: "กงล้อเสี่ยงโชค",
            path: "/promotion-wheel-luck",
            icon: "",
            isShow: true,
            parent_id: 7
        },
        {
            id: 11,
            permission_key: 'manage.redeem',
            features_key: 'redeem',
            title_id: '',
            title: "โค้ดโปรโมชั่น",
            path: "/promotion-code",
            icon: "",
            isShow: true,
            parent_id: 7
        },
        {
            id: 12,
            permission_key: 'manage.bonus',
            features_key: 'cashback',
            title_id: '',
            title: "คืนยอดเสีย",
            path: "/promotion-cashback",
            icon: "",
            isShow: true,
            parent_id: 7
        },
        {
            id: 13,
            permission_key: 'manage.bonus',
            features_key: '',
            title_id: '',
            title: "แนะนำเพื่อน",
            path: "/promotion-affiliate",
            icon: "",
            isShow: true,
            parent_id: 7
        },
        {
            id: 14,
            permission_key: 'manage.fixTx',
            features_key: '',
            title_id: '',
            title: "แก้ไขรายการผิดพลาด",
            path: "/fix-tx",
            icon: "fa-duotone fa-ballot-check",
            isShow: true,
            parent_id: 0
        },
        {
            id: 15,
            permission_key: 'manage.all',
            features_key: '',
            title_id: '',
            title: "ธนาคาร",
            path: "#",
            icon: "fa-duotone fa-university",
            isShow: true,
            parent_id: 0
        },
        {
            id: 16,
            permission_key: 'manage.bank',
            features_key: '',
            title_id: '',
            title: "บัญชีธนาคาร",
            path: "/bank",
            icon: "",
            isShow: true,
            parent_id: 15
        },
        {
            id: 17,
            permission_key: 'manage.all',
            features_key: '',
            title_id: '',
            title: "ทรูมันนี่วอลเล็ท",
            path: "/bank-true-wallet",
            icon: "",
            isShow: true,
            parent_id: 15
        },
        {
            id: 18,
            permission_key: 'manage.bank',
            features_key: '',
            title_id: '',
            title: "ตั้งค่าถอนเงินอัตโนมัติ",
            path: "/bank-auto-withdraw",
            icon: "",
            isShow: true,
            parent_id: 15
        },
        {
            id: 19,
            permission_key: 'manage.bank',
            features_key: '',
            title_id: '',
            title: "ตั้งค่าถอนเงินเข้าบัญชีกลาง",
            path: "/bank-withdraw-central",
            icon: "",
            isShow: true,
            parent_id: 15
        },
        {
            id: 20,
            permission_key: 'manage.summary',
            features_key: '',
            title_id: '',
            title: "รายงาน",
            path: "#",
            icon: "fa-duotone fa-file-chart-pie",
            isShow: true,
            parent_id: 0
        },
        {
            id: 21,
            permission_key: 'manage.summary',
            features_key: '',
            title_id: '',
            title: "สรุปข้อมูล",
            path: "/report-summary",
            icon: "",
            isShow: true,
            parent_id: 20
        },
        {
            id: 22,
            permission_key: 'manage.summary',
            features_key: '',
            title_id: '',
            title: "โบนัส",
            path: "/report-bonus",
            icon: "",
            isShow: true,
            parent_id: 20
        },
        {
            id: 23,
            permission_key: 'manage.summary',
            features_key: 'cashback',
            title_id: '',
            title: "แคชแบ็ก",
            path: "/report-cashback",
            icon: "",
            isShow: true,
            parent_id: 20
        },
        {
            id: 24,
            permission_key: 'manage.summary',
            features_key: 'wheel',
            title_id: '',
            title: "กงล้อเสี่ยงโชค",
            path: "/report-wheel-luck",
            icon: "",
            isShow: true,
            parent_id: 20
        },
        {
            id: 25,
            permission_key: 'manage.summary',
            features_key: '',
            title_id: '',
            title: "แนะนำเพื่อน",
            path: "/report-affiliate",
            icon: "",
            isShow: true,
            parent_id: 20
        },
        {
            id: 26,
            permission_key: 'manage.summary',
            features_key: 'redeem',
            title_id: '',
            title: "โค้ดโปรโมชั่น",
            path: "/report-promotion-code",
            icon: "",
            isShow: true,
            parent_id: 20
        },
        {
            id: 27,
            permission_key: 'manage.summary',
            features_key: '',
            title_id: '',
            title: "ฝากถอนรายบุคคล",
            path: "/report-transaction-individual",
            icon: "",
            isShow: true,
            parent_id: 20
        },
        {
            id: 28,
            permission_key: 'manage.users',
            features_key: '',
            title_id: '',
            title: "ผู้ใช้งาน",
            path: "/users",
            icon: "fa-duotone fa-user",
            isShow: true,
            parent_id: 0
        },
        {
            id: 29,
            permission_key: 'manage.website',
            features_key: '',
            title_id: '',
            title: "ตั้งค่าเว็บไซต์",
            path: "#",
            icon: "fa-duotone fa-wrench",
            isShow: true,
            parent_id: 0
        },
        {
            id: 30,
            permission_key: 'manage.website',
            features_key: '',
            title_id: '',
            title: "ตั้งค่า Prefix",
            path: "/setting-website",
            icon: "",
            isShow: true,
            parent_id: 29
        },
        {
            id: 31,
            permission_key: 'manage.website',
            features_key: '',
            title_id: '',
            title: "ตั้งค่าฟีเจอร์เว็บไซต์",
            path: "/setting-features",
            icon: "",
            isShow: true,
            parent_id: 29
        },
        {
            id: 32,
            permission_key: 'manage.website',
            features_key: '',
            title_id: '',
            title: "ตั้งค่า AMB Payment",
            path: "/setting-amb-payment",
            icon: "",
            isShow: true,
            parent_id: 29
        },
        {
            id: 33,
            permission_key: 'manage.website',
            features_key: '',
            title_id: '',
            title: "ตั้งค่าเกม Ambbet",
            path: "/setting-amb-games",
            icon: "",
            isShow: true,
            parent_id: 29
        },
        // {
        //     id: 36,
        //     permission_key: 'manage.website',
        //     features_key: '',
        //     title_id: '',
        //     title: "ตั้งค่าธีม",
        //     path: "/setting-theme",
        //     icon: "",
        //     isShow: true,
        //     parent_id: 29
        // },
        // {
        //     id: 33,
        //     permission_key: 'manage.all',
        // features_key: '',
        // title_id: '',
        //     title: "ภาพรวม",
        //     path: "/dashboard",
        //     icon: "fa-duotone fa-chart-pie",
        //     isShow: true,
        //     parent_id: 0
        // },
        {
            id: 34,
            permission_key: 'manage.all',
            features_key: '',
            title_id: '',
            title: "ประวัติการใช้งาน",
            path: "/logs",
            icon: "fa-duotone fa-history",
            isShow: true,
            parent_id: 0
        },
        {
            id: 35,
            permission_key: 'manage.summary',
            features_key: '',
            title_id: '',
            title: "กราฟข้อมูล",
            path: "/report-chart",
            icon: "",
            isShow: true,
            parent_id: 20
        },
    ]

    const listMenuSidebar: any = menuSidebar?.reduce((item: any, menu: any) => {
        if (menu.parent_id === 0) {
            item[menu.id] = menu
        } else {
            if (typeof item[menu.parent_id]['submenu'] === 'undefined') {
                item[menu.parent_id]['submenu'] = {}
            }
            item[menu.parent_id]['submenu'][menu.id] = menu
        }
        return item;
    }, {})

    return listMenuSidebar
}


// // i18next seems ready -> initial fill translations
// // if (i18n.isInitialized) {
// //     onLoadMenuSidebar();
// // }

// //   // reset translations to new values on language change
// i18n.on('languageChanged', function (lng) {
//     onLoadMenuSidebar();
// });

export default onLoadMenuSidebar


