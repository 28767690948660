import React, { useContext, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import ThemeProvider, { ThemeContext } from "../contexts/ThemeContext";
import ScreenProvider, { ScreenContext } from "../contexts/ScreenContext";
import Sidebar from '../components/Elements/Sidebar'
import Header from '../components/Elements/HeaderMobile'
import AuthInitContainer from '../components/Container/AuthInitContainer'
import { menuItems } from '../utils/RouteConfig'
import ScrollToTop from '../utils/ScrollToTop'
import NotifyWebSocket from '../utils/NotifyWebSocket'
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';

const AppLayoutChild = () => {
    const [toggleNavbar, setToggleNavbar] = useState(false)
    const { theme } = useContext(ThemeContext)
    const { screen } = useContext(ScreenContext)

    document.body.setAttribute('data-is-dark', (theme === 'dark') ? "true" : "false")
    document.body.setAttribute('data-is-fullscreen', (screen === 'full') ? "true" : "false")

    const { permissions } = useSelector((state: ApplicationState) => state.auth)
    const { features } = useSelector((state: ApplicationState) => state.prefix)

    NotifyWebSocket()

    return (
        <AuthInitContainer>
            <ScrollToTop />
            <div className={`app ${toggleNavbar ? 'toggle' : ''}`}>
                <div className="container">
                    <div className="d-flex align-items-start">
                        <Sidebar />
                        <div className="content">
                            <Header
                                toggleNavbar={toggleNavbar}
                                setToggleNavbar={setToggleNavbar}
                            />
                            <div className="container pb-4">
                                <Routes>
                                    {/* {menuItems.map((menuItem: any) => (
                                        <Route
                                            element={<menuItem.content />}
                                            key={menuItem.key}
                                            path={menuItem.path} />
                                    ))} */}
                                    {menuItems.map((menuItem: any, idx: number) => {
                                        if (permissions[menuItem.permission_key] || menuItem.permission_key === "manage.all" || permissions['@admin']) {
                                            if (menuItem.features_key === "" || features[menuItem.features_key] || permissions['@admin']) {
                                                return (
                                                    <Route
                                                        element={<menuItem.content />}
                                                        key={`menu-${menuItem.key}`}
                                                        path={menuItem.path} />
                                                )
                                            } else {
                                                return (
                                                    <Route
                                                        element={<Navigate to={menuItems[0]?.path} replace />}
                                                        key={`menu-${menuItem.key}`}
                                                        path={menuItems[0]?.path} />
                                                )
                                            }
                                        } else {
                                            return (
                                                <Route
                                                    element={<Navigate to={menuItems[0]?.path} replace />}
                                                    key={`menu-${menuItem.key}`}
                                                    path={menuItems[0]?.path} />
                                            )
                                        }
                                    })}
                                    <Route
                                        path="*"
                                        element={<Navigate to={menuItems[0]?.path} replace />}
                                    />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthInitContainer>
    )
}

const AppLayout = () => (
    <ThemeProvider>
        <ScreenProvider>
            <AppLayoutChild />
        </ScreenProvider>
    </ThemeProvider>
)

export default AppLayout
