import React, { FC, useEffect, useState } from 'react'
import {
    Form,
    Col,
    Row,
    Checkbox,
    InputNumber
} from 'antd'
import PatternNumber from '../../../utils/PatternNumber'

interface ConsecutiveDepositBonusProps {
    data: any
}

const ConsecutiveDepositBonus: FC<ConsecutiveDepositBonusProps> = ({ data }) => {
    const [consecutiveDepositBonusChecked, setConsecutiveDepositBonusChecked] = useState<boolean>(data?.consecutiveDepositBonus?.enable)

    useEffect(() => {
        setConsecutiveDepositBonusChecked(data?.consecutiveDepositBonus?.enable)
    }, [data]) // eslint-disable-line

    return (
        <div className="checkbox-collapse mb-2">
            <Form.Item
                name={['consecutiveDepositBonus', 'enable']}
                valuePropName="checked"
                className="mb-0"
            >
                <Checkbox onClick={(e: any) => setConsecutiveDepositBonusChecked(e.target.checked)}>
                    โบนัสฝากติดต่อกัน
                </Checkbox>
            </Form.Item>
            {consecutiveDepositBonusChecked &&
                <>
                    <Row gutter={16} className="mt-2">
                        <Col span={12}>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'consecutiveDepositDays']}
                                key="consecutiveDepositDays"
                                label="ฝากติดต่อกัน (วัน)"
                                rules={[{ required: true, message: 'กรุณากรอกฝากติดต่อกัน (วัน)' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <InputNumber placeholder="ฝากติดต่อกัน (วัน)" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'minimumDepositPerDay']}
                                key="minimumDepositPerDay"
                                label="ยอดฝากขั้นต่ำต่อวัน"
                                rules={[{ required: true, message: 'กรุณากรอกยอดฝากขั้นต่ำต่อวัน' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
                                hasFeedback>
                                <InputNumber placeholder="ยอดฝากขั้นต่ำต่อวัน" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'rules', 'noWithdraw']}
                                key="noWithdraw"
                                valuePropName="checked"
                                className="mb-0"
                            >
                                <Checkbox>
                                    ไม่มีรายการถอน
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'rules', 'noBonus']}
                                key="noBonus"
                                valuePropName="checked"
                                className="mb-0"
                            >
                                <Checkbox>
                                    ไม่มีรายการรับโบนัส
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'rules', 'noCashback']}
                                key="noCashback"
                                valuePropName="checked"
                                className="mb-0"
                            >
                                <Checkbox>
                                    ไม่มีรายการรับแคชแบ็ก
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'rules', 'noLuckyWheel']}
                                key="noLuckyWheel"
                                valuePropName="checked"
                                className="mb-0"
                            >
                                <Checkbox>
                                    ไม่มีรายการหมุนกงล้อเสี่ยงโชค
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            }
        </div>
    )
}
export default ConsecutiveDepositBonus

