import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { message, Space, Switch } from 'antd'
import moment from 'moment'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import TitleBar from '../../components/Elements/TitleBar'
import DatePickerRange from '../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../components/Elements/DateFilterTabs'
import SearchData from '../../components/Elements/Search'
import TableComponent from '../../components/Elements/TablePagination'
import BadgeComponent from '../../components/Elements/Badge'
import AvatarBankComponent from '../../components/Elements/AvatarBank'
import AvatarMemberComponent from '../../components/Elements/AvatarMember'
import EditMember from '../../components/Member/Edit'
import ViewMember from '../../components/Member/View'
import HistoryMember from '../../components/Member/History'
import ReportBetflix from '../../components/Member/Report/Betflix'
import AddFixTx from '../../components/FixTx/Add'
import useFetchMember from "../../hooks/useFetchMember"
import NumberWithCommas from '../../utils/NumberWithCommas'
import PrefixService from '../../services/prefix'
import { useSearchParams } from "react-router-dom";
import useDatePagination from '../../hooks/useDatePagination'
import AvatarRank from '../../components/Elements/AvatarRank'
import useFetchSetting from '../../hooks/useFetchSetting'

const Members = () => {

    const { data: fetchSetting } = useFetchSetting()
    const functionRank: boolean = fetchSetting?.setting.functions.rank || false
    let [searchParams] = useSearchParams();

    const [filterText, setFilterText] = useState<string>("")
    const [searchMember, setSearchMember] = useState<string>("")
    const [dataSource, setDataSource] = useState<any>([])
    const [switchLoading, setSwitchLoading] = useState<boolean>(false)
    // const { dateRange, setDateRange } = useDateRange()
    const [dateRange, setDateRange] = useState({
        start: "",
        end: "",
    })
    const { pagination, setPagination } = useDatePagination()
    const { data, mutate, isLoading } = useFetchMember(dateRange, searchMember, { page: pagination.page, limit: pagination.pageSize })

    const [editDrawer, setEditDrawer] = useState(false)
    const toggleEdit = () => setEditDrawer(!editDrawer)

    const [addFixTx, setAddFixTx] = useState(false)
    const toggleFixTx = () => setAddFixTx(!addFixTx)


    const [viewDrawer, setViewDrawer] = useState(false)
    const toggleView = () => setViewDrawer(!viewDrawer)


    const [historyDrawer, setHistoryDrawer] = useState(false)
    const toggleHistory = () => setHistoryDrawer(!historyDrawer)

    const [betflixDrawer, setBetflixDrawer] = useState(false)
    const toggleBetflix = () => setBetflixDrawer(!betflixDrawer)

    const [memberData, setMemberData] = useState<any>()
    const handleMember = (object: any) => setMemberData(object)

    const handleStatus = async (status: boolean, id: any) => {
        if (status) {
            try {
                const res = await PrefixService.setMemberActive({
                    id: id,
                    status: status,
                    remark: ""
                })
                mutate()
                toast.success(res.message)
            } catch ({ message }) {
                toast.error(message as string)
            }
        } else {
            Swal.fire({
                title: `คุณต้องการปรับสถานะบัญชี #${id}?`,
                text: `ตรวจสอบให้แน่ใจว่าคุณต้องการปรับสถานะบัญชี #${id}`,
                input: 'text',
                inputLabel: 'หมายเหตุ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ตกลง'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const res = await PrefixService.setMemberActive({
                            id: id,
                            status: status,
                            remark: result.value
                        })
                        mutate()
                        toast.success(res.message)
                    } catch ({ message }) {
                        toast.error(message as string)
                    }
                }
            })
        }
        setSwitchLoading(false)
    };

    const columns: any = [
        {
            title: 'ชื่อ - นามสกุล',
            dataIndex: 'name',
            width: '160px',
            render: (text: any, record: any) => (
                <Space>
                    {functionRank && (
                        <AvatarRank rank={record.rank.name || "Bronze"} point={record.rank.point} />
                    )}
                    <Space direction="vertical" size={0}>
                        <div className="text-link" onClick={() => {
                            handleMember(record)
                            toggleView()
                        }}>
                            <AvatarMemberComponent
                                fullname={`${record.firstName} ${record.lastName}`}
                                phone={''}
                                search={false} />
                        </div>
                        <div className="text-link">
                            <div className="row-member">
                                <div className="row-member-phone" onClick={() => copyData(record.phone)}>
                                    <i className="fa-solid fa-phone-square me-2"></i>
                                    <span>{record.phone}</span>
                                </div>
                            </div>
                        </div>
                    </Space>
                </Space>
            )
        },
        {
            title: 'บัญชีธนาคาร',
            dataIndex: 'bank',
            render: (text: any, record: any) => (
                <AvatarBankComponent
                    bankCode={record.bankCode}
                    bankNumber={record.bankNumber}
                />
            )
        },
        {
            title: 'สถานะสมาชิก',
            dataIndex: 'status',
            render: (text: any, record: any) => (
                <>
                    {record.status ? (
                        <BadgeComponent className="badge-info" text='สมาชิกทั่วไป' />
                    ) : (
                        <BadgeComponent className="badge-danger" text='ระงับการใช้งาน' />
                    )}
                </>
            )
        },
        {
            title: 'สถานะบัญชี',
            dataIndex: 'status',
            width: '90px',
            render: (text: any, record: any) => {
                return (
                    <>
                        <Space direction="vertical">
                            <Switch
                                checkedChildren="เปิด"
                                unCheckedChildren="ปิด"
                                checked={record.status}
                                loading={switchLoading}
                                onChange={(e) => {
                                    setSwitchLoading(true)
                                    handleStatus(e, record.id)
                                }}
                            />
                            {record.remark && !record.status && <div className="msn-badge badge-light mt-2">{record.remark}</div>}
                        </Space>
                    </>
                )
            }
        },
        {
            title: 'วันที่สมัคร',
            dataIndex: 'createdAt',
            width: '140px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'จัดการ',
            dataIndex: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space size={0}>
                        {fetchSetting && fetchSetting.setting.game.toUpperCase() === "BETFLIX" &&
                            <button
                                className="btn btn-primary btn-sm text-white me-2"
                                onClick={() => {
                                    handleMember(record)
                                    toggleBetflix()
                                }}><i className="fa-solid fa-clock-rotate-left me-1"></i>ประวัติการการเล่น</button>
                        }
                        <button
                            className="btn btn-info btn-sm text-white me-2"
                            onClick={() => {
                                handleMember(record)
                                toggleHistory()
                            }}><i className="fa-solid fa-clock-rotate-left me-1"></i>ประวัติการทำรายการ</button>
                        <button
                            className="btn btn-success btn-sm text-white me-2"
                            onClick={() => {
                                handleMember(record)
                                toggleFixTx()
                            }}><i className="fa-solid fa-circle-dollar me-1"></i>จัดการเครดิต</button>
                        <button
                            className="btn btn-warning btn-sm text-white me-2"
                            onClick={() => {
                                handleMember(record)
                                toggleEdit()
                            }}><i className="fa-solid fa-edit me-1"></i>แก้ไข</button>
                    </Space>
                </>
            )
        }
    ]

    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.data.length > 0) {
            // const filteredItems = (data as any).filter(
            //     (item: any) => (
            //         (item.firstName && item.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.lastName && item.lastName.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.phone && item.phone.toLowerCase().includes(filterText.toLowerCase()))
            //     ),
            // );
            setDataSource(data.data)
        }
    }, [data, isLoading, setDataSource])

    useMemo(() => {
        setFilterText("")
        setSearchMember("")
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    useMemo(() => {
        if (searchParams.get("search")) {
            setFilterText(searchParams.get("search") || "")
            setSearchMember(searchParams.get("search") || "")
            mutate()
        }
    }, [searchParams, setSearchMember])// eslint-disable-line

    const handleSearch = () => {
        setSearchMember(filterText)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        // 
        // mutate()
    }

    const copyData = (text: any) => {
        navigator.clipboard.writeText(`${text}`)
        message.success('คัดลอกข้อมูลสำเร็จ')
    }


    return (
        <Fragment>
            <TitleBar title="สมาชิก" subTitle={`${NumberWithCommas(data?.pagination.Count)} คน`} />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} funClear={true} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            <div className="d-flex justify-content-start mb-4">
                <SearchData onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">ค้นหา</button>
            </div>
            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.pagination.Count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>

            <EditMember
                member={memberData as any}
                isOpen={editDrawer}
                close={() => toggleEdit()}
                onloadData={() => mutate()}
            />

            <ViewMember
                member={memberData as any}
                isOpen={viewDrawer}
                close={() => toggleView()}
                title={`ข้อมูลสมาชิก #${memberData?.id}`}
            />

            <HistoryMember
                data={memberData as any}
                isOpen={historyDrawer}
                close={() => toggleHistory()}
            />

            <AddFixTx
                isOpen={addFixTx}
                close={() => toggleFixTx()}
                onloadData={mutate}
                phone={memberData?.phone}
            />
            {fetchSetting && fetchSetting.setting.game.toUpperCase() === "BETFLIX" && (
                betflixDrawer &&
                <ReportBetflix
                    isOpen={betflixDrawer}
                    close={() => toggleBetflix()}
                    member={memberData}
                />
            )}
        </Fragment>
    )
}

export default Members