import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Select,
    Divider,
    Space,
    InputNumber,
    // Switch
} from 'antd'
import { toast } from 'react-toastify'
import PatternNumber from '../../utils/PatternNumber'
import { bankNames } from '../../utils/bank'
import PrefixService from '../../services/prefix'

interface EditMemberProps {
    close: () => void
    isOpen: boolean
    onloadData: () => void

    member: any

}

const EditMember: FC<EditMemberProps> = ({ close, isOpen, onloadData, member }) => {
    const [form] = Form.useForm();
    const title = "แก้ไข / ตั้งค่าสมาชิก"
    const { Option } = Select
    const [btnLoading, setBtnLoading] = useState(false)
    const [loadingBankAccount, setLoadingBankAccount] = useState(false)

    const getDataBank = async (id: number) => {
        try {
            setLoadingBankAccount(true)
            const responseMemberBankAccount = await PrefixService.getMemberBankAccount(id).then()
            form.setFieldsValue({ 'firstName': responseMemberBankAccount?.firstName, 'lastName': responseMemberBankAccount?.lastName })
            setLoadingBankAccount(false)
        } catch ({ message }) {
            toast.error(message as string)
            setLoadingBankAccount(false)
        }
    }

    const optionBankNames = Object.keys(bankNames).map((key: any) => {
        return <Option key={key} value={key}>{bankNames[key]}</Option>
    })

    const updateData = async (values: any) => {
        try {
            const res = await PrefixService.updateMemberInfo(values)
            setBtnLoading(false)
            onloadData()
            toast.success(res.message)
            close()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = (member: any) => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                member.phone = values.phone
                member.twID = values.twID
                member.firstName = values.firstName
                member.lastName = values.lastName
                member.firstNameEN = values.firstNameEN
                member.lastNameEN = values.lastNameEN
                member.bankCode = values.bankCode
                member.bankNumber = values.bankNumber
                member.affiliate.balance = values.affiliate.balance
                member.totalTurn = values.totalTurn
                member.minWithdraw = values.minWithdraw
                member.maxWithdraw = values.maxWithdraw
                // member.status = values.status
                // member.remark = values.remark
                if (values.gameUsername) {
                    member.gameUsername = values.gameUsername
                }
                if (values.password) {
                    member.password = values.password
                }
                if (values.gamePassword) {
                    member.gamePassword = values.gamePassword
                }
                updateData(member)

            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
            form.setFieldsValue(member);
        }
    }, [form, isOpen, member]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={() => handleFormSubmit(member)}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="editMember"
                    layout="vertical"
                    hideRequiredMark>
                    <Row>
                        <Col span={24}>
                            <h6 className="mb-3">ข้อมูลสมาชิก</h6>
                            {/* <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="status"
                                        label="สถานะ"
                                        valuePropName="checked">
                                        <Switch
                                            checkedChildren="เปิด"
                                            unCheckedChildren="ปิด" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="remark"
                                        label="หมายเหตุ"
                                        rules={[
                                            { required: false, message: 'กรุณากรอกหมายเหตุ' }
                                        ]}>
                                        <Input placeholder="หมายเหตุ" inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="phone"
                                        label="เบอร์โทรศัพท์"
                                        rules={[
                                            { required: false, message: 'กรุณากรอกเบอร์โทรศัพท์' },
                                            { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                        ]}>
                                        <Input placeholder="เบอร์โทรศัพท์" inputMode="tel" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="twID"
                                        label="Truewallet ID"
                                        rules={[{ required: false, message: 'กรุณากรอกTruewallet ID' }]}>
                                        <Input placeholder="Truewallet ID" inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="firstName"
                                        label="ชื่อ"
                                        rules={[{ required: true, message: 'กรุณากรอกชื่อ' }]}
                                        hasFeedback>
                                        <Input placeholder="ชื่อ" inputMode="text" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="lastName"
                                        label="นามสกุล"
                                        rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
                                        hasFeedback>
                                        <Input placeholder="นามสกุล" inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="firstNameEN"
                                        label="ชื่อ (ภาษาอังกฤษ)"
                                        rules={[{ required: false, message: 'กรุณากรอกชื่อ (ภาษาอังกฤษ)' }]}>
                                        <Input placeholder="ชื่อ (ภาษาอังกฤษ)" inputMode="text" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="lastNameEN"
                                        label="นามสกุล (ภาษาอังกฤษ)"
                                        rules={[{ required: false, message: 'กรุณากรอกนามสกุล (ภาษาอังกฤษ)' }]}>
                                        <Input placeholder="นามสกุล (ภาษาอังกฤษ)" inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Button
                                    type="primary"
                                    loading={loadingBankAccount}
                                    size="large"
                                    className="mb-4"
                                    onClick={() => getDataBank(member?.id)}>
                                    ดึงข้อมูลจากบัญชีธนาคาร
                                </Button>
                            </Row>
                            <Divider className="mt-2 mb-4" />
                            <h6 className="mb-3">ตั้งค่า Game Username</h6>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="gameUsername"
                                        label="Game Username"
                                        rules={[{ required: false, message: 'กรุณากรอก Game Username"' }]}>
                                        <Input placeholder="Game Username" inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider className="mt-2 mb-4" />
                            <h6 className="mb-3">ตั้งค่ารหัสผ่าน</h6>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="password"
                                        label="รหัสผ่าน"
                                        rules={[{ required: false, message: 'กรุณากรอกรหัสผ่าน' }]}>
                                        <Input placeholder="รหัสผ่าน" inputMode="text" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="gamePassword"
                                        label="รหัสผ่านสำหรับเปิดยูซเซอร์ใหม่"
                                        rules={[{ required: false, message: 'กรุณากรอกรหัสผ่านสำหรับเปิดยูซเซอร์ใหม่' }]}>
                                        <Input placeholder="รหัสผ่านสำหรับเปิดยูซเซอร์ใหม่" inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider className="mt-2 mb-4" />
                        </Col>
                        <Col span={24}>
                            <h6 className="mb-3">ข้อมูลธนาคาร</h6>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="bankCode"
                                        label="ธนาคาร"
                                        rules={[{ required: true, message: 'กรุณาเลืิอกธนาคาร' }]}
                                        hasFeedback
                                    >
                                        <Select
                                            placeholder="เลือกธนาคาร"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {optionBankNames}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="bankNumber"
                                        label="เลขที่บัญชี"
                                        rules={[{ required: true, message: 'กรุณากรอกเลขที่บัญชี' }]}
                                        hasFeedback
                                    >
                                        <Input placeholder="เลขที่บัญชี" inputMode="numeric" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider className="mt-2 mb-4" />
                            <h6 className="mb-3">ตั้งค่าเทิร์น</h6>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name={['affiliate', 'balance']}
                                        label="ยอดเชิญเพื่อน"
                                        rules={[
                                            { required: true, message: 'กรุณากรอกยอดเชิญเพื่อน' },
                                            { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                        ]}
                                        hasFeedback
                                    >
                                        <InputNumber placeholder="ยอดเชิญเพื่อน" inputMode="numeric" controls={false} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="totalTurn"
                                        label="เทิร์นที่ต้องทำ"
                                        rules={[
                                            { required: true, message: 'กรุณากรอกเทิร์นที่ต้องทำ' },
                                            { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                        ]}
                                        hasFeedback
                                    >
                                        <InputNumber placeholder="เทิร์นที่ต้องทำ" inputMode="numeric" controls={false} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="minWithdraw"
                                        label="ยอดถอนขั้นต่ำ"
                                        rules={[
                                            { required: true, message: 'กรุณากรอกยอดถอนขั้นต่ำ' },
                                            { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                        ]}
                                        hasFeedback
                                    >
                                        <InputNumber placeholder="ยอดถอนขั้นต่ำ" inputMode="numeric" controls={false} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="maxWithdraw"
                                        label="ยอดถอนสูงสุด"
                                        rules={[
                                            { required: true, message: 'กรุณากรอกยอดถอนสูงสุด' },
                                            { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                        ]}
                                        hasFeedback
                                    >
                                        <InputNumber placeholder="ยอดถอนสูงสุด" inputMode="numeric" controls={false} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={() => handleFormSubmit(member)} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                            <Button onClick={close} size="large">ยกเลิก</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditMember

