import React, { Fragment, useEffect, useState, useMemo } from 'react'
import TitleBar from '../../../components/Elements/TitleBar'
import DatePickerRange from '../../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../../components/Elements/DateFilterTabs'
import TableComponent from '../../../components/Elements/TablePagination'
import AvatarMemberComponent from '../../../components/Elements/AvatarMember'
import moment from 'moment'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import useDateRange from "../../../hooks/useDateRange"
import useFetchReport from '../../../hooks/useFetchReport'
import { WheelSummaryItem } from '../../../@types/report'
import useDatePagination from '../../../hooks/useDatePagination'

const ReportWheelLuck = () => {
    const { dateRange, setDateRange } = useDateRange()

    const [dataSource, setDataSource] = useState<any>([])
    const { pagination, setPagination } = useDatePagination()
    const { data, mutate, isLoading } = useFetchReport<WheelSummaryItem[]>('wheel', dateRange, 10000, { page: pagination.page, limit: pagination.pageSize })

    const columns: any = [
        {
            title: 'เวลา',
            dataIndex: 'createdAt',
            width: '140px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'สมาชิก',
            dataIndex: 'name',
            render: (text: any, record: any) => (
                <>
                    <AvatarMemberComponent
                        fullname={`${record.member.firstName} ${record.member.lastName}`}
                        phone={record.member.phone}
                        search={true}
                    />
                </>
            )
        },
        {
            title: 'จำนวนเงิน',
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.amount, 2)}
                </div>
            )
        },
        {
            title: 'ยอดเทิร์น',
            dataIndex: 'totalTurn',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.totalTurn, 2)}
                </div>
            )
        },
        {
            title: 'เทิร์น',
            dataIndex: 'turn',
            render: (text: any, record: any) => (
                <>
                    {record.turn} เท่า
                </>
            )
        }
    ]

    useEffect(() => {
        setDataSource([])
        if (data !== undefined) {
            setDataSource(data.data)
        }
        // setLoading(isLoading)
    }, [data, isLoading, setDataSource])

    useMemo(() => {
        // setLoading(true)
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    return (
        <Fragment>
            <TitleBar title="รายงานการหมุนกงล้อเสี่ยงโชค" subTitle={`${NumberWithCommas(data?.pagination.Count)} รายการ`} />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>

            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    totalCount={data?.pagination.Count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>
        </Fragment>
    )
}

export default ReportWheelLuck