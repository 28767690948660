import React, { FC, useState, useEffect, useMemo } from 'react'
import { Row, Col } from 'antd'
import Chart from 'react-apexcharts'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import useFetchReport from '../../../hooks/useFetchReport'
import { withdrawSummaryItem } from '../../../@types/report'
import Loading from '../../Elements/Loading'


interface WithdrawTabProps {
    dateRange: any
}

const WithdrawTab: FC<WithdrawTabProps> = ({ dateRange }) => {
    const [amountHours, setAmountHours] = useState([])
    const [countHours, setCountHours] = useState([])

    // Withdraw
    const AmountOfWithdrawBarChart = {
        options: {
            series: [{
                name: 'จำนวนเงิน',
                data: amountHours,
                color: '#dc3545',
            }],
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            colors: ['#52D4BA', '#FF4267'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false,
            },
            tooltip: {
                theme: 'apexcharts-tooltip',
                x: {
                    formatter: function (val: any) {
                        return 'เวลา ' + val + ' น.'
                    },
                },
                y: {
                    formatter: function (val: any) {
                        return NumberWithCommas(val, 2) + ' บาท'
                    },
                }
            },
            xaxis: {
                categories: [
                    '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
                    '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00',
                    '22:00', '23:00',
                ],
                labels: {
                    rotate: -60,
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                }
            },
            yaxis: {
                labels: {
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                    formatter: function (val: any) {
                        return NumberWithCommas(val, 0)
                    },
                }
            },
            grid: {
                borderColor: 'var(--line)',
            },
            legend: {
                labels: {
                    colors: ['var(--text-secondary)']
                }
            }
        }
    };

    const CountOfWithdrawBarChart = {
        options: {
            series: [{
                name: 'จำนวน',
                data: countHours,
                color: '#dc3545',
            }],
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            colors: ['#52D4BA', '#FF4267'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false,
            },
            tooltip: {
                theme: 'apexcharts-tooltip',
                x: {
                    formatter: function (val: any) {
                        return 'เวลา ' + val + ' น.'
                    },
                },
                y: {
                    formatter: function (val: any) {
                        return NumberWithCommas(val) + ' ครั้ง'
                    },
                }
            },
            xaxis: {

                categories: [
                    '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
                    '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00',
                    '22:00', '23:00',
                ],
                labels: {
                    rotate: -60,
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                }
            },
            yaxis: {
                labels: {
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                }
            },
            grid: {
                borderColor: 'var(--line)',
            },
            legend: {
                labels: {
                    colors: ['var(--text-secondary)']
                }
            }
        }
    };

    const { data: dataWithdraw, mutate: mutateWithdraw, isLoading } = useFetchReport<withdrawSummaryItem>('withdraw', dateRange)

    useEffect(() => {
        if (dataWithdraw !== undefined) {
            if (Object.keys(dataWithdraw).length > 0) {
                setAmountHours(Object.values(dataWithdraw.amountHours))
                setCountHours(Object.values(dataWithdraw.countHours))
            }
        }
    }, [dataWithdraw, isLoading])

    useMemo(() => {
        mutateWithdraw()
    }, [dateRange])// eslint-disable-line


    return (
        <>
            {/* Withdraw */}
            <Row gutter={[16, 16]} className="mb-5">
                <Col span={24}>
                    <h4>รายงานการถอน</h4>
                </Col>

                <Col xs={24} sm={12}>
                    <div className="box-white ant-box-table p-md-2 pt-3 pt-md-4 pb-md-0 mb-2 mb-lg-0">
                        <p className="text-center mb-0">จำนวนการถอนเงินรายชั่วโมง (จำนวนเงิน)</p>
                        {isLoading ? <Loading /> :
                            <Chart
                                type="bar"
                                options={AmountOfWithdrawBarChart.options as object}
                                series={AmountOfWithdrawBarChart.options.series}
                            />
                        }
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="box-white ant-box-table p-md-2 pt-3 pt-md-4 pb-md-0 mb-2 mb-lg-0">
                        <p className="text-center mb-0">จำนวนการถอนเงินรายชั่วโมง (ครั้ง)</p>
                        {isLoading ? <Loading /> :
                            <Chart
                                type="bar"
                                options={CountOfWithdrawBarChart.options as object}
                                series={CountOfWithdrawBarChart.options.series}
                            />
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default WithdrawTab