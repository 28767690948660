export const statusTransaction: Record<string, string> = {
    all: 'ทั้งหมด',
    pending: 'รอดำเนินการ',
    success: 'รายการสำเร็จ',
    reject: 'รายการไม่สำเร็จ',
    member_notfound: 'ไม่พบสมาชิก',
    duplicate: 'รายการซ้ำ',
}


export const statusTransactionWithdraw: Record<string, string> = {
    all: 'ทั้งหมด',
    pending: 'รอดำเนินการ',
    queueing: 'กำลังดำเนินการ',
    success: 'รายการสำเร็จ',
    reject: 'รายการไม่สำเร็จ',
}

export const statusTransactionDeposit: Record<string, string> = {
    all: 'ทั้งหมด',
    pending: 'รอดำเนินการ',
    checking: 'รอตรวจสอบ',
    bank_duplicate: 'รายการฝากผิดพลาด',
    success: 'รายการสำเร็จ',
}