import React, { FC } from 'react'
import moment from 'moment'

interface DateFilterTabsProps {
    onChangeDate: (e: any) => void
    onDateActive: any
}

const DateFilterTabs: FC<DateFilterTabsProps> = ({ onChangeDate, onDateActive }) => {
    const sDateActive = moment(onDateActive?.start).format('YYYY-MM-DD');
    const eDateActive = moment(onDateActive?.end).format('YYYY-MM-DD');

    let activeClass: string = "";
    switch (`${sDateActive}-${eDateActive}`) {
        case `${moment().format('YYYY-MM-DD')}-${moment().format('YYYY-MM-DD')}`:
            activeClass = "today"
            break;

        case `${moment().subtract(1, 'days').format('YYYY-MM-DD')}-${moment().subtract(1, 'days').format('YYYY-MM-DD')}`:
            activeClass = "yesterday"
            break;

        case `${moment().startOf('isoWeek').format('YYYY-MM-DD')}-${moment().endOf('isoWeek').format('YYYY-MM-DD')}`:
            activeClass = "this_week"
            break;

        case `${moment().startOf('month').format('YYYY-MM-DD')}-${moment().endOf('month').format('YYYY-MM-DD')}`:
            activeClass = "this_month"
            break;

        default:
            break;
    }

    const onDateSearch = (date: any) => {
        if (date !== null) {
            onChangeDate && onChangeDate({
                start: moment(date[0]).format('YYYY-MM-DD'),
                end: moment(date[1]).format('YYYY-MM-DD'),
            })
        }
    }

    return (
        <ul className="nav nav-pills nav-tabs">
            <li className="nav-item" key="today">
                <div className={"nav-link " + (activeClass === 'today' && 'active')} onClick={() => onDateSearch([moment(), moment()])} >วันนี้</div>
            </li>
            <li className="nav-item" key="yesterday">
                <div className={"nav-link " + (activeClass === 'yesterday' && 'active')} onClick={() => onDateSearch([moment().subtract(1, 'days'), moment().subtract(1, 'days')])} >เมื่อวานนี้</div>
            </li>
            <li className="nav-item" key="this_week">
                <div className={"nav-link " + (activeClass === 'this_week' && 'active')} onClick={() => onDateSearch([moment().startOf('isoWeek'), moment().endOf('isoWeek')])} >สัปดาห์นี้</div>
            </li>
            <li className="nav-item" key="this_month">
                <div className={"nav-link " + (activeClass === 'this_month' && 'active')} onClick={() => onDateSearch([moment().startOf('month'), moment().endOf('month')])} >เดือนนี้</div>
            </li>
        </ul>
    )
}

export default DateFilterTabs