import React, { FC, useState, useEffect, useMemo } from 'react'
import { Row, Col } from 'antd'
import Chart from 'react-apexcharts'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import useFetchReport from '../../../hooks/useFetchReport'
import { registerSummaryItem } from '../../../@types/report'
import Loading from '../../Elements/Loading'


interface MemberTabProps {
    dateRange: any
}

const MemberTab: FC<MemberTabProps> = ({ dateRange }) => {
    const [knowFrom, setKnowFrom] = useState([])
    const [knowFromLebel, setKnowFromLebel] = useState([])
    const [knowFromLebelColor, setKnowFromLebelColor] = useState([])
    const [countHours, setCountHours] = useState([])

    // Register
    const RegisterPieChart = {
        options: {
            series: knowFrom,
            labels: knowFromLebel,
            colors: knowFromLebelColor,
            chart: {
                dropShadow: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    top: 5,
                    left: 0,
                    blur: 10,
                    color: '#8090d0',
                    opacity: 0.2
                }
            },
            legend: {
                position: 'bottom',
                fontFamily: 'Roboto, Noto Sans Thai, sans-serif',
            },
            stroke: {
                show: false
            },
            fill: {
                colors: knowFromLebelColor,
            },
            tooltip: {
                style: {
                    fontSize: '14px',
                    fontFamily: 'Roboto, Noto Sans Thai, sans-serif',
                },
                y: {
                    formatter: function (val: any) {
                        return NumberWithCommas(val) + ' คน'
                    },
                }
            }
        },
    };

    const RegisterBarChart = {
        options: {
            series: [{
                name: 'จำนวน',
                data: countHours,
                color: '#ffc602',
            }],
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            colors: ['#52D4BA', '#FF4267'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false,
            },
            tooltip: {
                theme: 'apexcharts-tooltip',
                x: {
                    formatter: function (val: any) {
                        return 'เวลา ' + val + ' น.'
                    },
                },
                y: {
                    formatter: function (val: any) {
                        return NumberWithCommas(val) + ' คน'
                    },
                }
            },
            xaxis: {

                categories: [
                    '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
                    '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00',
                    '22:00', '23:00',
                ],
                labels: {
                    rotate: -60,
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                }
            },
            yaxis: {
                labels: {
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                }
            },
            grid: {
                borderColor: 'var(--line)',
            },
            legend: {
                labels: {
                    colors: ['var(--text-secondary)']
                }
            }
        }
    };


    const { data: dataRegister, mutate: mutateRegister, isLoading } = useFetchReport<registerSummaryItem>('register', dateRange)

    useEffect(() => {
        if (dataRegister !== undefined && Object.keys(dataRegister).length > 0) {

            const knowFromKey: any = ['FACEBOOK', 'LINE', 'GOOGLE', 'INSTAGRAM', 'YOUTUBE', 'TIKTOK', 'ONLYFANS', 'TELEGRAM', 'VK', 'FRIEND', 'OTHER']
            const knowFromColor: any = ['#2374e1', '#40bf05', '#db4437', '#dd2a7b', '#e62117', '#69c9d0', '#00A3F0', '#0088cc', '#1a4b78', '#fd7e14', '#000']
            setKnowFromLebel(knowFromKey)
            setKnowFromLebelColor(knowFromColor)
            const knowFromObject: any = dataRegister.knowFrom
            const knowFrom: any = [
                knowFromObject?.facebook || 0,
                knowFromObject?.line || 0,
                knowFromObject?.google || 0,
                knowFromObject?.instagram || 0,
                knowFromObject?.youtube || 0,
                knowFromObject?.tiktok || 0,
                knowFromObject?.onlyfans || 0,
                knowFromObject?.telegram || 0,
                knowFromObject?.vk || 0,
                knowFromObject?.friend || 0,
                knowFromObject?.other || 0
            ];

            setKnowFrom(knowFrom)

            setCountHours(Object.values(dataRegister.countHours))
        }
    }, [dataRegister, isLoading])

    useMemo(() => {
        mutateRegister()
    }, [dateRange])// eslint-disable-line

    return (
        <>
            {/* Register */}
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <h4>รายงานการสมัครสมาชิก</h4>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="box-white ant-box-table p-3 p-lg-4 mb-2 mb-lg-0">
                        <p className="text-center mb-0">ช่องทางสมัครสมาชิก (จำนวนสมาชิก)</p>
                        {isLoading ? <Loading /> :
                            <Chart
                                type="pie"
                                options={RegisterPieChart.options as object}
                                series={RegisterPieChart.options.series}
                            />
                        }
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="box-white ant-box-table p-md-2 pt-3 pt-md-4 pb-md-0 mb-2 mb-lg-0">
                        <p className="text-center mb-0">จำนวนสมัครสมาชิกรายชั่วโมง (จำนวนสมาชิก)</p>
                        {isLoading ? <Loading /> :
                            <Chart
                                type="bar"
                                options={RegisterBarChart.options as object}
                                series={RegisterBarChart.options.series}
                            />
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default MemberTab