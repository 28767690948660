import React, { FC, useEffect, useState } from 'react'
import {
    Form,
    Col,
    Row,
    Checkbox,
    DatePicker
} from 'antd'

interface NoDepositBetweenProps {
    data: any
}

const NoDepositBetween: FC<NoDepositBetweenProps> = ({ data }) => {
    const [noDepositBetweenChecked, setNoDepositBetweenChecked] = useState<boolean>(data?.noDepositBetween?.enable)
    const dateFormat = 'DD/MM/YYYY'

    useEffect(() => {
        setNoDepositBetweenChecked(data?.noDepositBetween?.enable)
    }, [data]) // eslint-disable-line

    return (
        <div className="checkbox-collapse mb-4">
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name={['noDepositBetween', 'enable']}
                        valuePropName="checked"
                                className="mb-0"
                            >
                        <Checkbox onClick={(e: any) => setNoDepositBetweenChecked(e.target.checked)}>
                            ไม่มียอดฝาก
                        </Checkbox>
                    </Form.Item>
                    {noDepositBetweenChecked &&
                        <>
                            <Row gutter={16} className="mt-2">
                                <Col span={12}>
                                    <Form.Item
                                        name={['noDepositBetween', 'startDate']}
                                        key="startDate"
                                        label="วันที่เริ่มต้น"
                                        rules={[{ required: true, message: 'กรุณากรอกวันที่เริ่มต้น' }]}
                                        hasFeedback>
                                        <DatePicker placeholder="วันที่เริ่มต้น" format={dateFormat} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={['noDepositBetween', 'endDate']}
                                        key="endDate"
                                        label="วันที่สิ้นสุด"
                                        rules={[{ required: true, message: 'กรุณากรอกวันที่สิ้นสุด' }]}
                                        hasFeedback>
                                        <DatePicker placeholder="วันที่สิ้นสุด" format={dateFormat} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name={['noDepositBetween', 'rules', 'noWithdraw']}
                                        key="noWithdraw"
                                        valuePropName="checked"
                                className="mb-0"
                            >
                                        <Checkbox>
                                            ไม่มีรายการถอน
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name={['noDepositBetween', 'rules', 'noBonus']}
                                        key="noBonus"
                                        valuePropName="checked"
                                className="mb-0"
                            >
                                        <Checkbox>
                                            ไม่มีรายการรับโบนัส
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name={['noDepositBetween', 'rules', 'noCashback']}
                                        key="noCashback"
                                        valuePropName="checked"
                                className="mb-0"
                            >
                                        <Checkbox>
                                            ไม่มีรายการรับแคชแบ็ก
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name={['noDepositBetween', 'rules', 'noLuckyWheel']}
                                        key="noLuckyWheel"
                                        valuePropName="checked"
                                className="mb-0"
                            >
                                        <Checkbox>
                                            ไม่มีรายการหมุนกงล้อเสี่ยงโชค
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
            </Row>
        </div>
    )
}
export default NoDepositBetween

