import React, { FC, Fragment, useState, useEffect, useMemo } from 'react'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { Space, Tooltip, Button, Select } from 'antd'
import { UndoOutlined } from '@ant-design/icons'
import moment from 'moment'
import TitleBar from '../../../components/Elements/TitleBar'
import DatePickerRange from '../../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../../components/Elements/DateFilterTabs'
import TableComponent from '../../../components/Elements/TablePagination'
import StatusBadge from '../../../components/Elements/StatusBadge'
import AvatarBankComponent from '../../../components/Elements/AvatarBank'
import AvatarMemberComponent from '../../../components/Elements/AvatarMember'
import SlipModal from '../../../components/Slip/SlipModal'
import SearchData from '../../../components/Elements/Search'
import { WithdrawTransaction } from '../../../@types/transactions'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import { statusTransactionWithdraw } from '../../../utils/StatusTransaction'
import useDateRange from "../../../hooks/useDateRange"
import useFetchTransactionList from '../../../hooks/useFetchTransactionList'
import PrefixService from '../../../services/prefix'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../store'
import useDatePagination from '../../../hooks/useDatePagination'



export interface WithdrawActionModal {
    type: string
    tx: WithdrawTransaction
}

interface WithdrawActionsProps {
    tx: WithdrawTransaction

    setActionModal: (v: string, d: any) => void //WithdrawActionModal
}
const WithdrawActions: FC<WithdrawActionsProps> = ({ tx, setActionModal }) => (
    <>
        <button className="btn btn-warning btn-sm text-white me-2" onClick={() => setActionModal('confirm', tx)}>ปรับสถานะ</button>
        <button className="btn btn-success btn-sm text-white me-2" onClick={() => setActionModal('transfer', tx)}>ยืนยัน</button>
        <button className="btn btn-danger btn-sm text-white me-2" onClick={() => setActionModal('reject', tx)}>ยกเลิก</button>
    </>
)
const WithdrawActionsReject: FC<WithdrawActionsProps> = ({ tx, setActionModal }) => (
    <>
        <div className="me-2">
            {/* <StatusBadge status={"reject"} /> */}
            <button className="btn btn-warning btn-sm text-white ms-2" onClick={() => setActionModal('confirm', tx)}>ปรับสถานะ</button>
        </div>
    </>
)
// interface WithdrawSlipActionsProps {
//     tx: WithdrawTransaction
// }

// export const WithdrawSlipActions: FC<WithdrawSlipActionsProps> = ({ tx }) => (
//     <button className="btn btn-info btn-sm text-white me-2"
//         onClick={() => {
//             handleViewData(row)
//             toggleViewSlip()
//         }}
//     // onClick={() => {
//     //     if (tx.withdraw.qrString) {
//     //         window.open(
//     //             `https://chart.apis.google.com/chart?cht=qr&chs=256x256&chl=${tx.withdraw.qrString}`,
//     //             '_blank'
//     //         )
//     //     } else {
//     //         window.open(
//     //             `https://msn.cdnbet.co/withdraw/${PrefixService.getPrefix()}/${tx.withdraw.transactionID
//     //             }.png`,
//     //             '_blank'
//     //         )
//     //     }
//     // }}
//     >สลิปโอนเงิน</button>
// )

const TransactionWithdraw = () => {
    const { dateRange, setDateRange } = useDateRange()
    const [dataSource, setDataSource] = useState<any>([])
    const [filterText, setFilterText] = useState('')
    const [searchAlldata, setSearchAllData] = useState<string>("")
    const { Option } = Select;
    const [status, setStatus] = useState('all')

    const [viewSlipModal, setViewSlipModal] = useState(false)
    const [transaction, setTransaction] = useState({})
    const toggleViewSlip = () => setViewSlipModal(!viewSlipModal)

    const { permissions } = useSelector((state: ApplicationState) => state.auth)
    // const namePrefix = PrefixService.getPrefix()
    const { pagination, setPagination } = useDatePagination()
    const { data, mutate, isLoading } = useFetchTransactionList<WithdrawTransaction>(`/withdraw`, {
        dateRange: dateRange,
        type: 'withdraw',
        status: status !== 'all' ? status : '',
        search: searchAlldata
    }, 10000, { page: pagination.page, limit: pagination.pageSize })

    const handleCheckSlip = (values: any) => {
        if (values.transactionLog) {
            setTransaction(values)
            toggleViewSlip()
        } else {
            if (values.withdraw.qrString) {
                Swal.fire({
                    title: 'สลิปโอนเงิน',
                    html: `
                    <img src="https://chart.apis.google.com/chart?cht=qr&chs=256x256&chl=${values.withdraw.qrString}">
                `,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ปิด'
                }).then(async (result) => {
                })
            } else {
                Swal.fire({
                    title: 'สลิปโอนเงิน',
                    html: `
                    <img src="https://msn.cdnbet.co/withdraw/${PrefixService.getPrefix()}/${values.withdraw.transactionID}.png">
                `,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ปิด'
                }).then(async (result) => {
                })
            }
        }
    }
    const columns: any = [
        {
            title: 'เวลา',
            dataIndex: 'transferTime',
            width: '120px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.transferTime).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.transferTime).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'สมาชิก',
            dataIndex: 'name',
            render: (text: any, record: any) => (
                <AvatarMemberComponent
                    fullname={`${record.member.firstName} ${record.member.lastName}`}
                    phone={record.member.phone}
                    search={true}
                />
            )
        },
        {
            title: 'ไปยัง',
            dataIndex: 'bank',
            render: (text: any, record: any) => (
                <>
                    <AvatarBankComponent
                        bankCode={record.transferTo}
                        bankNumber={record.member.bankNumber}
                        text={record.transferTo}
                    />
                    {record.remark && <div className="row-remark mt-2">{record.remark}</div>}
                    {record.operator && <div className="msn-badge badge-light mt-2 text-danger">ทำรายการโดย : <b>{record.operator}</b></div>}
                </>
            )
        },
        {
            title: 'จำนวนเงิน',
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.amount, 2)}
                </div>
            )
        },
        {
            title: 'สถานะ',
            dataIndex: 'status',
            width: '90px',
            render: (text: any, record: any) => (
                <>
                    <StatusBadge status={record.status} />
                </>
            )
        },
        {
            title: 'จัดการ',
            dataIndex: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space size={0}>
                        {record.status === 'success' && record.withdraw.transactionID && (
                            // <WithdrawSlipActions tx={record} />
                            <button className="btn btn-info btn-sm text-white me-2"
                                onClick={() => {
                                    handleCheckSlip(record)
                                }}
                            >สลิปโอนเงิน</button>
                        )}
                        {!record.withdraw.transactionID && !record.withdraw.qrString && record.status !== 'pending' &&
                            <WithdrawActionsReject tx={record} setActionModal={handleAlert} />
                        }
                        {record.status === 'pending' && (
                            <WithdrawActions tx={record} setActionModal={handleAlert} />
                        )}
                        {(permissions["@admin"]) && (
                            (record.status !== 'pending' || record.status === 'reject') &&
                            <Tooltip title="ทำรายการถอนใหม่">
                                <Button shape="circle" className="ml-3" icon={<UndoOutlined />} onClick={() => { handleAlert('redo', record) }} />
                            </Tooltip>
                        )}
                        {(permissions['rewithdraw']) && (
                            (record.status === 'reject') &&
                            <Tooltip title="ทำรายการถอนใหม่">
                                <Button shape="circle" className="ml-3" icon={<UndoOutlined />} onClick={() => { handleAlert('redo', record) }} />
                                {permissions['rewithdraw']}
                            </Tooltip>
                        )}
                    </Space>
                </>
            )
        }
    ]

    const handleAlert = (type: string, tx: any) => {

        if (type === 'redo') {
            Swal.fire({
                title: 'คุณต้องการทำรายการใหม่?',
                text: "ตรวจสอบให้แน่ใจว่าคุณต้องการทำรายการถอนเงินใหม่อีกครั้ง",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ตกลง'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    try {
                        const res = await PrefixService.redoWithdraw(tx)
                        toast.success(res.message)
                        mutate()
                    } catch ({ message }) {
                        toast.error(message as string)
                    } finally {
                        handleAlert('undefined', null)
                    }
                }
            })
        }

        if (type === 'reject') {
            Swal.fire({
                title: 'คุณต้องการยกเลิกรายการ?',
                text: "ตรวจสอบให้แน่ใจว่าคุณต้องการยกเลิกรายการถอนเงิน",
                input: 'text',
                inputLabel: 'หมายเหตุ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#17c5a2',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: 'คุณต้องการคืนเครดิตหรือไม่?',
                        text: "ตรวจสอบให้แน่ใจว่าคุณต้องการคืนเครดิต",
                        icon: 'warning',
                        showDenyButton: true,
                        confirmButtonColor: '#3085d6',
                        denyButtonColor: '#d33',
                        confirmButtonText: 'คืนเครดิต',
                        denyButtonText: `ไม่คืนเครดิต`,
                    }).then(async (resultCredit) => {
                        if (resultCredit.isConfirmed) {
                            try {
                                const res = await PrefixService.rejectWithdraw(tx, result.value, true)
                                toast.success(res.message)
                                mutate()
                            } catch ({ message }) {
                                toast.error(message as string)
                            } finally {
                                handleAlert('undefined', null)
                            }
                        } else if (resultCredit.isDenied) {
                            try {
                                const res = await PrefixService.rejectWithdraw(tx, result.value, false)
                                toast.success(res.message)
                                mutate()
                            } catch ({ message }) {
                                toast.error(message as string)
                            } finally {
                                handleAlert('undefined', null)
                            }
                        }
                    })
                }
            })
        }

        if (type === 'confirm' || type === 'transfer') {
            Swal.fire({
                title: 'คุณต้องการยืนยันรายการ?',
                text: "ตรวจสอบให้แน่ใจว่าคุณต้องการยืนยันรายการถอนเงิน",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ตกลง'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const transfer = type === 'confirm' ? 'false' : 'true'
                    try {
                        const res = await PrefixService.confirmWithdraw(tx, transfer)
                        toast.success(res.message)
                        mutate()
                    } catch ({ message }) {
                        toast.error(message as string)
                    } finally {
                        handleAlert('undefined', null)
                    }
                }
            })
        }
    }

    const listStatusTransaction = Object.keys(statusTransactionWithdraw).map((key: any) => {
        return <Option key={key} value={key}>{statusTransactionWithdraw[key]}</Option>
    })

    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data?.transactions.length > 0) {
            // const filteredItems = (data?.transactions).filter(
            //     (item: any) => (
            //         (item.member.firstName && item.member.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.member.lastName && item.member.lastName.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.member.phone && item.member.phone.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.member.bankNumber && item.member.bankNumber.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.transferTo && item.transferTo.toLowerCase().includes(filterText.toLowerCase()))
            //     ),
            // );
            setDataSource(data?.transactions)
        }
    }, [data, isLoading, filterText, setDataSource])

    useMemo(() => {
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    const handleSearch = () => {
        setSearchAllData(filterText)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        mutate()
    }

    return (
        <Fragment>
            <TitleBar title="รายการถอน" subTitle={`${NumberWithCommas(data?.pagination.Count || 0)} รายการ`} />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <Space>
                    <SearchData onChangeSearch={setFilterText} filterText={filterText} />
                    <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">ค้นหา</button>
                </Space>
                <div className="ms-auto mt-3 mt-md-0 mt-xl-0">
                    <label className="me-2">สถานะ : </label>
                    <Select
                        size="large"
                        dropdownMatchSelectWidth={false}
                        placement="bottomRight"
                        value={status}
                        onSelect={(value: any) => {
                            setStatus(value)
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {listStatusTransaction}
                    </Select>
                </div>
            </div>

            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.pagination.Count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>


            <SlipModal
                data={transaction as any}
                isOpen={viewSlipModal}
                close={() => toggleViewSlip()}
            />


        </Fragment>
    )
}

export default TransactionWithdraw
