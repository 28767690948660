import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Space,
    Divider
} from 'antd'
import { toast } from 'react-toastify'
import PrefixService from "../../../services/prefix"
import { requestAuthInit } from '../../../store/auth/auth.actions'
import { PromotionConfigAdd } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'

interface AddBonusProps {
    close: () => void
    isOpen: boolean
}

const AddBonus: FC<AddBonusProps> = ({ close, isOpen }) => {
    const [form] = Form.useForm();
    const title = "เพิ่มโบนัส"
    const [btnLoading, setBtnLoading] = useState(false)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    const insertData = async (values: PromotionConfigAdd) => {
        try {
            const res = await PrefixService.addPromotionConfig(values)
            setBtnLoading(false)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            close()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: PromotionConfigAdd) => {
                setBtnLoading(true)
                insertData(values)
            })
            .catch((errorInfo) => { });
    };


    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
        }
    }, [form, isOpen]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="addBonus"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="ชื่อโบนัส"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อโบนัส' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อโบนัส" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="รายละเอียด"
                                rules={[{ required: true, message: 'กรุณากรอกรายละเอียด' }]}
                                hasFeedback
                            >
                                {/* <Input.TextArea rows={3} placeholder="รายละเอียด" /> */}
                                <Input placeholder="รายละเอียด" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                            <Button onClick={close} size="large">ยกเลิก</Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default AddBonus

