import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { toast } from 'react-toastify'
import { ApplicationState } from '../../../store'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import TitleBar from '../../../components/Elements/TitleBar'
import { Form, Input, InputNumber, Row, Col, Switch, Checkbox, Divider, Space, Button, Select } from 'antd';
import PatternNumber from '../../../utils/PatternNumber'
import { CashbackConfig } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import PrefixService from "../../../services/prefix"

const PromotionCashback = () => {
    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [withdrawLimitType, setWithdrawLimitType] = useState<string>("")
    const { Option } = Select

    const [form] = Form.useForm();

    const updateData = async (values: CashbackConfig) => {
        try {
            const res = await PrefixService.saveCashbackConfig(values)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: CashbackConfig) => {
                setBtnLoading(true)
                updateData(values)
            })
            .catch((errorInfo) => {

            });
    };


    useEffect(() => {
        if (form) {
            form.resetFields();
            form.setFieldsValue(prefix?.setting?.cashback)
            setWithdrawLimitType(prefix?.setting?.cashback.withdrawLimitType)
        }
    }, [form])// eslint-disable-line

    return (
        <Fragment>
            <TitleBar title="จัดการแคชแบ็ก" />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="cashbackForm"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="สถานะ"
                                name="enable"
                                valuePropName="checked">
                                <Switch
                                    checkedChildren="เปิด"
                                    unCheckedChildren="ปิด"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label="แคชแบ็ก"
                                rules={[
                                    { required: false, message: 'กรุณากรอกแคชแบ็ก' },
                                ]}>
                                <Input placeholder="แคชแบ็ก" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="maxAmount"
                                label="จำนวนแคชแบ็กสูงสุด"
                                rules={[
                                    { required: false, message: 'กรุณากรอกจำนวนแคชแบ็กสูงสุด' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}>
                                <InputNumber placeholder="จำนวนแคชแบ็กสูงสุด" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="min"
                                label="ยอดฝากขั้นต่ำ"
                                rules={[
                                    { required: false, message: 'กรุณากรอกยอดฝากขั้นต่ำ' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}>
                                <InputNumber placeholder="ยอดฝากขั้นต่ำ" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="turn"
                                label="เทิร์น (เท่า)"
                                rules={[
                                    { required: false, message: 'กรุณากรอกเทิร์น (เท่า)' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}>
                                <InputNumber placeholder="เทิร์น (เท่า)" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="withdrawLimitType"
                                label="ประเภทถอนสูงสุด"
                                rules={[{ required: true, message: 'กรุณาเลือกประเภทถอนสูงสุด' }]}
                                hasFeedback>
                                <Select
                                    placeholder="เลือกประเภทถอนสูงสุด"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onSelect={(value: any) => {
                                        setWithdrawLimitType(value);
                                    }}>
                                    <Option key={'fixed'} value={'fixed'}>จำนวน</Option>
                                    <Option key={'sum'} value={'sum'}>ยอดแคชแบ็ค (เท่า)</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {withdrawLimitType === "fixed" ?
                                <Form.Item
                                    name="withdrawLimit"
                                    label="จำนวนถอนสูงสุด"
                                    rules={[
                                        { required: false, message: 'กรุณากรอกจำนวนถอนสูงสุด' },
                                        { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                    ]}>
                                    <InputNumber placeholder="จำนวนถอนสูงสุด" inputMode="numeric" controls={false} />
                                </Form.Item>
                                :
                                <Form.Item
                                    name="withdrawLimit"
                                    label="ถอนสูงสุด (เท่า)"
                                    rules={[
                                        { required: false, message: 'กรุณากรอกถอนสูงสุด (เท่า)' },
                                        { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                    ]}>
                                    <InputNumber placeholder="ถอนสูงสุด (เท่า)" inputMode="numeric" controls={false} />
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <h6 className="my-3">เงื่อนไขคืนยอดเสีย</h6>
                            <Form.Item
                                name={['rules', 'minCredit']}
                                key="minCredit"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    ต้องมีเครดิตคงเหลือน้อยกว่า {prefix?.setting?.minCredit}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['rules', 'noWithdraw']}
                                key="noWithdraw"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    ต้องไม่มีรายการถอน (ตลอดทั้งวัน)
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['rules', 'noBonus']}
                                key="noBonus"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    ต้องไม่มีรายการรับโบนัส (ตลอดทั้งวัน)
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['rules', 'noWheel']}
                                key="noWheel"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    ต้องไม่มีรายการหมุนกงล้อ (ตลอดทั้งวัน)
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['rules', 'oncePerDay']}
                                key="oncePerDay"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    รับแคชแบ็กได้ 1 ครั้งต่อวัน
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default PromotionCashback