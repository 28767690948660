export interface PrefixConfig {
    banks: Record<string, BankConfig>
    scbAccounts: Record<string, SCBAccountConfig>

    promotions: Record<string, PromotionConfig>
    setting: PrefixSettingConfig
}
export interface PrefixSettingConfig {
    title: string
    turnType: string
    gameLink: string
    lineLink: string
    lineToken: string
    lineTokenFixTx: string
    socialMedia: Record<string, string>
    mainDeposit: string
    minCredit: number
    minWithdraw: number
    pointCalcurate: number
    functions: Record<PrefixFunction, boolean>

    cashback: CashbackConfig
    affiliate: AffiliateConfig
    wheel: WheelConfig
    withdrawAccount: WithdrawAccountConfig
    centralAccount: CentralAccountConfig

    ambbetGames: ambGamesConfig[]
    banks: banksConfig[]
}

export interface banksConfig {
    oldAccountNumber?: string
    accountName: string
    accountNumber: string
    apiURL: string
    bank: string
    enable: boolean
    hide: boolean
    isWithdrawAccount: boolean
    meta: { [key: string]: string }
    warning: boolean
}
export interface SCBAccountConfig {
    enable: boolean
    accountName: string
    accountNumber: string
    easynet: {
        username: string
        password: string
    }
    isWithdrawAccount: boolean
    balance: number
    error: string
    hide: boolean
}

export interface SCBConfig {
    status: boolean
    data: Record<string, SCBAccountConfig>
}

export interface TrueWalletQrCodeConfig {
    accountNumber: string
    phoneNumber: string
}

export interface TrueWalletVoucherConfig {
    accountNname: string
    phoneNumber: string
}

export interface TrueWalletCallbackConfig {
    accountNname: string
    phoneNumber: string
    urlTruewalletApiEndpoint: string
    secret: string
    status: boolean
}

export interface CentralAccountConfig {
    minAmount: number
    accountNumber: string
    bankCode: string
}

export interface WithdrawAccountConfig {
    type: string
    accountNumber: string
    maxAmount: number
    mobileNo: string
    noBonus: {
        enable: boolean
        maxAmount: number
    }
}

export interface BankConfig {
    oldAccountNumber?: string
    enable: boolean
    hide: boolean
    warning: boolean
    accountName: string
    accountNumber: string
    bank: string
    apiURL: string
    meta: { [key: string]: string }
    isWithdrawAccount: boolean
}

export type CashbackRule = 'minCredit' | 'noBonus' | 'noWheel' | 'noWithdraw' | 'oncePerDay'
export interface CashbackConfig {
    enable: boolean
    amount: string
    min: number
    maxAmount: number
    turn: number
    rules: Record<CashbackRule, boolean>
    withdrawLimit: number
    withdrawLimitType: string
}

export interface AffiliateConfig {
    amount: string
    min: number
    max: number
    toGameTurn: number
    withdrawToGame: boolean
    withdrawToBank: boolean
}

export type RewardRule = 'minCredit' | 'noBonus' | 'noWithdraw'
export interface RewardType {
    name: string
    amount: number
    chance: number
    index: number
}
export interface WheelConfig {
    enable: boolean
    minDeposit: number
    depositType: string
    rewardLimit: number
    rewardTurn: number
    rewardType: string
    rewards: Record<string, RewardType>
    rules: Record<RewardRule, boolean>
}

export interface PromotionLimitType {
    value: string
    label: string
}

export const PromotionLimitTypes: PromotionLimitType[] = [
    {
        value: 'deposit',
        label: 'โบนัสทั่วไป',
    },
    {
        value: 'ufa-sport-wl-cashback',
        label: '[UFA] คืนยอดเสียกีฬา',
    },
]


export interface PromotionLimit {
    limit: string
    label: string
}

export const PromotionLimits: PromotionLimit[] = [
    {
        limit: 'newReg',
        label: 'สมาชิกใหม่',
    },
    {
        limit: 'dailyFirstDeposit',
        label: 'ฝากแรกของวัน',
    },
    {
        limit: 'allDeposit',
        label: 'ทุกยอดฝาก',
    },
    {
        limit: 'once',
        label: 'ครั้งเดียว',
    },
]

export interface PromotionUfaCashback {
    limit: string
    label: string
}

export const PromotionUfaCashbacks: PromotionUfaCashback[] = [
    {
        limit: 'every-day',
        label: 'รับได้ทุกวัน',
    },
    {
        limit: 'every-week',
        label: 'รับได้ทุกสัปดาห์',
    },
]

export interface withdrawLimitType {
    value: string
    label: string
}

export const WithdrawLimitTypes: withdrawLimitType[] = [
    {
        value: 'fixed',
        label: 'จำนวน',
    },
    {
        value: 'sum',
        label: 'ยอดโบนัส (เท่า)',
    },
]

export interface BonusAmountType {
    type: string
    label: string
}
export const BonusAmountTypes: BonusAmountType[] = [
    {
        type: 'amount',
        label: 'จำนวน',
    },
    {
        type: 'percent',
        label: 'เปอร์เซ็นต์',
    },
]

export interface BonusGameType {
    game: string
    label: string
}
export const BonusGameTypes: BonusGameType[] = [
    {
        game: 'slot',
        label: 'สล็อต',
    },
    {
        game: 'casino',
        label: 'คาสิโน',
    },
    {
        game: 'lotto',
        label: 'หวย',
    },
    {
        game: 'sport',
        label: 'กีฬา',
    },
    {
        game: 'keno',
        label: 'คีโน',
    },
    {
        game: 'poker',
        label: 'เกมไพ่',
    },
    {
        game: 'esport',
        label: 'อีสปอร์ต',
    },
    {
        game: 'none',
        label: 'ไม่ระบุ',
    },
]

export interface PromotionPeriod {
    from: string
    to: string

    deleted: boolean
}
export interface PromotionStep {
    min: number
    max: number
    bonus: number

    deleted?: boolean
}
export interface PromotionConsecutiveDepositBonus {
    consecutiveDepositDays: number
    enable: boolean
    minimumDepositPerDay: number
    rules: {
        noBonus: boolean
        noCashback: boolean
        noLuckyWheel: boolean
        noWithdraw: boolean
    }
}
export interface PromotionNoDepositBetween {
    enable: boolean
    startDate: string
    endDate: string
    rules: {
        noBonus: boolean
        noCashback: boolean
        noLuckyWheel: boolean
        noWithdraw: boolean
    }
}
export interface PromotionConfig {
    enable: boolean
    name: string
    description: string
    limit: string
    image: string
    gameType: string
    category: string
    order: number

    type: string
    amount: number
    turn: number
    minimum: number // min deposit
    minBonus: number
    maxBonus: number
    maxWithdraw: number
    withdrawLimit: number
    withdrawLimitType: string

    period: boolean
    periods: PromotionPeriod[]

    step: boolean
    steps: PromotionStep[]

    consecutiveDepositBonus: PromotionConsecutiveDepositBonus
    noDepositBetween: PromotionNoDepositBetween
}
export interface PromotionConfigAdd {
    name: string
    description: string
}

export type PrefixFunction = 'checkSlip' | 'copGuard' | 'depositToGame' | 'withdrawFromGame' | 'bonusHunterGuard' | 'freespinHunter' | 'freespinHunterAdvance' | 'enableBonusFormCredit' | 'scanqr' | 'rank' | 'point' | 'WorkingTime'

export const promotionTabs = [
    {
        key: 'bonus',
        text: 'โบนัส',
    },
    {
        key: 'new-reg-bonus',
        text: 'แจกเครดิต',
    },
    {
        key: 'wheel',
        text: 'กงล้อเสี่ยงโชค',
    },
    {
        key: 'redeem',
        text: 'โค้ดโปรโมชั่น',
    },
    {
        key: 'cashback',
        text: 'คืนยอดเสีย',
    },
    {
        key: 'affiliate',
        text: 'แนะนำเพื่อน',
    },
]

export interface NewRegBonusConfig {
    amount: number
    turn: number
    maxWithdraw: number
}

export interface RedeemData {
    data: RedeemConfig[]
    pagination: {
        Count: number
        PageSize: number
    }
}

export interface RedeemConfig {
    ID: number
    Code: string
    Amount: number
    Limit: number
    Used: number
    Turn: number
    MaxWithdraw: number
    CreatedAt: string
    UpdatedAt: string
}
export interface RedeemAddConfig {
    code: string
    amount: number
    limit: number
    turn: number
    maxWithdraw: number
}

export interface RedeemDelConfig {
    id: any
}
export interface settingPageConfig {
    isCasino: boolean
    setting: {
        version: string
        title: string
        game: string
        agent: string
        gameMeta: {
            baseURL: string
            clientName: string
            hash: string
            key: string
        }
        turnType: string
        icon: string
        logo: string
        gameLink: string
        lineLink: string
        linkDownloadIOS: string
        linkDownloadAndroid: string
        lineToken: string
        lineTokenFixTx: string
        socialMedia: Record<string, string>
        minCredit: number
        minWithdraw: number
        mainDeposit: string
        truewalletQRDeposit: truewalletQRDepositConfig
        truewalletVoucher: truewalletVoucherConfig
        truewalletCallback: TrueWalletCallbackConfig
        functions: Record<PrefixFunction, boolean>

        cashback: CashbackConfig
        notifyBrokenTransaction: settingPageNotiConfig

        banks: banksConfig[]
        point: {
            pointCalcurate: number
            reward: any
        }
        rank: Record<RankFunction, number>
        themeID: number
        // affiliate: AffiliateConfig
        // wheel: WheelConfig
        // withdrawAccount: WithdrawAccountConfig
        // centralAccount: CentralAccountConfig
        ambPayment: ambPaymentConfig
        ambbetGames: ambGamesConfig[]
    }
}
export type RankFunction = 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond' | 'supreme'


export interface settingPageNotiConfig {
    enable: boolean
    token: string
}

export interface truewalletQRDepositConfig {
    accountNumber: string
    phoneNumber: string
}

export interface truewalletVoucherConfig {
    accountName: string
    phoneNumber: string
}

export interface settingWebConfig {
    version: string
    title: string
    game: string
    agent: string
    gameMeta: {
        baseURL: string
        clientName: string
        hash: string
        key: string
    }
    turnType: string
    icon: string
    logo: string
    gameLink: string
    lineLink: string
    linkDownloadIOS: string
    linkDownloadAndroid: string
    lineToken: string
    lineTokenFixTx: string
    socialMedia: Record<string, string>
    minCredit: number
    minWithdraw: number
    mainDeposit: string
    // functions: Record<PrefixFunction, boolean>

    cashback: CashbackConfig
    // affiliate: AffiliateConfig
    // wheel: WheelConfig
    // withdrawAccount: WithdrawAccountConfig
    // centralAccount: CentralAccountConfig
    ambPayment: ambPaymentConfig
    ambbetGames: ambGamesConfig[]
}
export interface ambPaymentConfig {
    username: string
    secret: string
}
export interface ambGamesConfigEdit {
    games: ambGamesConfig[]
}

export interface ambGamesConfig {
    enable: boolean
    game: string
    label: string
}
export interface usersConfig {
    id: number
    PermissionBuff: object
    createdAt: string
    permissions: Record<string, boolean>
    twoFactorSecret: string
    updatedAt: string
    username: string
    password: string
    workStart: string
    workEnd: string
}

export interface usersAddConfig {
    permissions: Record<string, boolean>
    username: string
    password: string
    work_start?: string
    work_end?: string
}
export interface usersEditConfig {
    permissions: Record<string, boolean>
    password?: string
    work_start?: string
    work_end?: string
}

export interface AddBankParams {
    accountName: string
    accountNumber: string
    bank: 'scb'
}

export interface RankSetting {
    rank: Record<RankFunction, number>
}
export interface PointCalurateSetting {
    pointCalcurate: number
}

export interface IUpdateTheme {
    themeID: number
}