import React, { Fragment, useState, useEffect, useMemo } from 'react'
import TitleBar from '../../components/Elements/TitleBar'
import DatePickerRange from '../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../components/Elements/DateFilterTabs'
import SearchData from '../../components/Elements/Search'
import TableComponent from '../../components/Elements/TablePagination'
import NumberWithCommas from '../../utils/NumberWithCommas'
import useDateRange from "../../hooks/useDateRange"
import useFetchLogs from '../../hooks/useFetchLogs'
import moment from "moment";
import useDatePagination from '../../hooks/useDatePagination'

const LogsReport = () => {
    const [filterText, setFilterText] = useState('')
    const [searchAlldata, setSearchAllData] = useState<string>("")
    const [dataSource, setDataSource] = useState<any>([])
    const { dateRange, setDateRange } = useDateRange()

    const { pagination, setPagination } = useDatePagination()
    const { data, isLoading, mutate } = useFetchLogs(searchAlldata, dateRange, 10000, { page: pagination.page, limit: pagination.pageSize })

    const columns = [
        {
            title: 'วันที่/เวลา',
            dataIndex: 'UpdatedAt',
            width: '120px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record?.UpdatedAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record?.UpdatedAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'รายละเอียด',
            dataIndex: 'Description',
            render: (text: any, record: any) => (
                <div>{record?.Description}</div>
            )
        },
        {
            title: 'ผู้ดำเนินการ',
            dataIndex: 'Operator',
            width: '120px',
            render: (text: any, record: any) => (
                <div>{record?.Operator}</div>
            )
        },
        {
            title: 'IP Address',
            dataIndex: 'IPAddress',
            render: (text: any, record: any) => (
                <div>{record?.IPAddress}</div>
            )
        }
    ]

    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.data.length > 0) {
            // const filteredItems = (data.data as any).filter(
            //     (item: any) => (
            //         (item.Description && item.Description.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.Operator && item.Operator.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.IPAddress && item.IPAddress.toLowerCase().includes(filterText.toLowerCase()))
            //     ),
            // );
            setDataSource(data.data)
        }
    }, [data, isLoading, filterText, setDataSource])

    useMemo(() => {
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    const handleSearch = () => {
        setSearchAllData(filterText)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        mutate()
    }

    return (
        <Fragment>
            <TitleBar title="ประวัติการใช้งาน" subTitle={`${NumberWithCommas(dataSource.length)} รายการ`} />

            {/* Date Filter */}
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            <div className="d-flex justify-content-start mb-4">
                <SearchData onChangeSearch={setFilterText} filterText={filterText} />
                <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">ค้นหา</button>
            </div>

            {/* Data Table */}
            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.pagination.Count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>

        </Fragment>
    )
}

export default LogsReport