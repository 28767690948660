import React, { Fragment, useEffect, useState } from 'react'
import TitleBar from '../../../components/Elements/TitleBar'
import { Form, Row, Col, Select, Divider, Space, Button, InputNumber, Input, Checkbox } from 'antd'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PatternNumber from '../../../utils/PatternNumber'
import { WithdrawAccountConfig } from '../../../@types/prefix'
import {
    createBankSelector,
    createSCBAccountSelector,
    createBankKbankSelector
} from '../../../utils/bank'
import { ApplicationState } from '../../../store'
import PrefixService from '../../../services/prefix'

const AutoWithdraw = () => {

    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [typeAccount, setTypeAccount] = useState<string>("")
    const [optionAccountNumber, setOptionAccountNumber] = useState<any>()
    const [showMaxAmount, setShowMaxAmount] = useState<boolean>(false)

    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const { Option } = Select;
    const updateData = async (values: WithdrawAccountConfig) => {
        try {
            const res = await PrefixService.saveWithdrawAccount(values)
            setBtnLoading(false)
            toast.success(res.message)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: WithdrawAccountConfig) => {
                setBtnLoading(true)
                updateData(values)
            })
            .catch((errorInfo) => {
            });
    };



    useEffect(() => {
        if (form) {
            form.resetFields();
            form.setFieldsValue(prefix?.setting?.withdrawAccount)
            setTypeAccount(prefix?.setting?.withdrawAccount?.type)
            setShowMaxAmount(prefix?.setting?.withdrawAccount?.noBonus?.enable)
        }
    }, [form, prefix])// eslint-disable-line

    useEffect(() => {
        const AccountNumber = typeAccount === 'api' ?
            createSCBAccountSelector(Object.values(prefix.banks)) :
            (typeAccount === "easynet" ? createBankSelector(Object.values(prefix.banks)) : createBankKbankSelector(Object.values(prefix.banks)))

        const optAccountNumber = AccountNumber && Object.keys(AccountNumber).map((key: any) => {
            return <Option key={AccountNumber[key].value} value={AccountNumber[key].value}>{AccountNumber[key].label}</Option>
        })

        setOptionAccountNumber(optAccountNumber)
        const prefixAccountNumber = AccountNumber.find((item: any) => item.value === prefix?.setting?.withdrawAccount?.accountNumber) !== undefined && AccountNumber.find((item: any) => item.value === prefix?.setting?.withdrawAccount?.accountNumber)?.value
        form.setFieldsValue({ 'accountNumber': prefixAccountNumber })
    }, [typeAccount, form])// eslint-disable-line


    return (
        <Fragment>
            <TitleBar title="ตั้งค่าถอนเงินอัตโนมัติ" />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="autoWithdrawForm"
                    layout="vertical"
                    hideRequiredMark
                    size={'large'}
                >
                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="type"
                                label="ประเภท"
                                rules={[{ required: false, message: 'กรุณาเลือกประเภท' }]}>
                                <Select
                                    placeholder="เลือกประเภท"
                                    showSearch
                                    optionFilterProp="children"
                                    onSelect={(e: string) => setTypeAccount(e)}
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={'1'} value={'api'}>SCB API</Option>
                                    <Option key={'2'} value={'easynet'}>SCB Easy Net</Option>
                                    <Option key={'3'} value={'kbiz'}>KBANK Kbiz</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="maxAmount"
                                label="ยอดถอนสูงสุด"
                                rules={[
                                    { required: true, message: 'กรุณากรอกยอดถอนสูงสุด' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder="ยอดถอนสูงสุด" inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="accountNumber"
                                label="บัญชี"
                                rules={[{ required: true, message: 'กรุณาเลือกบัญชี' }]}
                                hasFeedback>
                                <Select
                                    placeholder="เลือกบัญชี"
                                    dropdownMatchSelectWidth={false}
                                    placement="bottomRight"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {optionAccountNumber}
                                </Select>
                            </Form.Item>
                        </Col>
                        {typeAccount === 'easynet' && (
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name="mobileNo"
                                    label="เบอร์โทรศัพท์ 3 ตัวสุดท้าย"
                                    rules={[
                                        { required: typeAccount === 'easynet' ? true : false, message: 'กรุณากรอกเบอร์โทรศัพท์ 3 ตัวสุดท้าย' },
                                        { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                    ]}
                                    hasFeedback>
                                    <Input maxLength={3} showCount placeholder="เบอร์โทรศัพท์ 3 ตัวสุดท้าย" inputMode="numeric" />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <div className="checkbox-collapse">
                        <Form.Item
                            name={['noBonus', 'enable']}
                            valuePropName="checked"
                            className="mb-0">
                            <Checkbox onClick={() => setShowMaxAmount(!showMaxAmount)}>ถอนเงินอัตโนมัติเมื่อไม่มีรายการรับโบนัส</Checkbox>
                        </Form.Item>
                        {showMaxAmount &&
                            <Form.Item
                                name={['noBonus', 'maxAmount']}
                                label="ยอดถอนสูงสุด (ไม่รับโบนัส)"
                                className="mt-2 mb-0"
                                rules={[
                                    { required: true, message: 'กรุณากรอกยอดถอนสูงสุด (ไม่รับโบนัส)' },
                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder="ยอดถอนสูงสุด (ไม่รับโบนัส)" inputMode="numeric" controls={false} />
                            </Form.Item>
                        }
                    </div>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i> บันทึก</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default AutoWithdraw