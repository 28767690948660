import React, { FC } from 'react'
import { Table, Spin, Skeleton, Empty } from 'antd'
// import { LoadingOutlined } from '@ant-design/icons'
import { nanoid } from 'nanoid'
import ScrollToTopPagination from '../../utils/ScrollToTopPagination'

interface TableMiniProps {
    columns: any
    customOffsetHeader?: number
    dataSource: any
    footer?: string
    loading?: boolean
    title?: string
}

const TableMiniComponent: FC<TableMiniProps> = ({ columns, customOffsetHeader, dataSource, footer, loading, title }) => {
    let options: any = {}
    if (title) {
        options['title'] = () => title
    }
    if (footer) {
        options['footer'] = () => footer
    }

    return (
        <>
            <Spin spinning={loading} tip="กำลังโหลด..." size="large" >
                <Table
                    columns={columns as any}
                    dataSource={dataSource}
                    rowKey={() => nanoid()}
                    // loading={loading ? {
                    //     spinning: loading,
                    //     indicator: <Spin tip="กำลังโหลด..." size="large" />,
                    // } : false}
                    locale={{
                        emptyText: loading ? <Skeleton active={true} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }}
                    scroll={{ x: true }}
                    sticky={{
                        offsetHeader: customOffsetHeader ? customOffsetHeader : (window.screen.width > 992 ? 84 : 69)
                    }}
                    onChange={ScrollToTopPagination}
                    pagination={{
                        defaultPageSize: 5,
                        showSizeChanger: true,
                        pageSizeOptions: ['5', '25', '50', '100', '200'],
                        position: ['topRight', 'bottomRight'],
                        locale: { items_per_page: " รายการ" }
                    }}
                    {...options}
                />
            </Spin>
        </>
    )
}
export default TableMiniComponent