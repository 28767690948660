import React, { Fragment, useEffect, useState } from 'react'
import { Tabs } from 'antd'
import TitleBar from '../../../components/Elements/TitleBar'
import useFetchSetting from "../../../hooks/useFetchSetting"
import Ranking from './Option/Ranking'
import Point from './Option/Point'
import EmptyComponents from '../../../components/Elements/Empty'
import Loading from '../../../components/Elements/Loading'

const Features = () => {

    const { data, isLoading } = useFetchSetting()
    const [activeKey, setActiveKey] = useState<string>("")
    const [showTab, setShowTab] = useState<boolean>(true)
    const [isUpdate, setIsUpdate] = useState<boolean>(false)

    const handelKey = (key: string) => {
        setActiveKey(key)
    }

    useEffect(() => {
        if (data) {
            if (data.setting.functions.rank) {
                handelKey("1")
            } else {
                if (data.setting.functions.point) {
                    handelKey("2")
                } else {
                    setShowTab(false)
                }
            }
            // handelKey(activeKey)
        }
    }, [data])

    useEffect(() => {
        if (isUpdate) {
            handelKey(activeKey)
        }
    }, [activeKey, isUpdate])


    return (
        <Fragment>
            <TitleBar title="ตั้งค่าฟีเจอร์เว็บไซต์" />
            <div className="box-white ant-box-card">
                {isLoading ? <Loading /> :
                    showTab ? (
                        <Tabs tabPosition={"left"} activeKey={activeKey} onChange={handelKey}>
                            <Tabs.TabPane tab="ตั้งค่าแรงค์" key="1" disabled={!data?.setting.functions.rank}>
                                <Ranking setActiveKey={setActiveKey} setIsUpdate={setIsUpdate} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="ตั้งค่าสะสมแต้มแลกของรางวัล" key="2" disabled={!data?.setting.functions.point}>
                                <Point setActiveKey={setActiveKey} setIsUpdate={setIsUpdate} />
                            </Tabs.TabPane>
                        </Tabs>
                    ) : (<EmptyComponents description='ไม่พบข้อมูล กรุณาตั้งค่าเว็บไซต์ก่อนใช้งานเมนูนี้' />)}
            </div>
        </Fragment>
    )
}

export default Features