import React, { FC, useCallback, useState, useEffect } from 'react'
import moment from 'moment'
import TableComponent from '../../Elements/Table'
import StatusBadge from '../../Elements/StatusBadge'
import PrefixService from '../../../services/prefix'
import { useAPIRequest } from '../../../hooks/useAPIRequest'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import { getTypeName } from '../../../utils/transactionType'
import { Member } from '../../../@types/member'
// import { EyeOutlined } from '@ant-design/icons';


interface OtherTabProps {
    member: Member
    dateRange: any
}

const OtherTab: FC<OtherTabProps> = ({ member, dateRange }) => {
    const [dataSource, setDataSource] = useState<any>([])
    const { data, loading, update } = useAPIRequest(
        useCallback(
            async () =>
                PrefixService.getMemberTransactionList({
                    id: member.id,
                    dateRange: dateRange,
                    type: 'other',
                }),
            [member.id, dateRange]
        )
    )

    const columns = [
        {
            title: 'วันที่/เวลา',
            dataIndex: 'createdAt',
            width: '160px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span></div>
                    <div>อัพเดทยอด : {moment(record.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'โอนไปยัง',
            dataIndex: 'type',
            render: (text: any, record: any) => (
                <div>{getTypeName(record.type)}</div>
            )
        },
        {
            title: 'จำนวนเงิน',
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <>
                    <div className="row-amount">
                        <span>฿</span> {NumberWithCommas(record.amount, 2)}
                    </div>
                    {record.remark && <div className="row-remark mt-2">{record.remark}</div>}
                    {record.operator && <div className="row-operator msn-badge badge-light mt-2 text-danger" style={{ display: "in" }}>ทำรายการโดย : {record.operator}</div>}
                    <div className="msn-badge badge-light mt-2">เทิร์น {NumberWithCommas(record.turn)} เท่า - ยอดเทิร์น {NumberWithCommas(record.totalTurn, 2)}</div>
                </>
            )
        },
        {
            title: 'สถานะ',
            dataIndex: 'status',
            width: '180px',
            render: (text: any, record: any) => (
                <StatusBadge status={record.status} />
            )
        }
    ]

    useEffect(() => {
        setDataSource(data)
    }, [data, loading, setDataSource])

    useEffect(() => {
        update()
    }, [dateRange])// eslint-disable-line

    return (
        <>
            <h5 className="mt-2 mb-3">รายการอื่น ๆ <small className="sub-title">{`${NumberWithCommas(dataSource ? dataSource.length : 0)} รายการ`}</small></h5>
            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={loading}
                    customOffsetHeader={-24}
                />
            </div>
        </>
    )
}
export default OtherTab